<template>
  <div class="card-info">
    <ul class="card-container" v-if="cardInfoArr.length">
      <li class="card" v-for="(item, index) in cardInfoArr" :key="index">
        <header class="card-title" v-if="isShowTitle">
          <slot :item="item" name="title"></slot>
        </header>
        <main class="card-content">
          <slot :item="item" name="content"></slot>
        </main>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    cardInfoArr: {
      type: Array,
      default: () => [],
    },
    isShowTitle: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'common/scss/variable';
.card {
  background-color: $color-bgc-white;
  border-radius: 10px;
  margin-bottom: 20px;
  .card-title {
    color: black;
    padding: 0 15px;
    font-weight: 600;
    font-size: 13px;
    line-height: 40px;
    height: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-bottom: 1px solid $color-border-light;
    overflow: hidden;
    .icon-wangluo {
      font-size: 20px;
    }
  }
  .card-content {
    padding: 10px 20px;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>