<template>
  <div class="auto_charcode_payment">
    <section>同学您好，欢迎使用验证码自动识别功能。</section>
    <div class="rest_times_info">
      <p class="title">剩余次数</p>
      <div class="placeholder">
        <div v-if="!charCodeReqLoading">
          {{ totalCountOfAutoCharCode }}
        </div>
        <div v-else>
          <img height="30" src="../../common/img/loading.svg" alt="" />
        </div>
      </div>
    </div>
    <section>
      <div class="auto_charcode_example">
        <img src="../../common/img/auto.gif" alt="" />
      </div>
      每到期末的时候，无论是使用成绩查询功能，还是排名估算功能总是需要进行登陆操作。登陆操作就绕不开验证码的输入；为此我们团队特推出验证码自动识别功能，该功能是会在我们登录页面的过程中自动识别验证码图片，并填充到验证码输入框当中。该功能是采用的机器学习技术通过机器学习训练来完成验证码的识别，单次验证码的识别准确率可以达到98%。
    </section>
    <section>
      开通方法: 验证码识别功能为收费的高级功能，您可以通过如下的购买方式开通该功能。<br />
      收费标准分别为<strong class="strong_content">0.1元/5次、2元/20次、5元/70次、10元/150次；其中0.1元为体验价，只能使用一次；</strong
      >上线初期难免会有体验不好、程序bug等问题，还请大家多担待，大家有任何问题可以随时
      <router-link to="/contact">联系客服</router-link> ，工作人员看到以后会立即回复。
    </section>
    <div class="payment">
      <div class="price">
        <div
          :class="activeIndex == index ? 'price_item price_item_active' : 'price_item'"
          v-for="(payment, index) in paymentMap"
          :key="payment.price"
          @click="setActiveIndex(index)"
        >
          {{ payment.price }}元
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { loadAutoCharcodePayAccount, savePayAccountOpenid, loadPayAccountOpenid } from 'common/js/cache'
import payApi from 'api/wx/pay'
import { WxLogin } from 'yungouos-pay-sdk'
import * as studentInfoApi from 'api/edu/student'
import * as autoCharCodeApi from 'api/edu/auto_char_code'

export default {
  data() {
    return {
      activeIndex: undefined,
      hasRemanentTrialCount: false,
      isDataLoaded: false,
      restTimes: 0,
      charCodeReqLoading: false,
    }
  },
  computed: {
    ...mapGetters(['studentInfo']),
    totalCountOfAutoCharCode() {
      return this.studentInfo?.totalCountOfAutoCharCode || 0
    },
    paymentMap() {
      return this.hasRemanentTrialCount
        ? [{ price: '0.10' }, { price: '2.00' }, { price: '5.00' }, { price: '10.00' }]
        : [{ price: '2.00' }, { price: '5.00' }, { price: '10.00' }]
    },
    payAccountOpenId() {
      return loadPayAccountOpenid()
    },
  },
  mounted() {
    // loadAutoCharcodePayAccount方法用来移除localstorage中的autoCharCodePay数据，该方法可以在2023-10-01之后移除掉
    // 2023-08-27 by@hanhuipeng
    loadAutoCharcodePayAccount()
    this.checkPayStatus()
    this.getStudentInfo()
    this.getSelfAutoCharCodeTrialCount()
  },
  methods: {
    ...mapMutations({
      setStudentInfo: 'SET_STUDENTINFO',
    }),
    async getSelfAutoCharCodeTrialCount() {
      try {
        const res = await autoCharCodeApi.getSelfAutoCharCodeTrialCount()
        this.hasRemanentTrialCount = res.data.remanentTrialCount
      } catch (error) {
        this.$Toast({
          options: {
            toastType: 'warn',
            toastContent: '体验信息获取失败!',
            time: 1500,
          },
        })
      }
    },
    async getStudentInfo() {
      this.charCodeReqLoading = true
      try {
        const res = await studentInfoApi.getStudentInfo()
        this.setStudentInfo(res.data)
      } catch (err) {
        if (err?.response?.status === 403) {
          this.$router.push({ path: 'login', query: { nextUrl: 'auto-charcode' } })
        }
      }
      this.charCodeReqLoading = false
    },
    /**
     * 检测网页是否是重定向回来的(重定向的路径是获取openid的)
     */
    checkPayStatus() {
      const { type, index, code } = this.$route.query
      if (type === 'pay') {
        this.activeIndex = index
        this.getOsPayCode()
      } else if (code === '1') {
        this.$Dialog({
          options: {
            dialogContent: '支付成功将刷新验证码自动识别次数',
            confirmText: '支付成功',
            cancelText: '支付失败',
            isShowDialogCancel: true,
          },
        }).then(() => {
          this.getStudentInfo()
        })
      }
    },
    setActiveIndex(index) {
      this.activeIndex = index
      this.checkPayAccountOpenId()
    },
    async checkPayAccountOpenId() {
      if (this.payAccountOpenId) {
        this.getOsPayParams()
      } else {
        this.getPayAccountCodeRedirectUrl()
      }
    },
    async getPayAccountCodeRedirectUrl() {
      try {
        const mch_id = '1638817648'
        //授权结束后重定向地址
        const callback_url = `${window.location.origin}${window.location.pathname}?type=pay&&index=${this.activeIndex}`
        const type = 'mp-base'
        const params = {
          userId: 'lalala,message',
        }
        const key = '529A855DE1474CA4AEBFD6D664582FB7'
        const result = await WxLogin.getOauthUrlAsync(mch_id, callback_url, type, params, key)
        window.location.href = result
      } catch (error) {
        // 状态归位
      }
    },
    async getOsPayCode() {
      const { code } = this.$route.query
      if (code == null || code == undefined || code == '') {
        return
      }
      //根据授权的code查询用户授权信息
      this.code = code
      this.getOauthInfo()
    },
    async getOauthInfo() {
      const mch_id = '1638817648'
      const key = '529A855DE1474CA4AEBFD6D664582FB7'
      const result = await WxLogin.getOauthInfoAsync(mch_id, this.code, key)
      if (result == null || result == undefined || result == '') {
        return
      }
      const openId = result.openId
      if (openId == null || openId == undefined || openId == '') {
        return
      }
      savePayAccountOpenid(openId)
      this.getOsPayParams()
    },
    async getOsPayParams() {
      this.jsPayParams = (
        await payApi.getOsPayParams({
          openId: this.payAccountOpenId,
          totalFee: this.paymentMap[this.activeIndex].price,
          returnUrl: `${window.location.origin}${window.location.pathname}`,
        })
      ).data
      this.callPay()
    },
    jsApiCall: function () {
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke('getBrandWCPayRequest', this.jsPayParams, function (res) {
        // eslint-disable-next-line no-undef
        WeixinJSBridge.log(res.err_msg)
        if (res.err_msg == 'get_brand_wcpay_request:ok') {
          alert('支付成功')
        } else {
          alert('交易取消' + res.err_msg)
        }
      })
    },
    callPay: function () {
      if (typeof WeixinJSBridge == 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.jsApiCall, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.jsApiCall)
          document.attachEvent('onWeixinJSBridgeReady', this.jsApiCall)
        }
      } else {
        this.jsApiCall()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.auto_charcode_payment {
  padding: 10px;
  line-height: 25px;
  font-size: 14px;
  letter-spacing: 2px;
  .rest_times_info {
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .placeholder {
      line-height: 30px;
      background-color: #f5f5f5;
      font-size: 24px;
      .char_code_refresh {
        font-size: 18px;
      }
    }
    .value {
      font-size: 24px;
    }
  }
  section {
    margin-top: 10px;
    .tips {
      font-size: 10px;
    }
  }
  .auto_charcode_example {
    padding: 10px;
    text-align: center;
    img {
      width: 90%;
    }
  }
  .contact_container {
    text-align: center;
    img {
      width: 70%;
    }
  }
  .payment {
    padding: 10px 0;
    .price {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(autofill, 40px);
      row-gap: 10px;
      column-gap: 5px;
      .price_item {
        text-align: center;
        line-height: 40px;
        border: 1px solid black;
        border-radius: 3px;
      }
      .price_item_active {
        color: #fff;
        background: black;
      }
    }
    .payment_code {
      margin-top: 10px;
      text-align: center;
      img {
        width: 80%;
      }
    }
  }
}
</style>