import axiosInstance from 'common/js/axios-service'

export const getCetLoginParams = () => {
  return axiosInstance.request({
    url: 'getcetloginparams'
  })
}

export const cetLogin = (charCode, idCard, userName, cookie) => {
  return axiosInstance.request({
    url: 'cetlogin',
    method: 'post',
    data: { charCode, idCard, userName, cookie }
  })
}
