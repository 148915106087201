import AutoCharcodePayment from 'pages/auto-charcode-payment'
import BrokenNetWork from 'pages/broken-network/broken-network'
import CalculatorGpa from 'pages/calculator-gpa/calculator-gpa'
import BrokenNetWorkLogin from 'pages/broken-network/broken-network-login'
import Cet from 'pages/cet/cet'
import CheckGpa from 'pages/check-gpa/check-gpa'
import Contact from 'pages/contact/contact'
import ContactJser from 'pages/contact/contact-jser'
import CourseModal from 'pages/course-modal/course-modal'
import searchCourseModal from 'pages/course-modal/search-course-modal'
import EditCourse from 'pages/syllabus/edit-course'
import ExamRoom from 'pages/exam-room/exam-room'
import Exam from 'pages/exam/exam'
import Index from 'pages/index/index'
import Library from 'pages/library/library'
import Login from 'pages/login/login'
import VPNLogin from 'pages/login/vpn-login'
import ModifyPsw from 'pages/modify-psw/modify-psw'
import Rank from 'pages/rank/rank'
import RemindScore from 'pages/remind-score/remind-score'
import ResetSubject from 'pages/reset-subject/reset-subject'
import Score from 'pages/score/score'
import SelfStudy from 'pages/self-study/self-study'
import ServiceAgreement from 'pages/service-agreement/service-agreement'
import SingleInfo from 'pages/single-info/single-info'
import Syllabus from 'pages/syllabus'
import Volunteer from 'pages/volunteer/volunteer'
import Vue from 'vue'
import Router from 'vue-router'
import CharCodeReceive from 'pages/activity/char-code-receive'
import RemindNew from 'pages/remind-news'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/remind-news',
      component: RemindNew,
      meta: {
        title: '校园通知提醒'
      }
    },
    {
      path: '/auto-char-code-activity',
      component: CharCodeReceive,
      meta: {
        title: '验证码自动识别'
      }
    },
    {
      path: '/create-course',
      component: EditCourse,
      meta: {
        title: '新建课程'
      }
    },
    {
      path: '/edit-course',
      component: EditCourse,
      meta: {
        title: '编辑课程'
      }
    },
    {
      path: '/calculatorgpa',
      component: CalculatorGpa,
      meta: {
        title: '绩点计算器'
      }
    },
    {
      path: '/rank',
      component: Rank,
      meta: {
        title: '排名信息'
      }
    },
    {
      path: '/modify-psw',
      component: ModifyPsw,
      meta: {
        title: '修改密码'
      }
    },
    {
      path: '/auto-charcode',
      component: AutoCharcodePayment,
      meta: {
        title: '验证码自动识别'
      }
    },
    {
      path: '/syllabus',
      name: 'syllabus',
      component: Syllabus
    },
    {
      path: '/score',
      component: Score,
      meta: {
        title: '成绩查询'
      }
    },
    {
      path: '/comprehensive',
      component: CourseModal,
      meta: {
        title: '选修模块汇总'
      }
    },
    {
      path: '/search-course-modal',
      component: searchCourseModal,
      meta: {
        title: '综合素质类选修模块查询'
      }
    },
    {
      path: '/remind-score',
      component: RemindScore,
      meta: {
        title: '成绩更新自动提醒'
      }
    },
    {
      path: '/checkgpa',
      component: CheckGpa,
      meta: {
        title: '排名估算'
      }
    },
    {
      path: '/jser',
      component: ContactJser,
      meta: {
        title: '寒假训练营'
      }
    },
    {
      path: '/cet-close',
      component: Cet,
      meta: {
        title: '四六级准考证信息查看'
      }
    },
    {
      path: '/resetsub',
      component: ResetSubject,
      meta: {
        title: '重修查询'
      }
    },
    {
      path: '/singleinfo',
      component: SingleInfo,
      meta: {
        title: '个人信息'
      }
    },
    {
      path: '/volunteer',
      component: Volunteer,
      meta: {
        title: '志愿时长查询'
      }
    },
    {
      path: '/contact',
      component: Contact,
      meta: {
        title: '联系客服'
      }
    },
    {
      path: '/serviceagreement',
      component: ServiceAgreement,
      meta: {
        title: '东农助手服务协议'
      }
    },
    {
      path: '/exam',
      component: Exam,
      meta: {
        title: '考场查询'
      }
    },
    {
      path: '/examroom',
      component: ExamRoom,
      meta: {
        title: '考场查询'
      }
    },
    {
      path: '/index',
      component: Index,
      meta: {
        title: '做东农最贴心的功能'
      }
    },
    {
      path: '/networklogin',
      component: BrokenNetWorkLogin,
      meta: {
        title: '一键断网'
      }
    },
    {
      path: '/broken',
      component: BrokenNetWork,
      meta: {
        title: '一键断网'
      }
    },
    {
      path: '/library',
      name: 'library',
      component: Library,
      meta: {
        title: '图书馆座位情况'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        title: '教务登录'
      }
    },
    {
      path: '/vpn-login',
      name: 'vpn-login',
      component: VPNLogin,
      meta: {
        title: '内网登录'
      }
    },
    {
      path: '/selfstudy',
      component: SelfStudy,
      meta: {
        title: '自习室查询'
      }
    },
    {
      path: '*',
      redirect: '/index'
    }
  ]
})
