<template>
  <transition name="single" appear>
    <div class="single-info">
      <header>
        <div class="info">
          <img :src="studentInfo.headImgUrl" alt="头像" />
          <div class="nickName">{{ studentInfo.nickName }}</div>
        </div>
        <div class="exit" @click="exit" v-if="isBindIng">
          <svg class="icon icon-exit" aria-hidden="true">
            <use xlink:href="#icon-tuichu" />
          </svg>
        </div>
      </header>
      <!-- <router-link tag="div" to="/auto-charcode" class="cell">
        <div>开通验证码自动识别功能</div>
        <div>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-iconfonticonfonti2copycopy" />
          </svg>
        </div>
      </router-link> -->
      <div class="cell" @click="remindScore()">
        <div>接收成绩更新的通知</div>
        <div>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-iconfonticonfonti2copycopy" />
          </svg>
        </div>
        <!-- <div class="menu-badge">
          <label class="form-switch">
            <input type="checkbox" v-model="scoreUpdate" @change="scoreUpdateHandle" />
            <i class="form-icon"></i>
          </label>
        </div> -->
      </div>
      <!-- <div class="cell">
        <div>四六级考号预存敬请期待</div>
        <div class="menu-badge">
          <label class="form-switch">
            <input type="checkbox" v-model="examUpdate" @change="examUpdateHandle" />
            <i class="form-icon"></i>
          </label>
        </div>
      </div>-->
      <router-link tag="div" to="/serviceagreement" class="cell">
        <div>使用条款</div>
        <div>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-iconfonticonfonti2copycopy" />
          </svg>
        </div>
      </router-link>
      <BaseToast :options="toastOptions" />
      <BaseDialog :options="dialogOptions" />
    </div>
  </transition>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { checkBindIng, changeExamUpdate, changeScoreUpdate, clearUserInfo } from 'api/course'
import { debounce } from 'common/js/utils'
import { redirectUrl } from 'common/js/config'
import BaseDialog from 'base/base-dialog/base-dialog'
import BaseToast from 'base/base-toast/base-toast'
import { getLoginParams } from 'api/course'

export default {
  components: { BaseDialog, BaseToast },
  data() {
    return {
      dialogOptions: {
        mask: true,
        dialogTitle: '提示',
        dialogContent: '使用该功能需要先绑定教务账号，是否立即绑定？',
        cancelText: '取消',
        confirmText: '确定',
        isShowDialog: false,
        primaryHandle() {
          this.isShowDialog = false
        },
      },
      toastOptions: {
        mask: false,
        toastType: 'warn',
        toastContent: '请稍后',
        time: 1000,
        isShowToast: false,
      },
      handleNum: 0,
      examUpdate: false,
      scoreUpdate: false,
      loginParamsErrorTime: 0,
    }
  },
  computed: {
    ...mapGetters(['studentInfo']),
    isBindIng() {
      return Object.keys(this.studentInfo).includes('studentID')
    },
  },
  created() {
    this._checkBindIng()
  },
  mounted() {},
  methods: {
    ...mapMutations({
      setStudentInfo: 'SET_STUDENTINFO',
    }),
    ...mapActions(['exitAndClearExamAndCourse']),
    remindScore() {
      this.$router.push('remind-score')
    },
    scoreUpdateHandle() {
      if (!this.isBindIng) {
        const that = this
        this.dialogOptions = {
          mask: true,
          dialogTitle: '提示',
          dialogContent: '使用该功能需要先绑定教务账号，是否立即绑定？',
          cancelText: '取消',
          confirmText: '确定',
          isShowDialog: true,
          isShowDialogCancel: true,
          cancelHandle() {
            this.isShowDialog = false
            that.scoreUpdate = false
          },
          primaryHandle() {
            that.$router.push({
              path: 'login',
              query: { nextUrl: 'singleinfo' },
            })
            this.isShowDialog = false
          },
        }
        return
      }
      if (this.handleNum > 5) {
        this.toastOptions = {
          mask: false,
          toastType: 'warn',
          toastContent: '更改过于频繁，请稍后再试！',
          isShowToast: true,
          time: 1000,
        }
        return
      }
      this.toastOptions = {
        mask: true,
        toastType: 'loading',
        toastContent: '请稍后',
        isShowToast: true,
        time: 0,
      }
      this.handleNum += 1
      debounce(() => {
        changeScoreUpdate(this.scoreUpdate).then((res) => {
          this.toastOptions.isShowToast = false
          if (res.data.error === 1) {
            this.toastOptions = {
              mask: false,
              toastType: 'warn',
              toastContent: '设置失败，请稍后再试',
              isShowToast: true,
              time: 1000,
            }
            this.scoreUpdate = !this.scoreUpdate
            return
          }
          let studentInfo = Object.assign({}, this.studentInfo)
          studentInfo.scoreUpdate = this.scoreUpdate
          this.setStudentInfo(studentInfo)
        })
      }, 500)
    },
    examUpdateHandle() {
      if (!this.isBindIng) {
        const that = this
        this.dialogOptions = {
          mask: true,
          dialogTitle: '提示',
          dialogContent: '使用该功能需要先绑定教务账号，是否立即绑定？',
          cancelText: '取消',
          confirmText: '确定',
          isShowDialog: true,
          isShowDialogCancel: true,
          cancelHandle() {
            this.isShowDialog = false
            that.examUpdate = false
          },
          primaryHandle() {
            that.$router.push({
              path: 'login',
              query: { nextUrl: 'singleinfo' },
            })
            this.isShowDialog = false
          },
        }
        return
      }
      if (this.handleNum > 5) {
        this.toastOptions = {
          mask: false,
          toastType: 'warn',
          toastContent: '更改过于频繁，请稍后再试！',
          isShowToast: true,
          time: 1000,
        }
        return
      }
      this.toastOptions = {
        mask: true,
        toastType: 'loading',
        toastContent: '请稍后',
        isShowToast: true,
        time: 0,
      }
      this.handleNum += 1
      debounce(() => {
        changeExamUpdate(this.examUpdate).then((res) => {
          this.toastOptions.isShowToast = false
          if (res.data.error === 1) {
            this.toastOptions = {
              mask: false,
              toastType: 'warn',
              toastContent: '设置失败，请稍后再试',
              isShowToast: true,
              time: 1000,
            }
            this.examUpdate = !this.examUpdate
            return
          }
          const studentInfo = this.studentInfo
          studentInfo.examUpdate = this.examUpdate
          this.setStudentInfo(studentInfo)
        })
      }, 500)
    },
    exit() {
      const that = this
      this.$Dialog({
        options: {
          mask: true,
          dialogTitle: '提示',
          dialogContent: '真的要解绑当前账号嘛？',
          cancelText: '取消',
          confirmText: '确定',
          isShowDialogCancel: true,
        },
      }).then(async () => {
        const closeToastAction = this.$Toast({
          options: {
            time: 0,
            toastContent: '请稍后',
            mask: true,
          },
        })
        const clearRet = await clearUserInfo()
        closeToastAction()
        if (clearRet.data.error === 1) {
          this.$Toast({
            options: {
              toastType: 'warn',
              toastContent: '解绑失败',
              time: 1000,
            },
          })
          return
        }
        that.exitAndClearExamAndCourse({
          headImgUrl: that.studentInfo.headImgUrl,
          nickName: that.studentInfo.nickName,
        })
        this.isShowDialog = false
        that.getExamUpdate()
        that.getScoreUpdate()
        this._getLoginParams()
        this.$Toast({
          options: {
            toastType: 'correct',
            toastContent: '解绑成功',
            time: 1000,
          },
        })
      })
    },
    async _getLoginParams() {
      getLoginParams()
        .then(() => {
          this.loginParamsErrorTime = 0
        })
        .catch((error) => {
          if (error.response.status === 400 && error.response.data.errmsg === '请刷新重试！') {
            if (this.loginParamsErrorTime < 3) {
              this.loginParamsErrorTime++
              this._getLoginParams()
            } else {
              this.$Dialog({
                options: {
                  dialogContent: '网络错误，请稍后重试或者联系客服！',
                  cancelText: '取消',
                  confirmText: '确定',
                  isShowDialog: true,
                },
              }).then(() => {
                this.$router.push('index')
                this.isShowDialog = false
              })
            }
          }
        })
    },
    getExamUpdate() {
      this.examUpdate = this.studentInfo.examUpdate ? true : false
    },
    getScoreUpdate() {
      this.scoreUpdate = this.studentInfo.scoreUpdate ? true : false
    },
    _checkBindIng() {
      if (Object.keys(this.studentInfo).includes('studentID')) {
        this.getExamUpdate()
        this.getScoreUpdate()
        return
      }
      checkBindIng().then((res) => {
        if (res.data.error === 1) {
          if (res.data.errmsg === 'noSession') {
            window.location.href = redirectUrl()
            return
          }
        }
        this.examUpdate = res.data.studentInfo.examUpdate ? true : false
        this.scoreUpdate = res.data.studentInfo.scoreUpdate ? true : false
        this.setStudentInfo(res.data.studentInfo)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'common/scss/variable.scss';
@import 'common/scss/project-name';
.single-enter-active,
.single-leave-active {
  transition: all 0.3s;
}
.single-enter,
.single-leave-to {
  transform: translate3d(100%, 0, 0);
  opacity: 0;
}
.single-info {
  padding: 0 15px;
  height: 100vh;
  background-color: $color-background;
  header {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .info {
      display: flex;
      align-items: center;
      img {
        height: 60px;
        border-radius: 50%;
      }
      .nickName {
        margin-left: 15px;
        font-weight: 600;
      }
    }
    .exit {
      font-size: 25px;
      color: #5755d9;
    }
  }
  .cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-radius: 15px;
    padding: 0 15px;
    background-color: $color-bgc-white;
    margin-bottom: 10px;
    .form-switch {
      padding-right: 0;
    }
  }
}
</style>
