export const PRODUCT_URL = 'https://fc.hhp.im'
export const DEV_URL = 'http://localhost:7001'
// export const DEV_URL = 'http://fc.hhp.im'
export const _URL_ = process.env.NODE_ENV == 'development' ? DEV_URL : PRODUCT_URL

// 根据state的值来决定是否跳转回原来页面
export const redirectUrl = (mark = 0) => {
  const DEV_AUTH_URL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa2f9acdcf299b457&redirect_uri=${encodeURIComponent(
    'http://neauwebview.hhp.im/index'
  )}&response_type=code&scope=snsapi_userinfo&state=${mark}#wechat_redirect`
  const PRODUCT_AUTH_URL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa2f9acdcf299b457&redirect_uri=${encodeURIComponent(
    'https://neauwebview.hhp.im/index'
  )}&response_type=code&scope=snsapi_userinfo&state=${mark}#wechat_redirect`
  return process.env.NODE_ENV == 'development' ? DEV_AUTH_URL : PRODUCT_AUTH_URL
}
