import axiosInstance from 'common/js/axios-service'

export const getOtp = data => {
  return axiosInstance.request({
    url: 'vpn/getOtp.json',
    method: 'post',
    data
  })
}

export const getCookie = () => {
  return axiosInstance.request({ url: 'vpn/getCookie.json' })
}

export const signIn = data => {
  return axiosInstance.request({
    url: 'vpn/signIn.json',
    method: 'post',
    data
  })
}
