export const currentWeekOfSemester = state => state.currentWeekOfSemester

export const cookie = state => state.cookie

export const courseCount = state => state.courseCount

export const courses = state => state.courses

export const prevParsedCourses = state => state.prevParsedCourses

export const parsedCourses = state => state.parsedCourses

export const nextParsedCourses = state => state.nextParsedCourses

export const isShowUpdateLog = state => state.isShowUpdateLog

export const studentInfo = state => state.studentInfo

export const examArr = state => state.examArr

export const volunteerInfo = state => state.volunteerInfo

export const scoreInfos = state => state.scoreInfos
