<template>
  <div class="reset-sub">
    <div style="line-height: 50px; font-size: 30px; font-weight: bold; text-align: center">重修查询</div>
    <CardInfo :isShowTitle="false" :cardInfoArr="resetSubInfo">
      <template v-slot:content="cardInfo">
        <div>课程名：{{ cardInfo.item['课程名'] }}</div>
        <div>学号：{{ cardInfo.item['学号'] }}</div>
        <div>姓名：{{ cardInfo.item['姓名'] }}</div>
      </template>
    </CardInfo>
    <MoreOperations class="more-btn" @btnHandle="moreBtnHandle" @moreHandle="moreHandle" :isShowMore="isShowMore" :btnArrs="moreBtnArrs" />
    <BaseDialog :options="dialogOptions" />
  </div>
</template>
<script>
import { getResetSubInfo } from 'api/course'
import CardInfo from 'components/card-info/card-info'
import BaseDialog from 'base/base-dialog/base-dialog'
import { redirectUrl } from 'common/js/config'
import MoreOperations from 'components/more-operations/more-operations'

export default {
  components: {
    CardInfo,
    BaseDialog,
    MoreOperations,
  },
  data() {
    return {
      moreBtnArrs: [{ title: '返回首页', icon: '#icon-shouye', id: 0 }],
      isShowMore: false,
      resetSubInfo: [],
      dialogOptions: {
        mask: true,
        dialogContent: '',
        cancelText: '取消',
        confirmText: '确定',
        isShowDialog: false,
      },
    }
  },
  created() {
    this._getResetSubInfo()
  },
  methods: {
    moreHandle() {
      this.isShowMore = !this.isShowMore
    },
    moreBtnHandle(id) {
      switch (id) {
        case 0:
          this.$router.push('index')
          break
      }
    },
    _getResetSubInfo() {
      getResetSubInfo().then((res) => {
        if (res.data.error === 1) {
          if (res.data.errmsg === 'noSession') {
            window.location.href = redirectUrl('login?nextUrl=examroom')
            return
          } else if (res.data.errmsg === 'noStudentID') {
            this.$router.push('login?nextUrl=resetsub')
            return
          }
        }
        if (res.data.resetSubInfo.length === 0) {
          const that = this
          this.dialogOptions = {
            dialogContent: '暂无重修信息，请确认是否需要进行重修',
            cancelText: '取消',
            confirmText: '确定',
            isShowDialog: true,
            primaryHandle() {
              this.isShowDialog = false
              that.$router.push('index')
            },
          }
          return
        }
        this.resetSubInfo = res.data.resetSubInfo
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'common/scss/variable';

.reset-sub {
  box-sizing: border-box;
  padding: 20px;
  height: 100vh;
  background-color: $color-background;
  overflow: auto;
  .more-btn {
    position: fixed;
    bottom: 30px;
    right: 20px;
  }
}
</style>
