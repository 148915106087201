<template>
  <div class="toast-self" v-show="isShowToast">
    <div v-show="mask" class="mask animate-fade-in"></div>
    <div class="model animate-fade-in">
      <div class="toast-model">
        <img :src="icon" class="icon-toast" alt />
        <div class="toast-content">{{ toastContent }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import loading from '../../common/img/loading-white.svg'
import warn from '../../common/img/error.svg'
import correct from '../../common/img/correct-c.svg'
export default {
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          mask: false,
          toastType: '#icon-loading',
          toastContent: '请稍后',
          time: 0,
        }
      },
    },
  },
  data() {
    return {
      isShowToast: false,
    }
  },
  mounted() {
    this.initToast()
  },
  computed: {
    icon() {
      return this.options.toastType === 'warn' ? warn : this.options.toastType === 'correct' ? correct : loading
    },
    mask() {
      return this.options.mask || false
    },
    toastContent() {
      return this.options.toastContent || '请稍后'
    },
  },
  methods: {
    initToast() {
      this.isShowToast = true
      if (this.options.time === 0) {
        return
      }
      if (this.isShowToast) {
        const time = this.options.time ? this.options.time : 1000
        setTimeout(() => {
          this.isShowToast = false
        }, time)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'common/scss/mixin.scss';

.animate-fade-in {
  @include fade-modal;
}

.mask {
  @include mask;
}

.model {
  @include model;
}

.toast-model {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 18px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon-toast {
    width: 50px;
    margin-bottom: 10px;
  }
  .toast-content {
    font-size: 14px;
  }
}
</style>
