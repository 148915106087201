<template>
  <div class="self-study">
    <div class="title">自习室查询</div>
    <div class="form-group">
      <label class="form-label" for="input-example-1">教学楼</label>
      <select class="form-select" v-model="selfStudyInfo.teaNum">
        <option v-for="(item, index) in touchingBuildsObj" :key="index" :value="item.value">{{ item.content }}</option>
      </select>
    </div>
    <div class="form-group">
      <label class="form-label" for="input-example-1">日期</label>
      <select class="form-select" v-model="selfStudyInfo.date">
        <option v-for="date in dateList" :key="date" :value="date">{{ date | formatDate }}</option>
      </select>
    </div>
    <div class="form-group">
      <label class="form-label" for="input-example-1">节次</label>
      <div class="time_container">
        <label class="form-checkbox form-inline" v-for="time in timeStarts" :key="time.id">
          <input type="checkbox" :value="time.id" v-model="selfStudyInfo.timeStarts" />
          <i class="form-icon"></i>
          第{{ weekAndTimeStartArr[time.id - 1] }}节 {{ time.value }}
        </label>
      </div>
      <div style="font-size: 12px; line-height: 20px; margin-top: 10px">温馨提示: <br />1. 研楼、教学楼第二节为 10:05-11:40 <br /></div>
    </div>
    <div class="form-group">
      <button class="btn" @click="getSelfStudyRoom">查询自习室</button>
    </div>
    <table class="table" v-if="selfStudyRooms.length">
      <thead>
        <tr>
          <th>教学楼</th>
          <th>教室</th>
          <th>座位数</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(selfStudyRoom, index) in selfStudyRooms" :key="index">
          <td>{{ findTouchingBuild(selfStudyInfo.teaNum) }}</td>
          <td>{{ selfStudyRoom.classroomName }}</td>
          <td>{{ selfStudyRoom.classNumberOfSeats }}</td>
        </tr>
      </tbody>
    </table>
    <div style="height: 50px"></div>
    <BaseToast :options="toastOptions" />
    <BaseDialog :options="dialogOptions" />
  </div>
</template>
<script>
import BaseToast from 'base/base-toast/base-toast'
import BaseDialog from 'base/base-dialog/base-dialog'
import { mapActions } from 'vuex'
import dayjs from 'dayjs'
import { getSelfStudyRoom } from 'api/course'

export default {
  components: {
    BaseToast,
    BaseDialog,
  },
  data() {
    return {
      weekAndTimeStartArr: ['一', '二', '三', '四', '五', '六', '日'],
      timeStarts: [
        { id: '1', value: '8:10-9:45' },
        { id: '2', value: '10:25-12:00' },
        { id: '3', value: '13:30-15:05' },
        { id: '4', value: '15:35-17:10' },
        { id: '5', value: '18:30-20:05' },
        { id: '6', value: '20:15-21:50' },
      ],
      toastOptions: {
        mask: false,
        toastType: 'loading',
        toastContent: '请稍后',
        time: 1000,
        isShowToast: false,
      },
      dialogOptions: {
        mask: true,
        dialogContent: '',
        cancelText: '取消',
        confirmText: '确定',
        isShowDialog: false,
      },
      selfStudyRooms: [],
      dateList: [],
    }
  },
  computed: {
    selfStudyInfo() {
      return {
        teaNum: '1',
        timeStarts: [],
        date: this.dateList[0],
      }
    },
  },
  filters: {
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD')
    },
  },
  async created() {
    await this.loadWeekOfSemester()
    this.setDateList()
    this.touchingBuildsObj = [
      { value: '1', content: '主楼' },
      { value: '2', content: '教学楼' },
      { value: '23', content: '研究生教学楼' },
      { value: '3', content: '实验楼' },
      { value: '44', content: '成栋新艺术楼' },
      { value: '34', content: '成栋楼' },
    ]
  },
  methods: {
    ...mapActions(['loadWeekOfSemester']),
    findTouchingBuild(teaNum) {
      return this.touchingBuildsObj.find((build) => build.value === teaNum)?.content || ''
    },
    setDateList() {
      this.dateList = []
      for (let i = 0; i < 5; i++) {
        this.dateList.push(dayjs().add(i, 'day').format('YYYYMMDD'))
      }
    },
    getSelfStudyRoom() {
      if (!this.selfStudyInfo.timeStarts.length) {
        this.toastOptions = {
          isShowToast: true,
          toastType: 'warn',
          toastContent: '请输入完整内容',
          time: 1500,
        }
        return
      }
      this.toastOptions = {
        isShowToast: true,
        time: 0,
        toastContent: '请稍后',
      }
      getSelfStudyRoom(this.selfStudyInfo).then((res) => {
        this.toastOptions.isShowToast = false
        this.selfStudyRooms = res.data?.message?.reduce((prev, cur) => {
          return prev.concat(cur?.freeRoomList || [])
        }, [])
        if (this.selfStudyRooms.length === 0) {
          this.dialogOptions = {
            dialogContent: '当前所选并无空教室',
            cancelText: '取消',
            confirmText: '确定',
            isShowDialog: true,
            primaryHandle() {
              this.isShowDialog = false
            },
          }
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'common/scss/project-name';

.self-study {
  padding: 0 15px;
  .title {
    font-size: 20px;
    font-weight: 600;
    padding: 20px;
    text-align: center;
    color: #5755d9;
  }
  .time_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .form-label {
    font-size: 14px;
    font-weight: 600;
  }
  .btn {
    height: 2rem;
    width: 100%;
  }
  .table {
    font-size: 14px;
  }
}
</style>
