<template>
  <div>
    <div
      v-for="(item, index) in course.items"
      :key="index"
      class="course-item animate-bounceIn"
      :class="item.class"
      :style="item.style"
      @click="courseClick(course)"
    >
      <div class="course-name">{{ item.name }}</div>
      <div class="course-room">@{{ item.room }}</div>
      <div class="course-tip">{{ item.isCurrentWeek ? '' : `@${_smartWeekToStr(item.smartWeeks)}` }}</div>
    </div>
  </div>
</template>
<script>
import { smartWeekToStr } from './utils'
export default {
  props: {
    course: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    _smartWeekToStr(smartWeeks) {
      return smartWeekToStr(smartWeeks)
    },
    courseClick(course) {
      this.$emit('courseClick', course)
    },
  },
}
</script>
<style lang="scss" scoped>
.course-item {
  position: absolute;
  box-sizing: border-box;
  width: calc(100% / 7);
  padding: 6px;
  overflow: hidden;
  border-radius: 5px;
  .course-name {
    color: #fff;
    line-height: 14px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;

    display: -webkit-box;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .course-room {
    color: #fff;
    line-height: 13px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }
  .course-tip {
    color: #fff;
    margin-top: 2px;
    font-size: 9px;
    font-weight: 400;
  }
}
.bevel::before {
  content: '';
  position: absolute;
  width: 9px;
  height: 9px;
  background: -webkit-linear-gradient(top right, transparent 50%, #fff 0);
  top: 0;
  right: 0;
  // border-bottom-left-radius: 7rpx;
  box-shadow: -0.2em 0.2em 0.3em -0.1em rgba(0, 0, 0, 0.15);
}

.top {
  z-index: 1;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

.animate-bounceIn {
  animation: bounceIn 0.45s linear forwards;
}

$ch: 54px;

.course-top-1 {
  top: 0;
}
.course-top-2 {
  top: $ch * 1;
}
.course-top-3 {
  top: $ch * 2;
}
.course-top-4 {
  top: $ch * 3;
}
.course-top-5 {
  top: $ch * 4;
}
.course-top-6 {
  top: $ch * 5;
}
.course-top-7 {
  top: $ch * 6;
}
.course-top-8 {
  top: $ch * 7;
}
.course-top-9 {
  top: $ch * 8;
}
.course-top-10 {
  top: $ch * 9;
}
.course-top-11 {
  top: $ch * 10;
}
.course-top-12 {
  top: $ch * 11;
}

.course-height-1 {
  height: $ch;
}
.course-height-2 {
  height: $ch * 2;
}
.course-height-3 {
  height: $ch * 3;
}
.course-height-4 {
  height: $ch * 4;
}
.course-height-5 {
  height: $ch * 5;
}
.course-height-6 {
  height: $ch * 6;
}
.course-height-7 {
  height: $ch * 7;
}
.course-height-8 {
  height: $ch * 8;
}
.course-height-9 {
  height: $ch * 9;
}
.course-height-10 {
  height: $ch * 10;
}
.course-height-11 {
  height: $ch * 11;
}
.course-height-12 {
  height: $ch * 12;
}
</style>
