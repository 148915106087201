<template>
  <div class="course_modal">
    <Tabs :tabList="tabList" @change="changeTab">
      <template v-slot:comprehensive>
        <CardInfo :cardInfoArr="comprehensiveLists">
          <template v-slot:title="comprehensive">
            {{ comprehensive.item.title }}
          </template>
          <template v-slot:content="comprehensive">
            <div class="course_modal_line">
              <div class="course_modal_name">课程名</div>
              <div class="line-right">
                <div class="course_modal-grade">成绩</div>
                <div class="course_modal-credit">学分</div>
                <div v-if="comprehensive.item.title === '未知模块'"></div>
              </div>
            </div>
            <div v-for="(item, index) in comprehensive.item.comprehensiveInfo" :key="index">
              <div class="course_modal_line" @click="searchCourseModal(comprehensive.item, item)">
                <div class="course_modal_name">{{ item['课程名'] }}</div>
                <div class="line-right">
                  <div class="course_modal-grade">{{ item['成绩'] }}</div>
                  <div class="course_modal-credit">
                    {{ item['学分'] }}
                  </div>
                  <div v-if="comprehensive.item.title === '未知模块'">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-iconfonticonfonti2copycopy"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </CardInfo>
        <div v-if="comprehensiveLists == 0" class="empty-container">
          <span class="empty-title">暂无数据...</span>
          <div class="btn btn-link" @click="$router.push('index')">返回首页</div>
        </div>
      </template>
      <template v-slot:professional>
        <CardInfo :cardInfoArr="professionalDevelopmentList">
          <template v-slot:title="professionalDevelopment">
            {{ professionalDevelopment.item.title }}
          </template>
          <template v-slot:content="professionalDevelopment">
            <div class="course_modal_line">
              <div class="course_modal_name">课程名</div>
              <div class="line-right">
                <div class="course_modal-grade">成绩</div>
                <div class="course_modal-credit">学分</div>
              </div>
            </div>
            <div v-for="item in professionalDevelopment.item.info" :key="item['课程号']">
              <div class="course_modal_line">
                <div class="course_modal_name">{{ item['课程名'] }}</div>
                <div class="line-right">
                  <div class="course_modal-grade">{{ item['成绩'] }}</div>
                  <div class="course_modal-credit">
                    {{ item['学分'] }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </CardInfo>
        <div v-if="professionalDevelopmentStore.info.length == 0" class="empty-container">
          <span class="empty-title">暂无数据...</span>
          <div class="btn btn-link" @click="$router.push('index')">返回首页</div>
        </div>
      </template>
    </Tabs>
    <Ad :imgUrl="courseModalRules" :adUrl="courseModalRulesUrl" v-if="isShowAd"></Ad>
    <MoreOperations class="more-btn" @btnHandle="moreBtnHandle" @moreHandle="moreHandle" :isShowMore="isShowMore" :btnArrs="moreBtnArrs" />
  </div>
</template>

<script>
import { getComprehensive } from 'api/edu/comprehensive'
import CardInfo from 'components/card-info/card-info'
import MoreOperations from 'components/more-operations/more-operations'
import Tabs from 'components/tabs/tabs'
import { mapGetters } from 'vuex'
import courseModalRules from 'common/img/courseModalRules.jpg'
import Ad from 'components/ad/ad'

export default {
  components: {
    CardInfo,
    MoreOperations,
    Tabs,
    Ad,
  },
  data() {
    return {
      moreBtnArrs: [
        { title: '返回首页', icon: '#icon-shouye', id: 0 },
        { title: '更新模块信息', icon: '#icon-shuaxin', id: 1 },
        { title: '所属模块查询', icon: '#icon-sousuoleimu', id: 2 },
      ],
      isShowMore: false,
      comprehensiveLists: [],
      professionalDevelopmentStore: null,
      tabList: [
        { value: '综合素质类', key: 'comprehensive' },
        { value: '专业拓展类', key: 'professional' },
      ],
      activeIndexTab: 0,
      courseModalRules: courseModalRules,
      courseModalRulesUrl: 'https://mp.weixin.qq.com/s/5JbFxWhNiqrBadqqTHW32A',
    }
  },
  computed: {
    ...mapGetters(['cookie']),
    professionalDevelopmentList() {
      return this.professionalDevelopmentStore && this.professionalDevelopmentStore.info.length ? [this.professionalDevelopmentStore] : []
    },
    isShowAd() {
      return this.activeIndexTab === 0
        ? this.comprehensiveLists.length
        : this.professionalDevelopmentStore && this.professionalDevelopmentStore.info.length
    },
  },
  created() {
    this.getComprehensive()
  },
  methods: {
    changeTab(index) {
      this.activeIndexTab = index
    },
    searchCourseModal(coursemodal, course) {
      coursemodal.title === '未知模块' && this.$router.push(`search-course-modal?courseName=${course['课程名']}`)
    },
    moreHandle() {
      this.isShowMore = !this.isShowMore
    },
    moreBtnHandle(id) {
      switch (id) {
        case 0:
          this.$router.push('index')
          break
        case 1:
          this.comprehensiveLists = []
          this.getComprehensive()
          break
        case 2:
          this.$router.push('search-course-modal')
          break
      }
    },
    getComprehensive() {
      const closeToastAction = this.$Toast({
        time: 0,
        toastContent: '请稍后...',
      })
      getComprehensive(this.cookie)
        .then((res) => {
          this.comprehensiveLists = res.data.result.comprehensiveLists
          this.professionalDevelopmentStore = { title: '专业拓展类选修课', info: res.data.result.professionalDevelopmentList }
          const index = this.comprehensiveLists.findIndex((comprehensive) => comprehensive.title == '未知模块')
          if (index > -1) {
            const otherModule = this.comprehensiveLists.splice(index, 1)
            this.comprehensiveLists.push(...otherModule)
          }
        })
        .catch(() => {})
        .finally(() => {
          closeToastAction()
        })
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'common/scss/variable';
.course_modal {
  box-sizing: border-box;
  padding: 20px;
  height: 100vh;
  overflow: auto;
  background-color: $color-background;
  .course_modal_line {
    border-bottom: 1px solid $color-border-light;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    .course_modal_name {
      width: 50%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .line-right {
      width: 40%;
      display: flex;
      justify-content: space-between;
    }
  }
  .empty-container {
    height: 200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .empty-title {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  .more-btn {
    position: fixed;
    bottom: 30px;
    right: 20px;
  }
}
</style>
