export const weekGirds = []
export const emptyWeekArr = []
export const fullWeekArr = []
export const singularWeekArr = []
export const doubleWeekArr = []
export const smartWeekToNumber = []

let singularFlag = true
for (let i = 0; i < 25; i++) {
  weekGirds.push(i)
  emptyWeekArr.push(0)
  fullWeekArr.push(1)
  singularWeekArr.push(singularFlag ? 1 : 0)
  doubleWeekArr.push(singularFlag ? 0 : 1)

  smartWeekToNumber.push(i + 1)

  singularFlag = !singularFlag
}

export function getDayOfWeekNumber(str) {
  return {
    星期一: 1,
    星期二: 2,
    星期三: 3,
    星期四: 4,
    星期五: 5,
    星期六: 6,
    星期日: 0,
    周一: 1,
    周二: 2,
    周三: 3,
    周四: 4,
    周五: 5,
    周六: 6,
    周日: 0
  }[str]
}

export function getDayOfWeekStr(number) {
  return {
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
    0: '日'
  }[number]
}

export function isCurrentWeek(smartWeeks, currentWeek) {
  return !!smartWeeks[currentWeek - 1]
}

export function smartWeekToStr(smartWeeks) {
  const weekNumbers = smartWeeks.map((item, i) => (item === 1 ? smartWeekToNumber[i] : 0))

  // 先把单个周，和连续周取出来
  const weekChunk = []
  let start = 0
  weekNumbers.forEach((n, i) => {
    if (!n) {
      return
    }

    // 单个周
    if (!weekNumbers[i - 1] && !weekNumbers[i + 1]) {
      weekChunk.push(`${n}`)
      return
    }

    // 连续周的开始
    if (!weekNumbers[i - 1]) {
      start = n
    }

    // 连续周结束
    if (!weekNumbers[i + 1]) {
      weekChunk.push(`${start}-${n}`)
    }
  })

  // 在单个周中过滤单周和双周
  // 先把单个周过滤出来,注意这里的single意思是单个周
  const singleWeekArr = weekChunk.filter(item => !item.includes('-'))

  function popSingleWeek(n) {
    const index = weekChunk.indexOf(n)
    weekChunk.splice(index, 1)
  }

  let singleOrDoubleChunk = []
  let singleOrDoubleStart = 0
  singleWeekArr.forEach((n, i) => {
    // 判断下一个上课周与当前是否相差一个周
    if (singleWeekArr[i + 1] - n === 2) {
      if (!singleOrDoubleStart) {
        singleOrDoubleStart = n
      }
      popSingleWeek(n)
    } else {
      if (singleOrDoubleStart) {
        singleOrDoubleChunk.push(`${singleOrDoubleStart}-${n}`)
        singleOrDoubleStart = 0
        popSingleWeek(n)
      }
    }
  })

  singleOrDoubleChunk = singleOrDoubleChunk.map(item => {
    item += item.split('-')[0] % 2 === 0 ? '周(双周)' : '周(单周)'
    return item
  })

  function sortWeek(a, b) {
    a = a.includes('-') ? a.split('-')[0] : a
    b = b.includes('-') ? b.split('-')[0] : b

    return a - b
  }

  return weekChunk
    .concat(singleOrDoubleChunk)
    .sort(sortWeek)
    .map(item => `${item}${item.includes('周') ? '' : '周'}`)
    .join(' ')
}

export const isMissingCourseInfo = (course = {}) => {
  return !course.courseUnits || course.courseUnits.length === 0 || course.courseUnits.room === ''
}
