<template>
  <div class="course_missing_data">
    <div class="course-items-wrap">
      <!-- 课程名 -->
      <div class="form-group" v-if="nameDisplayMode">
        <label class="form-label" for="courseName">课程名称</label>
        <select class="form-select" id="courseName" style="width: 100%" @change="onSelectedCourseChangeHandle">
          <option :value="null" key="empty">请选择</option>
          <option v-for="course in missingInfoCourseList" :value="course.name" :key="course.index">{{ course.name }}</option>
        </select>
      </div>
      <div class="form-group" v-else>
        <label class="form-label" for="courseName">课程名称</label>
        <input
          type="text"
          class="form-input"
          id="courseName"
          :readonly="operationTypeName === '编辑'"
          v-model="currentCourse.name"
          placeholder="请输入课程名"
        />
      </div>
      <!-- 课程属性-课程模式 -->
      <div class="form-group">
        <label class="form-label" for="courseTeacher">课程属性</label>
        <select class="form-select" v-model="currentCourse.attr" style="margin-right: 20px">
          <option value="" checked>请选择课程属性</option>
          <option value="必修">必修</option>
          <option value="限选">限选</option>
          <option value="任选">任选</option>
        </select>
      </div>
      <!-- 授课教师 -->
      <div class="form-group">
        <label class="form-label" for="courseTeacher">授课教师</label>
        <input type="text" class="form-input" id="courseTeacher" v-model="currentCourse.teacher" placeholder="请输入授课教师" />
      </div>
      <!-- 上课星期-开始节-结束节 -->
      <div class="form-group">
        <label class="form-label" for="dayOfWeek">星期</label>
        <select class="form-select" id="dayOfWeek" v-model="currentUnit.dayOfWeek">
          <option :value="1">星期一</option>
          <option :value="2">星期二</option>
          <option :value="3">星期三</option>
          <option :value="4">星期四</option>
          <option :value="5">星期五</option>
          <option :value="6">星期六</option>
          <option :value="7">星期日</option>
        </select>
      </div>
      <div class="form-group">
        <label class="form-label" for="timeStart">开始节</label>
        <select class="form-select" id="timeStart" v-model="currentUnit.timeStart">
          <option :value="1">1</option>
          <option :value="3">3</option>
          <option :value="5">5</option>
          <option :value="7">7</option>
          <option :value="9">9</option>
          <option :value="11">11</option>
        </select>
      </div>
      <div class="form-group">
        <label class="form-label" for="count">节数</label>
        <select id="count" class="form-select" v-model="currentUnit.count">
          <option :value="2">2</option>
          <option :value="4">4</option>
        </select>
      </div>
      <!-- 智能周 -->
      <div class="form-group">
        <span>上课周</span>
        <div class="check-week-content">
          <label class="form-checkbox" v-for="i in 16" :key="'label-week-' + i" style="width: 63px">
            <input
              type="checkbox"
              :checked="currentUnit.smartWeeks[i - 1]"
              @change="
                (e) => {
                  onSmartWeeksChangeHandle(e, i - 1)
                }
              "
            />
            <i class="form-icon"></i>{{ i }}
          </label>
        </div>
      </div>
      <!-- 上课地点 -->
      <div class="form-group">
        <label class="form-label" for="room">上课地点</label>
        <input type="text" class="form-input" id="room" v-model="currentUnit.room" placeholder="请输入上课地点" />
      </div>
      <!-- 学分 -->
      <div class="form-group">
        <label class="form-label" for="courseCredit">学分(非必填)</label>
        <input type="number" class="form-input" id="courseCredit" v-model="currentCourse.credit" placeholder="请输入学分" />
      </div>
      <!-- 课序号 -->
      <div class="form-group">
        <label class="form-label" for="courseNumber">课程号(非必填)</label>
        <input type="text" class="form-input" id="courseNumber" v-model="currentCourse.courseNumber" placeholder="请输入课程号" />
      </div>
      <div class="action">
        <button class="btn btn-primary submit_action" @click="submitForm">{{ oldCourseInfo ? '应用' : '提交' }}</button>
      </div>
      <div class="empty_action"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isMissingCourseInfo } from './utils'
import * as courseApi from '@/api/edu/course'
export default {
  name: 'EditCourse',
  data() {
    return {
      nameDisplayMode: false, //课程名显示方式
      missingInfoCourseList: [], // 缺失信息的课程列表
      // 当前操作的课程在store中的索引
      currentCourseStore: {},
      // 当前编辑的课程详情
      currentCourse: {
        teacher: '', //授课教师
        name: '', //课程名
        credit: '', //学分
        attr: '必修', //课程属性
        courseNumber: '', // 课程号
      },
      currentUnit: {
        weeks: '',
        smartWeeks: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //智能周
        dayOfWeek: 1, //上课星期
        timeStart: 1, //开始节
        count: 2, //节数
        room: '', // 上课地点
      },
      // 标志当前课程以前是否编辑过
      oldCourseInfo: false,
    }
  },
  mounted() {
    /**
     * 路由
     * /edit-course
     * /edit-course/:id
     * /create-course
     */
    if (this.currentCourseName) {
      this.findCurrentCourse(this.currentCourseName, Number(this.$route.query.dayOfWeek))
    } else if (this.currentPath === '/edit-course') {
      this.filterCoursesInfoUnComplete(this.courses)
    } else if (this.currentPath === '/create-course') {
      // 新建课程
    }
  },
  computed: {
    ...mapGetters(['courses']),
    currentCourseName() {
      return this.$route.query?.currentCourseName
    },
    currentPath() {
      // 枚举值： create-course => 创建课程
      // 枚举值： edit-course => 编辑课程
      return this.$route.path
    },
    /**
     * return {string} // 编辑|新建
     */
    operationTypeName() {
      return this.currentPath.includes('edit-course') ? '编辑' : '新建'
    },
  },
  methods: {
    ...mapActions(['saveCourseAndSetStorage']),
    checkForm() {
      if (!this.currentCourse.teacher || !this.currentCourse.name || !this.currentCourse.attr || !this.currentUnit.room) {
        this.$Toast({
          options: {
            toastType: 'warn',
            toastContent: '请输入完整内容',
            time: 1500,
          },
        })
        return false
      } else if (this.currentUnit.smartWeeks?.every((item) => item === 0)) {
        this.$Toast({
          options: {
            toastType: 'warn',
            toastContent: '请选择上课周',
            time: 1500,
          },
        })
        return false
      }
      return true
    },
    // eslint-disable-next-line no-unused-vars
    getDayOfWeekForCurrentCourse(currentCourseName) {
      return 0
    },
    onSelectedCourseChangeHandle(e) {
      const dayOfWeek = this.getDayOfWeekForCurrentCourse(e.target.value)
      this.findCurrentCourse(e.target.value, Number(dayOfWeek))
    },
    onSmartWeeksChangeHandle(event, index) {
      this.currentUnit.smartWeeks[index] = event.target.checked ? 1 : 0
    },
    setCourses() {
      const courses = [...this.courses]
      if (this.operationTypeName === '编辑') {
        this.currentCourseStore.courseUnits.splice(
          this.currentCourse.courseUnits.findIndex((unit) => unit.dayOfWeek === this.currentUnit.dayOfWeek),
          1,
          this.currentUnit
        )
        courses.splice(
          courses.findIndex((course) => this.currentCourse.name === course.name),
          1,
          this.currentCourse
        )
      } else {
        this.currentCourse.courseUnits = [this.currentUnit]
        courses.push(this.currentCourse)
      }
      return courses
    },
    /**
     * 保存当前页面的数据
     */
    async saveCourse() {
      try {
        const dialogContent = `${this.operationTypeName}成功！是否立即返回课表页面查看？`
        const courses = this.setCourses()
        await this.createCourse()
        this.saveCourseAndSetStorage(courses)
        this.$Dialog({
          options: {
            dialogContent,
            confirmText: '确定',
            isShowDialog: true,
          },
        }).then(() => {
          this.$router.replace('syllabus')
        })
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    /**
     * 与服务器交互，创建新的课程信息
     */
    async createCourse() {
      if (this.operationTypeName === '编辑') {
        await courseApi.updateCourse(this.currentCourse)
      } else if (this.operationTypeName === '新建') {
        await courseApi.createCourse(this.currentCourse)
      }
    },
    submitForm() {
      if (!this.checkForm()) return
      this.$Dialog({
        options: {
          dialogContent: '是否立即提交当前操作？',
          cancelText: '取消',
          confirmText: '确定',
          isShowDialog: true,
          isShowDialogCancel: true,
        },
      }).then(() => {
        this.saveCourse()
      })
    },
    filterCoursesInfoUnComplete(courseList = []) {
      this.nameDisplayMode = true
      this.missingInfoCourseList = courseList.filter((course) => isMissingCourseInfo(course))
    },
    getCustomCourse() {
      return new Promise(async (resolve) => {
        try {
          const res = await courseApi.getCustomCourse({ courseName: this.currentCourseStore.name })
          if (!res.data.result) {
            resolve()
            return
          }
          this.$Dialog({
            options: {
              dialogContent: '检测到您以前编辑过该课程，是否应用该信息？',
              cancelText: '算了',
              confirmText: '试试看',
              isShowDialogCancel: true,
            },
          })
            .then(() => {
              delete res.data.result._id
              this.currentCourseStore = res.data.result
              this.oldCourseInfo = true
            })
            .finally(() => {
              resolve()
            })
        } catch (_) {
          resolve()
        }
      })
    },
    /**
     *
     * @param {Number} currentCourseName
     * @param {Number} dayOfWeek
     */
    async findCurrentCourse(currentCourseName, dayOfWeek = 0) {
      this.currentCourseStore = this.courses.find((course) => course.name === currentCourseName)
      if (!this.currentCourseStore) {
        this.$Dialog({
          options: {
            dialogContent: '无法找到当前编辑的课程，请返回课表页面重试！',
            cancelText: '取消',
            confirmText: '确定',
            isShowDialog: true,
          },
        }).then(() => {
          this.$router.replace('syllabus')
        })
        return
      }
      await this.getCustomCourse()
      /**
       * 此处课表内容需要进行深拷贝，不然会影响store中的数据，导致课表页面的数据意外改动
       * @2023-08-20 by @hanhuipeng
       */
      this.currentCourse = { ...this.currentCourseStore }
      const currentUnit = this.currentCourse.courseUnits?.find((unit) => unit.dayOfWeek === dayOfWeek) || this.currentCourse.courseUnits[0]
      /**
       * 用户选择课程编辑的时候都会存在currentUnit
       * 缺失信息的课程可能不存在courseUnits这个属性
       */
      if (!currentUnit) return
      this.currentUnit = { ...currentUnit }
      this.currentUnit.smartWeeks = [...this.currentUnit.smartWeeks]
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'common/scss/variable.scss';
@import 'common/scss/project-name';
.course_missing_data {
  height: 100vh;
  padding: 0 15px;
  background-color: $color-background;
  box-sizing: border-box;
  overflow-y: auto;
  .course-items-wrap {
    margin-top: 10px;
    overflow: hidden;
  }
  .check-week-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6px;
  }
  .action {
    position: fixed;
    height: 70px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: $color-background;
    padding: 0 15px;
    display: flex;
    align-items: center;
    .submit_action {
      width: 100%;
      box-sizing: border-box;
    }
  }
  .empty_action {
    height: 70px;
  }
}
</style>
