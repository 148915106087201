<template>
  <div class="week-bar">
    <div class="week-bar-empty-block"></div>
    <div class="week-bar-items">
      <div v-for="(item, index) in daysOfWeekHans" :key="index" :class="`week-bar-item ${currentDayOfWeek === item.id ? 'underline' : ''}`">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'weekBar',
  props: {
    currentDayOfWeek: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      daysOfWeekHans: [
        { name: '一', id: 1 },
        { name: '二', id: 2 },
        { name: '三', id: 3 },
        { name: '四', id: 4 },
        { name: '五', id: 5 },
        { name: '六', id: 6 },
        { name: '日', id: 0 }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.week-bar {
  display: flex;
  height: 25px;
  background-color: #1c1b20;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.26);
  z-index: 9;
  position: sticky;
  top: 0;
  .week-bar-empty-block {
    width: 35px;
  }
  .week-bar-items {
    display: flex;
    flex: 1;
    .week-bar-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100% / 7);
      color: white;
      font-size: 14px;
    }
    .week-bar-item.underline {
      color: #09bb07;
      border-bottom: 2px solid #09bb07;
    }
  }
}
</style>
