import Dialog from 'base/self-dialog/index'
import selfToast from 'base/self-toast/index'
import 'common/icon/iconfont.js'
import 'common/icon/iconfont.css'
import 'common/js/interception'
import 'common/scss/icon.scss'
import 'reset-css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex/store'
// import vConsole from 'vconsole'

// import fastclick from 'fastclick'
// if (process.env.NODE_ENV == 'development') {
//   new vConsole()
// }

// fastclick.attach(document.body)

Vue.config.productionTip = false
Vue.use(Dialog)
Vue.use(selfToast)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
