let timer = null

export function setWebTitle(title) {
  document.title = title
}

export const encodeUrl = url => {
  return encodeURIComponent(url)
}

// 该方法已经不建议使用
export const debounce = (func, wait) => {
  if (timer) {
    clearTimeout(timer)
  }
  timer = setTimeout(() => {
    func.apply(this)
  }, wait)
}

export const newDebounce = function(fn, depley) {
  let timer
  return (...args) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, depley)
  }
}
