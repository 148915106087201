<template>
  <div class="calculator-gpa">
    <div style="line-height: 50px; font-size: 30px; font-weight: bold; text-align: center">绩点计算器</div>
    <div v-for="(scoreItem, index) in currentSemesterScore.info" :key="index" class="gpa-item">
      <div class="form-group">
        <label class="form-checkbox">
          <input type="checkbox" v-model="scoreItem.check" />
          <i class="form-icon"></i>
        </label>
      </div>
      <div class="item-left">
        <div class="item-left-top">{{ scoreItem['课程名'] }}</div>
        <div class="item-left-bottom">
          <div>{{ scoreItem['课程属性'] }} |&nbsp;</div>
          <div>学分：{{ scoreItem['学分'] }} |&nbsp;</div>
          <div>单科学分绩：{{ scoreItem.singleScore }}</div>
        </div>
      </div>
      <div class="item-right">{{ scoreItem['成绩'] }}</div>
    </div>
    <button class="btn" @click="calculate" v-if="btnShow">计算本学期绩点</button>
    <Ad :imgUrl="adImgUrl" :adUrl="adUrl"></Ad>
    <MoreOperations class="more-btn" @btnHandle="moreBtnHandle" @moreHandle="moreHandle" :isShowMore="isShowMore" :btnArrs="moreBtnArrs" />
  </div>
</template>
<script>
import { getGpaRank } from 'api/course'
import MoreOperations from 'components/more-operations/more-operations'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import adImgUrl from 'common/img/ad.png'
import Ad from 'components/ad/ad'
export default {
  name: 'calculatorGpa',
  components: {
    MoreOperations,
    Ad,
  },
  data() {
    return {
      moreBtnArrs: [
        { title: '返回首页', icon: '#icon-shouye', id: 0 },
        { title: '联系客服', icon: '#icon-kefu', id: 1 },
        { title: '更新成绩', icon: '#icon-shuaxin', id: 2 },
        { title: '排名估算', icon: '#icon-paiming', id: 3 },
      ],
      adUrl: 'https://mp.weixin.qq.com/s/NHxHZSuAUKGWEO8Qv2R2NA',
      adImgUrl: adImgUrl,
      isShowMore: false,
      btnShow: false,
      currentSemesterScore: {},
      gpaRankInfo: [],
      creditTotal: 0,
      errorTime: 0,
    }
  },
  computed: {
    ...mapGetters(['scoreInfos', 'studentInfo', 'cookie']),
  },
  async created() {
    this.sportReg = /大学体育.*/
    this.militaryReg = /军事理论.*/
    this._getGpaRank()
  },
  methods: {
    ...mapMutations({
      setStudentInfo: 'SET_STUDENTINFO',
      clearScoreInfos: 'CLEAR_SCOREINFOS',
    }),
    ...mapActions(['setScoreInfosAndSaveScoreInfos']),
    moreBtnHandle(id) {
      switch (id) {
        case 0:
          this.$router.push('index')
          break
        case 1:
          this.$router.push('contact')
          break
        case 2:
          this._getGpaRank()
          break
        case 3:
          this.$router.push('checkgpa')
          break
      }
    },
    moreHandle() {
      this.isShowMore = !this.isShowMore
    },
    _getGpaRank() {
      const closeToastAction = this.$Toast({
        options: {
          time: 0,
          toastContent: '请稍后',
          mask: true,
        },
      })
      getGpaRank(this.cookie)
        .then((res) => {
          closeToastAction()
          if (!res.data.scoreInfo) {
            const that = this
            this.$Dialog({
              options: {
                dialogContent: '暂无成绩信息！',
                cancelText: '取消',
                confirmText: '确定',
                isShowDialog: true,
              },
            }).then(() => {
              this.isShowDialog = false
              that.$router.replace('index')
            })
            return
          }
          this.gpaRankInfo = [Object.assign({}, res.data.scoreInfo, res.data.gpaRankInfo)]
          this.currentSemesterScore = res.data.scoreInfo
          this.currentSemesterScore.info.forEach((score) => {
            this.setSingleScore(score)
            this.setCheck(score)
          })
          this.btnShow = true
        })
        .catch((error) => {
          const { response } = error
          if (response.status !== 403 && response.status !== 401) {
            this.errHandle()
          }
          closeToastAction()
        })
        .finally(() => {})
    },
    errHandle() {
      this.$Dialog({
        options: {
          dialogContent: '成绩信息更新失败，是否立即重新获取',
          cancelText: '取消',
          confirmText: '确定',
          isShowDialogCancel: true,
          isShowDialog: true,
        },
      }).then(() => {
        this.isShowDialog = false
        this._getGpaRank()
      })
    },
    setSingleScore(scoreItem) {
      let score = parseFloat(scoreItem['成绩'])
      const singleScore = (((score - 50) / 10) * scoreItem['学分']).toFixed(4)
      scoreItem.singleScore = +singleScore
    },
    setCheck(scoreItem) {
      scoreItem.check = scoreItem['课程属性'] === '必修' || scoreItem['课程属性'] === '限选' ? true : false
    },
    calculate() {
      let allCredit = 0
      let allSingleScore = 0
      let GPA = 0
      let scoreItem
      for (let i = 0; i < this.currentSemesterScore.info.length; i++) {
        scoreItem = this.currentSemesterScore.info[i]
        if (scoreItem.check) {
          allSingleScore += scoreItem.singleScore
          allCredit += parseFloat(scoreItem['学分'])
        }
      }
      allSingleScore = allSingleScore.toFixed(4)
      if (allCredit !== 0) {
        GPA = (allSingleScore / allCredit).toFixed(4)
      }
      this.$Dialog({
        options: {
          dialogContent: '本学期总学分: ' + allCredit + '<br/>' + '本学期总学分绩: ' + allSingleScore + '<br/>' + '本学期绩点: ' + GPA,
          confirmText: '确定',
          isShowDialog: true,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'common/scss/variable.scss';
@import 'common/scss/project-name';
.calculator-gpa {
  padding: 0 20px;
  .gpa-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-border-light;
    .item-left {
      padding: 10px 0;
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .item-left-top {
        line-height: 35px;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .item-left-bottom {
        display: flex;
        line-height: 25px;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .more-btn {
    position: fixed;
    bottom: 30px;
    right: 20px;
  }
  .form-group {
    margin: 0;
    .form-checkbox {
      display: flex;
      align-items: center;
    }
  }
  .btn {
    height: 2.5rem;
    display: block;
    width: 100%;
    margin-top: 10px;
  }
}
</style>
