<template>
  <div class="signin">
    <div style="height: 20vh"></div>
    <div class="title">教务系统登录</div>
    <div class="form-group">
      <input
        class="form-input"
        type="text"
        :maxlength="9"
        v-model="userInfo.studentID"
        id="input-name"
        :disabled="isBindIng"
        placeholder="账号"
      />
    </div>
    <div class="form-group">
      <input class="form-input" v-model="userInfo.passWord" type="password" id="input-password" placeholder="密码" />
    </div>
    <div style="text-align: center">
      <button style="width: 100%" class="btn" @click="submit">登录</button>
    </div>
    <div class="form-group remember-me" v-if="isBindIng">
      <label class="form-checkbox">
        <input type="checkbox" v-model="rememberMe" />
        <i class="form-icon"></i>记住我
      </label>
    </div>
    <div class="form-group remember-me" v-else>
      <label class="form-checkbox">
        <input type="checkbox" v-model="agreement" />
        <i class="form-icon"></i>
        同意并遵守
        <router-link to="/serviceagreement">《东农助手服务协议》</router-link>
      </label>
    </div>
    <div style="font-size: 12px; line-height: 20px; margin-top: 10px">
      温馨提示: <br />1. 登录密码与教务密码一致 <br />2. 教务密码默认为身份证后六位
    </div>
  </div>
</template>

<script>
import { saveEdu, loadEdu, removeEdu } from 'common/js/cache'
import { getLoginParams, signIn } from 'api/course'
import { getStudentInfo } from 'api/edu/student'
import { redirectUrl } from 'common/js/config'
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'signIn',
  data() {
    return {
      charCodeUrl: '',
      rememberMe: false,
      agreement: false,
      errorTime: 0,
      loginParamsErrorTime: 0,
      userInfo: {
        studentID: '',
        passWord: '',
      },
    }
  },
  computed: {
    ...mapGetters(['studentInfo']),
    isBindIng() {
      return Object.keys(this.studentInfo).includes('studentID')
    },
  },
  async created() {
    await this._getStudentInfo()
    this._getLoginParams()
    this._loadEdu()
  },
  methods: {
    ...mapMutations({
      setCookie: 'SET_COOKIE',
      setStudentInfo: 'SET_STUDENTINFO',
    }),
    _getStudentInfo() {
      getStudentInfo()
        .then((res) => {
          this.setStudentInfo(res.data)
          res.data.hasOwnProperty('studentID') && (this.userInfo.studentID = res.data.studentID)
        })
        .catch((err) => {
          if (err.response.status === 401) {
            window.location.href = redirectUrl(`login?nextUrl=${this.$route.query.nextUrl}`)
          }
        })
    },
    _loadEdu() {
      const eduUserInfo = loadEdu()
      if (!eduUserInfo.rememberMe) return
      this.rememberMe = eduUserInfo.rememberMe
      this.userInfo.studentID = eduUserInfo.studentID
      this.userInfo.passWord = eduUserInfo.passWord
    },
    async submit() {
      if (!this.userInfo.studentID || !this.userInfo.passWord) {
        this.$Toast({
          options: {
            toastType: 'warn',
            toastContent: '请输入完整内容',
            time: 1500,
          },
        })
        return
      } else if (!this.isBindIng && !this.agreement) {
        this.$Toast({
          options: {
            toastType: 'warn',
            toastContent: '请先阅读服务协议！',
            time: 1500,
          },
        })
        return
      }
      const closeToastAction = this.$Toast({
        options: {
          toastContent: '请稍后',
          time: 0,
        },
      })
      signIn(this.userInfo)
        .then((result) => {
          if (this.rememberMe) {
            saveEdu({
              rememberMe: this.rememberMe,
              studentID: this.userInfo.studentID,
              passWord: this.userInfo.passWord,
            })
          } else {
            removeEdu()
          }
          closeToastAction()
          const studentInfo = this.studentInfo
          studentInfo.studentID = result.data.studentInfo.studentID
          this.setStudentInfo(studentInfo)
          this.$router.replace(this.$route.query.nextUrl)
        })
        .catch((error) => {
          closeToastAction()
          const { response } = error
          if (response.status === 400) {
            this.$Toast({
              options: {
                toastContent: response.data.errmsg,
                toastType: 'warn',
                time: 2000,
              },
            })
          } else if (response.status === 412) {
            this.repeatBind()
          } else {
            this.errHandle(response)
          }
        })
    },
    repeatBind() {
      this.$Dialog({
        options: {
          dialogTitle: '重复绑定',
          dialogContent: '该账号重复绑定微信，详情请联系客服',
          cancelText: '取消',
          confirmText: '联系客服',
          isShowDialog: true,
          isShowDialogCancel: true,
        },
      })
        .then(() => {
          this.$router.push('contact')
        })
        .catch(() => {
          this._getLoginParams()
        })
    },
    errHandle() {
      const isErrorLimit = this.errorTime >= 2
      this.$Dialog({
        options: {
          dialogTitle: '错误提醒',
          dialogContent: isErrorLimit ? '网络连接失败，多次连接失败请添加客服微信！' : '目前系统网络不太稳定，请稍后重试！',
          cancelText: '取消',
          confirmText: isErrorLimit ? '联系客服' : '好的',
          isShowDialog: true,
          isShowDialogCancel: isErrorLimit,
        },
      })
        .then(() => {
          isErrorLimit ? this.$router.push('contact') : this._getLoginParams()
        })
        .catch(() => {
          this._getLoginParams()
        })
      this.errorTime++
    },
    async _getLoginParams() {
      const closeToastAction = this.$Toast({
        options: {
          toastContent: '获取登录参数中...',
          time: 0,
        },
      })
      getLoginParams()
        .then(async (ret) => {
          this.loginParamsErrorTime = 0
          this.userInfo.loginToken = ret.data?.loginToken
          this.userInfo.passWordToken = ret.data?.passWordToken
          closeToastAction()
        })
        .catch((error) => {
          closeToastAction()
          if (error.response.status === 400) {
            const errmsg = error.response.data.errmsg
            if (errmsg === '需要登录内网') {
              this.$router.replace({ path: 'vpn-login', query: { nextUrl: 'score' } })
            } else if (errmsg === '请刷新重试！') {
              if (this.loginParamsErrorTime < 3) {
                this.loginParamsErrorTime++
                this._getLoginParams()
              } else {
                this.$Dialog({
                  options: {
                    dialogContent: '网络错误，请稍后重试或者联系客服！',
                    cancelText: '取消',
                    confirmText: '确定',
                    isShowDialog: true,
                  },
                }).then(() => {
                  this.$router.push('index')
                  this.isShowDialog = false
                })
              }
            }
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'common/scss/variable';
@import 'common/scss/project-name';

.form-input {
  box-sizing: border-box;
  height: 2.5rem;
}
.btn {
  height: 2.5rem;
}
.signin {
  height: 100vh;
  padding: 0 15px;
  background-color: $color-background;
  width: 100%;
  box-sizing: border-box;
  .title {
    text-align: center;
    font-size: 25px;
    font-weight: 900;
    margin-bottom: 30px;
  }
  .input {
    margin: 10px 0;
  }
  .char-code-input {
    display: flex;
    align-items: center;
    height: 40px;
    .char-code {
      flex: 1;
    }
    img {
      height: 100%;
      margin-left: 10px;
    }
  }
  .remember-me {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    .form-checkbox {
      display: flex;
      align-items: center;
    }
    .modify-psw {
      margin: 7px 0;
    }
  }
}
</style>
