<template>
  <div class="cet">
    <img class="banner" src="https://jser.hhp.im/hhp/img/banner.png" alt />
    <div class="loading" v-if="loading">
      <img src="../../common/img/loading.svg" height="50px" alt />
    </div>
    <div class="input-box">
      <input type="text" placeholder="请输入身份证号" v-model="idCard" />
      <input type="text" placeholder="请输入姓名" v-model="userName" />
      <div class="code-box">
        <input
          class="code-input"
          type="text"
          placeholder="请输入验证码"
          v-model="charCode"
          v-on:keyup.enter="submit"
        />
        <img class="code" :src="charCodeUrl" alt="这里是验证码" />
      </div>
      <button @click="submit">登录</button>
    </div>
    <div class="content" v-if="contentWrapper">
      <div class="content-wrapper">
        <div>姓名：{{userName}}</div>
        <div>身份证号：{{idCard}}</div>
        <div>
          准考证号：
          <span id="admissionNum">{{examInfo.admissionNum}}</span>
        </div>
        <!-- <div>
          考试日期：
          <span>{{examInfo.examDate}}</span>
        </div>
        <div>
          报到时间：
          <span>{{examInfo.examCheckInTime}}</span>
        </div>
        <div>
          考试时间：
          <span>{{examInfo.examTime}}</span>
        </div>
        <div>
          考试地点：
          <span>{{examInfo.examRoom}}</span>
        </div>
        <div>
          考场号：
          <span>{{examInfo.examNum}}</span>
        </div>
        <div>
          座位号：
          <span>{{examInfo.examSeatNum}}</span>
        </div>-->
        <button
          class="copy-btn"
          data-clipboard-action="copy"
          :data-clipboard-text="examInfo.admissionNum"
          ref="copy"
          @click="hideContent"
        >复制准考证号</button>
      </div>
    </div>
    <!-- <div class="tips" style="width: 80%;margin: 0 auto 10px;">
      温馨提示：由于全国大学生四六级考试报名网(也就是我们查询准考证号的网站)访问人数太多，网站已经瘫痪，预计该网站可能会在下午才能被修复，大家稍安勿躁，晚点再查！
    </div>-->
    <BaseToast :options="toastOptions" />
  </div>
</template>
<script>
import ClipboardJS from 'clipboard'
import BaseToast from 'base/base-toast/base-toast'
import { redirectUrl } from 'common/js/config'
import { getCetLoginParams, cetLogin } from 'api/cet'
export default {
  components: {
    BaseToast
  },
  data() {
    return {
      cookie: '',
      charCodeUrl: '',
      charCode: '',
      userName: '',
      idCard: '',
      imgUrl: '',
      loading: false,
      contentWrapper: false,
      examInfo: {},
      toastOptions: {
        mask: false,
        toastType: 'loading',
        toastContent: '请稍后',
        time: 1000,
        isShowToast: false
      }
    }
  },
  mounted() {
    this.getLoginOption()
  },
  methods: {
    hideContent() {
      this.contentWrapper = false
    },
    getLoginOption() {
      getCetLoginParams().then(res => {
        if (res.data.error === 1) {
          if (res.data.errmsg === 'noSession') {
            window.location.href = redirectUrl('login?nextUrl=cet')
            return
          } else if (res.data.errmsg === 'noStudentID') {
            this.$router.push('login?nextUrl=cet')
          }
          return
        }
        this.cookie = res.data.cookie
        this.charCodeUrl = 'data:image/jpeg;base64,' + res.data.charCode
      })
    },
    submit() {
      if (!this.userName || !this.idCard || !this.charCode) {
        this.toastOptions = {
          isShowToast: true,
          toastType: 'warn',
          toastContent: '请输入完整内容',
          time: 1500,
          mask: true
        }
        return
      }
      this.loading = true
      cetLogin(this.charCode, this.idCard, this.userName, this.cookie).then(res => {
        let ret = res.data
        if (ret.ExceuteResultType === -1) {
          this.loading = false
          this.toastOptions = {
            isShowToast: true,
            toastType: 'warn',
            toastContent: ret.Message,
            time: 1500,
            mask: true
          }
          this.charCode = ''
          this.getLoginOption()
          return
        }
        this.loading = false
        this.examInfo.admissionNum = JSON.parse(ret.Message)[0].TestTicket
        this.contentWrapper = true
        this.$nextTick(() => {
          const copybtn = this.$refs.copy
          this.clipboard = new ClipboardJS(copybtn).on('success', e => {
            this.toastOptions = {
              isShowToast: true,
              toastType: 'correct',
              toastContent: '已复制',
              time: 1500,
              mask: true
            }
            e.clearSelection()
          })
        })
      })
    }
  }
}
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #caf5fe;
}

.banner {
  width: 100%;
}

.input-box {
  display: flex;
  flex-direction: column;
  margin: 30px 20px;
}

input {
  height: 35px;
  margin: 10px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  padding-left: 10px;
  outline: none;
}

.code-box {
  display: flex;
  align-items: center;
}

.code-input {
  flex: auto;
  min-width: 0;
}

.code {
  height: 30px;
  margin: 10px 10px 10px 0;
  flex: none;
}

button {
  height: 35px;
  margin: 30px 10px;
  border: none;
  outline: none;
  border-radius: 10px;
  font-size: 18px;
  background-color: #f16d7a;
  color: #ffffff;
  font-weight: 900;
}

.loading {
  position: fixed;
  top: 40%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.content {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.content-wrapper {
  margin: 0 auto;
  width: 90%;
  margin-top: 40%;
  border-radius: 10px;
  padding: 20px 10px;
  background-color: #fff;
  line-height: 25px;
}

.copy-btn {
  margin: 10px auto 0;
  padding: 0 10px;
  display: block;
}
</style>