<template>
  <transition name="library" appear>
    <div class="library-wrapper" ref="libraryWrapper" v-if="seatInfos.length">
      <div class="pull-down-loading" v-show="pullDown">
        <img :src="pullDownLoading" width="35px" alt />
      </div>
      <div class="library" ref="library">
        <header>
          <img :src="Biaoti" alt />
        </header>
        <div class="seat-info">
          <div class="seat-item" v-for="(item,index) in seatInfos" :key="index">
            <div class="jqm">{{item.jqm}}</div>
            <div class="syzw">
              <img :src="Yizi" width="50%" alt />
              {{item.syzw}}可用
            </div>
            <div class="minute">
              <div class="minute-info">15min后</div>
            </div>
            <div class="syzw">
              <img :src="Yizi" width="50%" alt />
              {{item.syzw15}}可用
            </div>
          </div>
        </div>
        <footer v-show="seatInfos.length">
          <div>注意：</div>
          <div>1.座位情况每天20:00后将不再更新，次日开馆后恢复</div>
          <div>2.图书馆一楼自习室现采用“不占座”系统，座位信息暂无法查询</div>
        </footer>
        <BaseToast :options="toastOptions" />
      </div>
    </div>
  </transition>
</template>
<script>
import BaseToast from 'base/base-toast/base-toast'
import { getSeatInfo } from 'api/library'
import loadPng from 'common/img/Ellipsis.png'
import loadGif from 'common/img/Ellipsis.gif'
import Biaoti from 'common/img/biaoti.jpg'
import Yizi from 'common/img/yizi.png'
import { prefixStyle } from 'common/js/dom'

const transform = prefixStyle('transform')

export default {
  components: {
    BaseToast
  },
  data() {
    return {
      seatInfos: [],
      Biaoti: Biaoti,
      Yizi: Yizi,
      pullDownLoading: loadPng,
      pullDown: false,
      pullDownRefresh: true,
      toastOptions: {
        mask: false,
        toastType: 'loading',
        toastContent: '请稍后',
        time: 1000,
        isShowToast: false
      }
    }
  },
  computed: {
    options() {
      return {
        pullDownRefresh: this.pullDownRefresh,
        scrollbar: true
      }
    }
  },
  created() {
    this.touch = {}
  },
  mounted() {
    this._getSeatInfo()
  },
  methods: {
    libraryTouchStart(e) {
      this.touch.initiated = true
      this.touch.move = false
      this.touch.startY = e.touches[0].pageY
      this.$refs.library.style[transform] =
        this.$refs.library.style[transform] || 'translateY(0px)'
    },
    libraryTouchMove(e) {
      if (!this.touch.initiated) {
        return
      }
      const deltaY = e.touches[0].pageY - this.touch.startY
      // console.log(deltaY);
      // if (deltaY < 0) {
      //   this.$refs.library.style[transform] += `translateY(${deltaY}px)`;
      // }
      // if (this.$refs.library.style[transform] < 0) {
      //   this.$refs.library.style[transform] += `translateY(${deltaY}px)`;
      // }
      if (this.$refs.library.style[transform] !== 'translateY(0px)') {
        this.$refs.library.style[transform] = `translateY(${deltaY}px)`
      }
      if (deltaY > 30) {
        this.pullDown = true
        this.touch.move = true
        this.pullDownLoading = loadPng
      } else if (deltaY < 30 && deltaY > 0) {
        this.pullDown = true
        this.touch.move = false
      }
    },
    libraryTouchEnd() {
      if (!this.touch.move) {
        this.$refs.library.style[transform] =
          this.$refs.library.style[transform] < 30 &&
          this.$refs.library.style[transform] > 0
            ? 'translateY(0px)'
            : 0
        return
      }
      // const deltaY = e.touches[0].pageY - this.touch.startY;
      this.pullDownLoading = loadGif
      this.$refs.library.style[transform] = 'translateY(60px)'
      this.toastOptions = {
        isShowToast: true,
        time: 0,
        toastContent: '请稍后'
      }
      getSeatInfo().then(res => {
        this.toastOptions.isShowToast = false
        this.seatInfos = res.data
        this.pullDown = false
        this.$refs.library.style[transform] = 'translateY(0px)'
      })
    },
    _getSeatInfo() {
      getSeatInfo().then(res => {
        if (res.data.error) {
          return
        }
        this.seatInfos = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.library-enter-active,
.library-leave-active {
  transition: all 0.3s;
}
.library-enter,
.library-leave-to {
  transform: translate3d(100%, 0, 0);
  opacity: 0;
}

.library-wrapper {
  .pull-down-loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
  }
}

.library {
  padding: 10px;
  box-sizing: border-box;
  // height: 100vh;
  // max-height: 667px;
  display: flex;
  flex-direction: column;
  header {
    img {
      width: 100%;
      height: 150px;
    }
  }
  .seat-info {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 5px 0;
    .seat-item {
      flex: 1;
      background-image: url(../../common/img/kuang.png);
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      .jqm {
        color: #fff;
        font-size: 13px;
        width: 30px;
        margin: 0 25px;
        line-height: 18px;
        flex: none;
      }
      .syzw {
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        color: #ffed64;
        font-size: 13px;
        padding: 5px 0;
      }
      .minute {
        background-image: url(../../common/img/zhongbiao.png);
        background-size: 100% 100%;
        height: 55px;
        width: 90px;
        flex: 0 0 90px;
        position: relative;
        .minute-info {
          position: absolute;
          top: 60%;
          width: 100%;
          text-align: center;
          font-size: 10px;
        }
      }
    }
  }
  footer {
    font-size: 12px;
    padding-bottom: 10px;
  }
}
</style>