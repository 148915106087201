import * as courseUtils from './utils'

export function parsedCourse(courses, week) {
  if (!courses.length) {
    return []
  }

  let tableCourses = []
  courses.forEach(course => {
    course.courseUnits.forEach(courseUnit => {
      let { dayOfWeek, timeStart } = courseUnit // 上课开始时间，上课是每周的第几天

      timeStart = parseInt(timeStart)

      let tableCourse = tableCourses.find(item => {
        return item.dayOfWeek === dayOfWeek && item.timeStart === timeStart
      })

      if (!tableCourse) {
        tableCourse = { dayOfWeek, timeStart }
        tableCourses.push(tableCourse)
      }

      tableCourse.items = tableCourse.items || []

      const isCurrentWeek = courseUtils.isCurrentWeek(courseUnit.smartWeeks, week) //  week = 1，iscurrentweek 表示当前周该节课是否要上

      if (!isCurrentWeek) {
        return
      }

      course.color = course.color || getColor(dayOfWeek, timeStart) // 获取课表格子的颜色

      const style = `background: ${isCurrentWeek ? course.color : '#AAAAAA'};left: calc(100% / 7 * ${dayOfWeek - 1});`

      const className = `course-top-${timeStart} course-height-${courseUnit.count}`

      tableCourse.items.push({
        _id: course._id,
        color: course.color,
        name: course.name,
        weeks: courseUnit.weeks,
        smartWeeks: courseUnit.smartWeeks,
        teacher: course.teacher,
        room: courseUnit.room,
        count: courseUnit.count,
        signature: course.signature,
        class: className,
        credit: course.credit,
        courseNumber: course.courseNumber,
        style,
        isCurrentWeek
      })

      if (tableCourse.items.length > 1) {
        let topItem = tableCourse.items.find(item => item.isCurrentWeek)
        if (!topItem) topItem = tableCourse.items[0]
        topItem.class += ' bevel top'
        topItem.style = topItem.style.replace(
          /background:.+?;/,
          `background: linear-gradient(-135deg, transparent 6px ,${topItem.isCurrentWeek ? topItem.color : '#AAAAAA'} 0);`
        )
      }
    })
  })

  return tableCourses
}

export function parsedCourseForCurrentWeek(courses, currentWeekOfSemester) {
  if (!courses.length) {
    return []
  }

  let tableCourses = []
  courses.forEach(course => {
    course.courseUnits.forEach(courseUnit => {
      // 同时定位格子的位置
      let { dayOfWeek, timeStart } = courseUnit // 上课开始时间，上课是每周的第几天
      // eslint-disable-next-line no-self-assign
      dayOfWeek = dayOfWeek
      timeStart = parseInt(timeStart)

      // 查看当前位置是否含有其他课程，如果有，都放在一起
      let tableCourse = { dayOfWeek, timeStart }
      tableCourses.push(tableCourse)

      tableCourse.items = tableCourse.items || []

      // 校验是否是当前周课程
      const isCurrentWeek = courseUtils.isCurrentWeek(courseUnit.smartWeeks, currentWeekOfSemester) //  currentWeekOfSemester = 1，iscurrentweek 表示当前周该节课是否要上

      if (!isCurrentWeek) {
        return
      }

      course.color = course.color || getColor(dayOfWeek, timeStart) // 获取课表格子的颜色

      const style = `background: ${course.color};left: calc(100% / 7 * ${dayOfWeek - 1});`

      const className = `course-top-${timeStart} course-height-${courseUnit.count}`

      tableCourse.items.push({
        _id: course._id,
        color: course.color,
        name: course.name,
        weeks: courseUnit.weeks,
        smartWeeks: courseUnit.smartWeeks,
        teacher: course.teacher,
        room: courseUnit.room,
        count: courseUnit.count,
        signature: course.signature,
        class: className,
        credit: course.credit,
        courseNumber: course.courseNumber,
        style,
        isCurrentWeek
      })
    })
  })

  return tableCourses
}

export function parsedCourseForAllWeek(courses) {
  if (!courses.length) {
    return []
  }

  let tableCourses = []
  courses.forEach(course => {
    course.courseUnits.forEach(courseUnit => {
      let { dayOfWeek, timeStart } = courseUnit // 上课开始时间，上课是每周的第几天
      // eslint-disable-next-line no-self-assign
      dayOfWeek = dayOfWeek
      timeStart = parseInt(timeStart)

      let tableCourse = tableCourses.find(item => {
        return item.dayOfWeek === dayOfWeek && item.timeStart === timeStart
      })

      if (!tableCourse) {
        tableCourse = { dayOfWeek, timeStart }
        tableCourses.push(tableCourse)
      }

      tableCourse.items = tableCourse.items || []

      course.color = course.color || getColor(dayOfWeek, timeStart) // 获取课表格子的颜色

      const style = `background: ${course.color};left: calc(100% / 7 * ${dayOfWeek - 1});`

      const className = `course-top-${timeStart} course-height-${courseUnit.count}`

      tableCourse.items.push({
        _id: course._id,
        color: course.color,
        name: course.name,
        weeks: courseUnit.weeks,
        smartWeeks: courseUnit.smartWeeks,
        teacher: course.teacher,
        room: courseUnit.room,
        count: courseUnit.count,
        signature: course.signature,
        class: className,
        credit: course.credit,
        courseNumber: course.courseNumber,
        style
      })

      if (tableCourse.items.length > 1) {
        let topItem = tableCourse.items.find(item => item.count > 2)
        if (!topItem) topItem = tableCourse.items[0]
        topItem.class += ' bevel top'
        topItem.style = topItem.style.replace(
          /background:.+?;/,
          `background: linear-gradient(-135deg, transparent 6px ,${topItem.color} 0);`
        )
      }
    })
  })

  return tableCourses
}

const colors = [
  'rgb(248, 125, 138)',
  'rgb(1, 179, 238)',
  'rgb(19, 202, 154)',
  'rgb(186, 138, 223)',
  'rgb(255, 186, 7)',
  'rgb(110, 159, 246)',
  'rgb(255, 141, 65)',
  'rgb(145, 198, 7)',
  'rgb(228, 119, 195)'
]

function getColor(dayOfWeek, timeStart) {
  const weekOffset = dayOfWeek - 1
  const dayOffset = parseInt(timeStart) - 1
  const colorIndex = (weekOffset + dayOffset) % 9
  return colors[colorIndex]
}
