import storage from 'good-storage'

const WEEKOFSEMESTER_KEY = '__weekofsemester__'
const COURSES_KEY = '__course__'
const EXAM_KEY = '__exam__'
const NEAU_COOKIE_KEY = '__neau_cookie__'
const SCORE_KEY = '__score__'
// 校园网络的记住密码功能
const NEAU_NET_WORK = '__neau_net_work__'
// 教务功能的记住密码
const NEAU_EDU = '__neau_edu__'
const REMINDSCOREINVITECOUNT = '__remind_score_invite_count__'
const AUTOCHARCODEINVITECOUNT = '__auto_charcode_invite_count__'
const AUTOCHARCODEPAYACCOUNT = '__auto_charcode_pay_account__'
const PAYACCOUNTOPENID = '__pay_account_openid'

const setStorageInfo = (store, expires = 15 * 24 * 60 * 60 * 1000) => {
  return {
    expires,
    data: store,
    startTimer: new Date().getTime()
  }
}

export const savePayAccountOpenid = openid => {
  storage.set(PAYACCOUNTOPENID, openid)
}

export const loadPayAccountOpenid = () => {
  return storage.get(PAYACCOUNTOPENID, '')
}

export const removePayAccountOpenid = () => {
  return storage.remove(PAYACCOUNTOPENID)
}

export const saveAutoCharcodeInviteCount = autoCharcodeInviteCount => {
  storage.set(AUTOCHARCODEINVITECOUNT, setStorageInfo(autoCharcodeInviteCount))
}

export const removeAutoCharcodeInviteCount = () => {
  storage.remove(AUTOCHARCODEINVITECOUNT)
}

export const loadAutoCharcodeInviteCount = () => {
  const storageInfo = storage.get(AUTOCHARCODEINVITECOUNT, setStorageInfo(0))
  if (new Date().getTime() - storageInfo.startTimer > storageInfo.expires) {
    removeAutoCharcodeInviteCount()
    return 0
  } else {
    return storageInfo.data
  }
}

// 此方法不能再用来判断用户是否拥有体验权限
export const saveAutoCharcodePayAccount = autoCharcodePayAccount => {
  storage.set(AUTOCHARCODEPAYACCOUNT, setStorageInfo(autoCharcodePayAccount))
}

export const removeAutoCharcodePayAccount = () => {
  storage.remove(AUTOCHARCODEPAYACCOUNT)
}

export const loadAutoCharcodePayAccount = () => {
  const storageInfo = storage.get(AUTOCHARCODEPAYACCOUNT, setStorageInfo(0))
  if (new Date().getTime() - storageInfo.startTimer > storageInfo.expires) {
    removeAutoCharcodePayAccount()
    return 0
  } else {
    return storageInfo.data
  }
}

/**
 *
 * @param {number} remindScoreInviteCount
 */
export function saveRemindScoreInviteCount(remindScoreInviteCount) {
  storage.set(REMINDSCOREINVITECOUNT, setStorageInfo(remindScoreInviteCount))
}

/**
 *
 * @returns {number}
 */
export function loadRemindScoreInviteCount() {
  const storageInfo = storage.get(REMINDSCOREINVITECOUNT, setStorageInfo(0))
  if (new Date().getTime() - storageInfo.startTimer > storageInfo.expires) {
    removeRemindScoreInviteCount()
    return 0
  } else {
    return storageInfo.data
  }
}

export function removeRemindScoreInviteCount() {
  storage.remove(REMINDSCOREINVITECOUNT)
}

export function saveScore(scoreInfos) {
  storage.set(SCORE_KEY, scoreInfos)
}

export function loadScore() {
  return storage.get(SCORE_KEY, [])
}

export function removeScore() {
  storage.remove(SCORE_KEY)
}

export function saveExam(examArr) {
  storage.set(EXAM_KEY, examArr)
}

export function loadExam() {
  return storage.get(EXAM_KEY, [])
}

export function removeExam() {
  storage.remove(EXAM_KEY)
}

export function saveEdu(eduUserInfo) {
  storage.set(NEAU_EDU, eduUserInfo)
}

export function loadEdu() {
  return storage.get(NEAU_EDU, {})
}

export function removeEdu() {
  storage.remove(NEAU_EDU)
}

export function saveNetWork(netWorkUserInfo) {
  storage.set(NEAU_NET_WORK, netWorkUserInfo)
}

export function loadNetWork() {
  return storage.get(NEAU_NET_WORK, {})
}

export function removeNetWork() {
  storage.remove(NEAU_NET_WORK)
}

export function saveCookie(cookie) {
  storage.set(NEAU_COOKIE_KEY, cookie)
}

export function loadCookie() {
  return storage.get(NEAU_COOKIE_KEY, '')
}

export function removeCookie() {
  storage.remove(NEAU_COOKIE_KEY)
}

export function saveCourses(courses) {
  storage.set(COURSES_KEY, courses)
}

export function loadCourses() {
  return storage.get(COURSES_KEY, [])
}

export function removeCourse() {
  storage.remove(COURSES_KEY)
}

export function saveWeekOfSemester(weekOfSemester, dateOfSetWeek) {
  storage.set(WEEKOFSEMESTER_KEY, { weekOfSemester, dateOfSetWeek })
}
