<template>
  <div class="check-gpa">
    <div style="line-height: 50px; font-size: 30px; font-weight: bold; text-align: center">智育排名查询</div>
    <div style="font-size: 13px; line-height: 20px; margin-bottom: 20px; color: #5755d9">
      <section>
        1.同学在核实成绩时，下方单科绩点显示为 0 的项是不计入绩点计算的科目，请悉知；<br />
        2.由于班级及专业绑定人数可能会改变，可以点击右下角按钮更新排名哦！<br />
        3.绩点的具体计算方法请看最后的图文链接；
      </section>
    </div>
    <card-info :cardInfoArr="gpaRankInfo">
      <template v-slot:title>排名信息</template>
      <template v-slot:content="cardInfo">
        <div class="gpaRank-line">
          <div class="line-left">
            <div>姓名：</div>
            <div>学号：</div>
            <div>班级：</div>
            <div>专业：</div>
            <div>年级：</div>
            <div>本学期绩点：</div>
            <div>本学期总学分绩：</div>
            <div>本学期总学分：</div>
            <div>班级绑定人数：</div>
            <div>班级绑定人数排名：</div>
            <div>专业绑定人数：</div>
            <div>专业绑定人数排名：</div>
          </div>
          <div class="line-right">
            <div>{{ cardInfo.item.studentName }}</div>
            <div>{{ cardInfo.item.studentID }}</div>
            <div>{{ cardInfo.item.class }}</div>
            <div>{{ cardInfo.item.major }}</div>
            <div>{{ cardInfo.item.grade }}</div>
            <div>{{ cardInfo.item.Gpa || 0 }}</div>
            <div>{{ cardInfo.item.intellectualEduTotal || 0 }}</div>
            <div>{{ cardInfo.item.creditTotal || 0 }}</div>
            <div>{{ cardInfo.item.classBindCount }}</div>
            <div>{{ cardInfo.item.gpaRankOfClass }}</div>
            <div>{{ cardInfo.item.majorBindCount }}</div>
            <div>{{ cardInfo.item.gpaRankOfMajor }}</div>
          </div>
        </div>
      </template>
    </card-info>
    <div v-for="(scoreItem, index) in currentSemesterScore.info" :key="index" class="gpa-item">
      <div class="item-left">
        <div class="item-left-top">{{ scoreItem['课程名'] }}</div>
        <div class="item-left-bottom">
          <div>{{ scoreItem['课程属性'] }} |&nbsp;</div>
          <div>学分：{{ scoreItem['学分'] }} |&nbsp;</div>
          <div :style="scoreItem.gpa === 0 ? 'color: #5755d9;' : ''">单科学分绩：{{ scoreItem.gpa }}</div>
        </div>
      </div>
      <div class="item-right">{{ scoreItem['成绩'] }}</div>
    </div>
    <Ad :imgUrl="adImgUrl" :adUrl="adUrl"></Ad>
    <MoreOperations class="more-btn" @btnHandle="moreBtnHandle" @moreHandle="moreHandle" :isShowMore="isShowMore" :btnArrs="moreBtnArrs" />
  </div>
</template>
<script>
import { getGpaRank } from 'api/course'
import MoreOperations from 'components/more-operations/more-operations'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import CardInfo from 'components/card-info/card-info'
import adImgUrl from 'common/img/ad.png'
import Ad from 'components/ad/ad'

export default {
  name: 'checkGpa',
  components: {
    CardInfo,
    MoreOperations,
    Ad,
  },
  data() {
    return {
      moreBtnArrs: [
        { title: '返回首页', icon: '#icon-shouye', id: 0 },
        { title: '联系客服', icon: '#icon-kefu', id: 1 },
        { title: '更新排名', icon: '#icon-shuaxin', id: 2 },
        { title: '绩点计算器', icon: '#icon-jisuanqi', id: 3 },
      ],
      adUrl: 'https://mp.weixin.qq.com/s/NHxHZSuAUKGWEO8Qv2R2NA',
      adImgUrl: adImgUrl,
      isShowMore: false,
      currentSemesterScore: {},
      gpaRankInfo: [],
      creditTotal: 0,
      errorTime: 0,
    }
  },
  computed: {
    ...mapGetters(['scoreInfos', 'studentInfo', 'cookie']),
  },
  async created() {
    this.sportReg = /大学体育.*/
    this.militaryReg = /军事理论.*/
    this._getGpaRank()
  },
  methods: {
    ...mapMutations({
      setStudentInfo: 'SET_STUDENTINFO',
      clearScoreInfos: 'CLEAR_SCOREINFOS',
    }),
    ...mapActions(['setScoreInfosAndSaveScoreInfos']),
    moreBtnHandle(id) {
      switch (id) {
        case 0:
          this.$router.push('index')
          break
        case 1:
          this.$router.push('contact')
          break
        case 2:
          this._getGpaRank()
          break
        case 3:
          this.$router.push('calculatorgpa')
          break
      }
    },
    moreHandle() {
      this.isShowMore = !this.isShowMore
    },
    _getGpaRank() {
      const closeToastAction = this.$Toast({
        options: {
          time: 0,
          toastContent: '请稍后',
          mask: true,
        },
      })
      getGpaRank(this.cookie)
        .then((res) => {
          closeToastAction()
          if (!res.data.scoreInfo) {
            const that = this
            this.$Dialog({
              options: {
                dialogContent: '暂无成绩信息！',
                cancelText: '取消',
                confirmText: '确定',
                isShowDialog: true,
              },
            }).then(() => {
              this.isShowDialog = false
              that.$router.replace('index')
            })
            return
          }
          this.$Toast({
            options: {
              time: 1000,
              toastType: 'correct',
              toastContent: '更新成功',
            },
          })
          this.gpaRankInfo = [Object.assign({}, res.data.scoreInfo, res.data.gpaRankInfo)]
          this.currentSemesterScore = res.data.scoreInfo
          this.currentSemesterScore.info.forEach((score) => {
            this.calGpa(score)
          })
        })
        .catch((error) => {
          const { response } = error
          if (response.status !== 403 && response.status !== 401) {
            this.errHandle()
          }
          closeToastAction()
        })
        .finally(() => {})
    },
    errHandle() {
      this.$Dialog({
        options: {
          dialogContent: '排名估算信息更新失败，是否立即重新获取',
          cancelText: '取消',
          confirmText: '确定',
          isShowDialogCancel: true,
          isShowDialog: true,
        },
      }).then(() => {
        this.isShowDialog = false
        this._getGpaRank()
      })
    },
    calGpa(scoreItem) {
      if (scoreItem['课程属性'] === '任选' || this.sportReg.test(scoreItem['课程名']) || this.militaryReg.test(scoreItem['课程名'])) {
        scoreItem.gpa = 0
        return
      }
      let score = parseFloat(scoreItem['成绩'])
      if (/[\u4e00-\u9fa5]{2}/.test(scoreItem['成绩'])) {
        switch (scoreItem['成绩']) {
          case '优秀':
            score = 90
            break
          case '良好':
            score = 80
            break
          case '中等':
            score = 70
            break
          case '及格':
            score = 60
            break
          case '合格':
            score = 60
            break
          default:
        }
      }
      const gpa = (((score - 50) / 10) * scoreItem['学分']).toFixed(4)
      scoreItem.gpa = +gpa
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'common/scss/variable.scss';
@import 'common/scss/project-name';

.check-gpa {
  padding: 0 20px;
  .gpaRank-line {
    display: flex;
    color: $color-spectre-main;
    .line-left {
      width: 50%;
      text-align: right;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .line-right {
      width: 50%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .gpa-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-border-light;
    .item-left {
      padding: 10px 0;
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .item-left-top {
        line-height: 35px;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .item-left-bottom {
        display: flex;
        line-height: 25px;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .more-btn {
    position: fixed;
    bottom: 30px;
    right: 20px;
  }
}
</style>
