import Vue from 'vue'
import Dialog from 'base/self-dialog/dialog'

let vm
const noop = () => {}

function createVNode(resolve = noop, reject = noop, props) {
  return new Vue({
    mixins: [
      {
        mounted() {
          document.body.appendChild(this.$el)
        },
        beforeDestroy() {
          document.body.removeChild(this.$el)
        }
      }
    ],
    beforeDestroy() {
      vm = null
    },
    methods: {
      confirmBtnHandle() {
        resolve('confirm')
        this.$destroy()
      },
      cancelBtnHandle() {
        reject('cancel')
        this.$destroy()
      }
    },
    render(createElement) {
      return createElement(Dialog, {
        on: {
          confirmBtnHandle: this.confirmBtnHandle,
          cancelBtnHandle: this.cancelBtnHandle
        },
        props
      })
    }
  })
}

function show(props) {
  return new Promise((resolve, reject) => {
    if (vm) {
      vm.$destroy()
    }
    vm = createVNode(resolve, reject, props)
    vm.$mount()
  })
}

export default {
  install(vue) {
    vue.prototype.$Dialog = show
  }
}
