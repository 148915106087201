<template>
  <transition name="setWeeks">
    <div class="set-weeks-modal" ref="modal">
      <div class="mask animation-fade-in" @click="hideWeekHandle"></div>
      <div class="modal animation-fade-in">
        <div
          :class="`modal-item ${index === currentWeekOfSemester ? 'active' : ' '}`"
          ref="modalItem"
          @click="itemHandle(index)"
          v-for="index in 24"
          :key="index"
        >
          {{ index }}
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    weeks: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(['currentWeekOfSemester']),
  },
  methods: {
    hideWeekHandle() {
      this.$emit('hideWeekHandle')
    },
    itemHandle(index) {
      this.$emit('itemHandle', index)
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'common/scss/mixin.scss';
.animation-fade-in {
  @include fade-modal;
}
.mask {
  @include mask;
}
.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 275px;
  height: 150px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 5000;
  background-color: #fff;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}
.modal-item {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  border: 1px solid #ccc;
  text-align: center;
}
.active {
  border: 1px solid #e30b5e;
  background-color: #e30b5e;
  color: #fff;
}
</style>
