<template>
  <div class="course-table">
    <div v-for="(course, index) in parsedCourses" :key="index">
      <CourseItem @courseClick="courseClick" :course="course" />
    </div>
    <CourseItemModel @hideActiveCourse="hideActiveCourse" v-if="activeCourse" :course="activeCourse" />
  </div>
</template>
<script>
import CourseItem from './course-item'
import CourseItemModel from './course-item-model'
export default {
  name: 'course-table',
  components: {
    CourseItem,
    CourseItemModel,
  },
  props: {
    parsedCourses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeCourse: null,
    }
  },
  methods: {
    hideActiveCourse() {
      this.activeCourse = null
    },
    courseClick(course) {
      this.activeCourse = course
    },
  },
}
</script>
<style lang="scss" scoped>
.course-table {
  position: relative;
  width: 100%;
}
</style>