<template>
  <div class="modify_psw">
    <div style="height: 20vh"></div>
    <div class="title">修改教务密码</div>
    <div class="form-group">
      <input :class="'form-input'" type="text" v-model="oldPsw" id="input-name" placeholder="旧密码" />
    </div>
    <div class="form-group">
      <input
        :class="newPswError ? 'form-input is-error' : 'form-input'"
        type="text"
        v-model="newPsw"
        id="input-name"
        placeholder="新密码"
        @input="changeNewPsw"
      />
      <p class="form-input-hint" v-show="newPswError">必须包含大写、小写字母、数字、特殊字符至少三种字符，且长度至少为8位</p>
    </div>
    <div style="text-align: center">
      <button style="width: 100%" class="btn btn-primary" :disabled="newPswError" @click="submit">修改</button>
    </div>
  </div>
</template>

<script>
import { modifyPsw } from 'api/course'
import { checkCookieConnect } from 'api/course'
export default {
  name: 'modify-psw',
  data() {
    return {
      newPsw: '',
      oldPsw: '',
      newPswError: false,
    }
  },
  computed: {},
  mounted() {
    checkCookieConnect()
  },
  methods: {
    submit() {
      modifyPsw(this.newPsw, this.oldPsw)
        .then(() => {
          this.$Dialog({
            options: {
              dialogTitle: '修改成功',
              dialogContent: '教务处登录密码同样变更，之后请用新密码登录教务，是否立即返回首页',
              confirmText: '确定',
            },
          }).then(() => {
            this.$router.replace('index')
          })
        })
        .catch((err) => {
          const { response } = err
          this.$Toast({
            options: {
              mask: false,
              toastType: 'warn',
              toastContent: response.data.errmsg,
              time: 2000,
            },
          })
        })
    },
    changeNewPsw(e) {
      const regex =
        /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,}$/
      const value = e.target.value
      this.newPswError = !regex.test(value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'common/scss/variable';
@import 'common/scss/project-name';

.form-input {
  box-sizing: border-box;
  height: 2.5rem;
}
.btn {
  height: 2.5rem;
}

.modify_psw {
  height: 100vh;
  padding: 0 15px;
  background-color: $color-background;
  width: 100%;
  box-sizing: border-box;
  .title {
    text-align: center;
    font-size: 25px;
    font-weight: 900;
    margin-bottom: 30px;
  }
  .input {
    margin: 10px 0;
  }
}
</style>