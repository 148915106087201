import { loadCourses, loadExam, loadScore } from 'common/js/cache'

const state = {
  currentWeekOfSemester: 1,
  cookie: '',
  courseCount: 0, // 本周要上的课节数
  courses: loadCourses(),
  prevParsedCourses: [],
  parsedCourses: [],
  nextParsedCourses: [],
  isShowUpdateLog: false,
  studentInfo: {},
  examArr: loadExam(),
  volunteerInfo: [],
  scoreInfos: loadScore()
}

export default state
