<template>
  <div class="remind_news">
    <div class="empty_box"></div>
    <div class="news_category" v-for="parentCategory in parentCategoryList" :key="parentCategory._id">
      <div class="news_category_title">
        <span class="title">
          {{ parentCategory.title }}
        </span>
        <div class="title_action">
          <button class="btn btn-link" @click="onSelectedAllHandle(parentCategory)">全选</button>
        </div>
      </div>
      <div class="news_category_wrapper">
        <div
          :class="['news_category_item', selectedCategoryKeys.includes(category._id) ? 'news_category_item_active' : '']"
          v-for="category in childCategoryList(parentCategory._id)"
          :key="category._id"
          @click="toggleSelectedKeys(category._id)"
        >
          <div class="item_title">{{ category.title }}</div>
        </div>
      </div>
    </div>
    <div style="height: 120px"></div>
    <div class="action">
      <div class="tips">温馨提示: <br />1. 登录密码与教务密码一致 <br />2. 教务密码默认为身份证后六位</div>
      <div class="news_category_action">
        <button :class="['btn', 'btn-primary', 'submit', loading ? 'loading' : '']" @click="submit">提交</button>
      </div>
    </div>
  </div>
</template>

<script>
const categoryList = [
  {
    title: '其他相关',
    mark: '其他相关',
    parentId: null,
    depth: 0,
    _id: 0,
  },
  {
    title: '停水',
    mark: '停水',
    parentId: 0,
    depth: 1,
    _id: 1,
  },
  {
    title: '停电',
    mark: '停电',
    parentId: 0,
    depth: 1,
    _id: 2,
  },
  {
    title: '开放时间',
    mark: '开放时间',
    parentId: 0,
    depth: 1,
    _id: 3,
  },
  {
    title: '考务',
    mark: '考务',
    parentId: null,
    depth: 0,
    _id: 4,
  },
  {
    title: '四六级',
    mark: '四、六级',
    parentId: 4,
    depth: 1,
    _id: 5,
  },
  {
    title: '考试',
    mark: '考试',
    parentId: 4,
    depth: 1,
    _id: 6,
  },
  {
    title: '补考',
    mark: '补考',
    parentId: 4,
    depth: 1,
    _id: 7,
  },
]

import * as remindNewsApi from '@/api/edu/remind_news'
import * as newsKeyApi from '@/api/edu/news_key'
export default {
  name: 'RemindNews',
  data() {
    return {
      categoryList: categoryList,
      selectedCategoryKeys: [],
      loading: false,
    }
  },
  computed: {
    parentCategoryList() {
      return this.categoryList.filter((category) => category.depth === 0)
    },
    childCategoryList() {
      return (parentId) => {
        return this.categoryList.filter((category) => category.parentId === parentId)
      }
    },
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const closeToastAction = this.$Toast({
        options: {
          time: 0,
          toastContent: '请稍后',
          mask: true,
        },
      })
      await Promise.all([this.getNewsKey(), this.getNewsRemind()])
      closeToastAction()
    },
    async getNewsKey() {
      try {
        const res = await newsKeyApi.getNewsKey({ pageSize: 99 })
        this.categoryList = res.data?.result || []
        if (!Array.isArray(this.categoryList) || this.categoryList.length === 0) {
          throw new Error('无法获取到提醒关键词列表')
        }
      } catch (error) {
        this.$Dialog({
          options: {
            dialogContent: '通知类型获取失败，请您稍后重试，即将返回首页！',
            confirmText: '确定',
          },
        }).then(() => {
          this.$router.replace('index')
        })
      }
    },
    onSelectedAllHandle({ _id }) {
      this.childCategoryList(_id).forEach((childCategory) => {
        if (!this.selectedCategoryKeys.includes(childCategory._id)) {
          this.selectedCategoryKeys.push(childCategory._id)
        }
      })
    },
    async getNewsRemind() {
      try {
        const res = await remindNewsApi.getNewsRemind()
        this.selectedCategoryKeys = res.data?.subscribeKeyList || []
      } catch (error) {
        this.$Dialog({
          options: {
            dialogContent: '通知类型获取失败，请您稍后重试，即将返回首页！',
            confirmText: '确定',
          },
        }).then(() => {
          this.$router.replace('index')
        })
      }
    },
    async submit() {
      this.loading = true
      try {
        await remindNewsApi.updateNewsRemind({ subscribeKeyList: this.selectedCategoryKeys })
        this.$Toast({
          options: {
            time: 1000,
            toastType: 'correct',
            toastContent: '提交成功',
          },
        })
      } catch (error) {
        this.$Toast({
          options: {
            time: 1000,
            toastType: 'warn',
            toastContent: '提交失败，请稍后重试！',
          },
        })
      }
      this.loading = false
    },
    toggleSelectedKeys(key) {
      const index = this.selectedCategoryKeys.findIndex((selectedKey) => selectedKey === key)
      if (index > -1) {
        this.selectedCategoryKeys.splice(index, 1)
      } else {
        this.selectedCategoryKeys.push(key)
      }
    },
    initSelectedCategoryKeys() {
      for (let i = 0; i < this.categoryList.length / 4; i++) {
        this.selectedCategoryKeys.push(this.categoryList[i].mark)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'common/scss/variable';
@import 'common/scss/project-name';

.remind_news {
  height: 100vh;
  padding: 0 15px;
  background-color: $color-background;
  width: 100%;
  box-sizing: border-box;
  .empty_box {
    height: 10px;
  }
  .news_category {
    background-color: $color-bgc-white;
    border-radius: 10px;
    margin-bottom: 20px;
    .news_category_title {
      padding: 0 10px;
      font-weight: 600;
      font-size: 13px;
      line-height: 40px;
      border-bottom: 1px solid $color-border-light;
      display: flex;
      justify-content: space-between;
    }
    .news_category_wrapper {
      color: #666666;
      padding: 20px 10px 15px;
      display: flex;
      flex-wrap: wrap;
      .news_category_item {
        line-height: 25px;
        padding: 0 10px;
        border-radius: 15px;
        border: 1px solid $color-border-light;
        margin: 0 5px 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .item_title {
          font-size: 13px;
        }
      }
      .news_category_item_active {
        color: $color-spectre-main;
        border-color: $color-spectre-main;
      }
    }
  }
  .action {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $color-bgc-white;
    .tips {
      padding: 0 20px;
      font-size: 12px;
      line-height: 20px;
      margin-top: 10px;
    }
    .news_category_action {
      text-align: center;
      padding: 10px 0;
      .submit {
        width: 100px;
      }
    }
  }
}
</style>