import Vue from 'vue'
import selfToast from './toast'

let vm

const createVNode = props => {
  return new Vue({
    mixins: [
      {
        mounted() {
          document.body.appendChild(this.$el)
        },
        beforeDestroy() {
          document.body.removeChild(this.$el)
        }
      }
    ],
    render(createElement) {
      return createElement(selfToast, { props })
    }
  })
}

const show = props => {
  if (vm) {
    vm.$destroy()
  }
  vm = createVNode(props)
  vm.$mount()
  return function() {
    vm.$destroy()
  }
}

export default {
  install(vue) {
    vue.prototype.$Toast = show
  }
}
