import router from '@/router'
import axios from 'axios'
import { redirectUrl } from 'common/js/config'

const axiosInstance = axios.create({ baseURL: process.env.VUE_APP_BASE_URL, withCredentials: true })

axiosInstance.interceptors.request.use(config => {
  if (config.mock && process.env.NODE_ENV !== 'production') {
    config.baseURL = process.env.VUE_APP_MOCK_URL
  }
  return config
})

axiosInstance.interceptors.response.use(
  res => res,
  error => {
    errHandle(error.response)
    throw error
  }
)

const errHandle = error => {
  switch (error.status) {
    case 401:
      window.location.href = redirectUrl(`login?nextUrl=${router.history.current.fullPath.slice(1)}`)
      break
    case 403:
      router.push({ path: 'login', query: { nextUrl: `${router.history.current.fullPath.slice(1)}` } })
      break
  }
}

export default axiosInstance
