<template>
  <div>
    <div class="mask animate-fade-in"></div>
    <div class="model animate-fade-in" @click="hideActiveCourse" @touchstart="wrapperTouchHandle" @touchmove="wrapperTouchHandle">
      <div class="course-items-wrap" @click="wrapperClickHandle">
        <div v-for="(item, index) in course.items" :key="index" class="course-model-item">
          <div class="course-title">{{ item.name }}</div>
          <div class="course-text">{{ _smartWeekToStr(item.smartWeeks) }}</div>
          <div class="course-text">{{ item.teacher }}</div>
          <div class="course-text" v-show="item.credit">学分：{{ item.credit }}</div>
          <div class="course-text" v-show="item.courseNumber">课程号：{{ item.courseNumber }}</div>
          <div class="course-text">{{ item.room }}</div>
          <div class="course-opts">
            <!-- 点击进入当前课程信息的编辑页 -->
            <div class="course-edit-btn" @click="onGoEditHandle(item.name)">编辑</div>
            <div class="course-cancel-btn" @click="hideActiveCourse">取消</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { smartWeekToStr } from './utils'
export default {
  props: ['course'],
  methods: {
    _smartWeekToStr(smartWeeks) {
      return smartWeekToStr(smartWeeks)
    },
    hideActiveCourse() {
      this.$emit('hideActiveCourse')
    },
    wrapperClickHandle(event) {
      event.stopPropagation()
    },
    wrapperTouchHandle(event) {
      event.stopPropagation()
    },
    onGoEditHandle(currentCourseName) {
      const { dayOfWeek } = this.course
      this.$router.push(`/edit-course?currentCourseName=${currentCourseName}&dayOfWeek=${dayOfWeek}`)
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'common/scss/mixin.scss';

.animate-fade-in {
  @include fade-modal;
}

.mask {
  @include mask;
}

.model {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 5000;
  left: 0;
  right: 0;
  top: 20px;
  bottom: 100px;
}

.course-items-wrap {
  overflow: auto;
  max-height: 100%;
}

.course-model-item {
  white-space: normal;
  width: 275px;
  background: #fff;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 18px;
}

.course-model-item:last-child {
  margin-bottom: 0;
}

.course-title {
  line-height: 24px;
  font-size: 21px;
  font-weight: 600;
}

.course-text {
  line-height: 19px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 12px;
}

.course-opts {
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
  border-top: 1px solid #eee;
  padding-top: 8px;

  .course-edit-btn {
    color: #5755d9;
  }

  .course-cancel-btn {
    color: #333;
  }
}
</style>
