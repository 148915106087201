import axiosInstance from 'common/js/axios-service'

const get = query => {
  return axiosInstance.request({
    url: 'core/dict.json',
    params: query
  })
}

export default { get }
