import * as types from './mutation-types'

const mutations = {
  [types.SET_CURRENT_WEEK_OF_SEMESTER](state, currentWeekOfSemester) {
    state.currentWeekOfSemester = currentWeekOfSemester
  },
  [types.SET_COOKIE](state, cookie) {
    state.cookie = cookie
  },
  [types.CLEAR_COOKIE](state) {
    state.cookie = ''
  },
  [types.SET_COURSE_COUNT](state, courseCount) {
    state.courseCount = courseCount
  },
  [types.SET_COURSES](state, courses) {
    state.courses = courses
  },
  [types.CLEAR_COURSE](state) {
    state.courses = []
  },
  [types.CLEAR_ALL_PARSED_COURSE](state) {
    state.parsedCourses = []
    state.prevParsedCourses = []
    state.nextParsedCourses = []
  },
  [types.SET_PREV_PARSED_COURSES](state, prevParsedCourses) {
    state.prevParsedCourses = prevParsedCourses
  },
  [types.SET_PARSED_COURSES](state, parsedCourses) {
    state.parsedCourses = parsedCourses
  },
  [types.SET_NEXT_PARSED_COURSES](state, nextParsedCourses) {
    state.nextParsedCourses = nextParsedCourses
  },
  [types.CHANGE_ISSHOW_UPDATE_LOG](state) {
    state.isShowUpdateLog = false
  },
  [types.SET_STUDENTINFO](state, studentInfo) {
    state.studentInfo = studentInfo
  },
  [types.SET_EXAMARR](state, examArr) {
    state.examArr = examArr
  },
  [types.CLEAR_EXAMARR](state) {
    state.examArr = []
  },
  [types.SET_VOLUNTEER_INFO](state, volunteerInfo) {
    state.volunteerInfo = volunteerInfo
  },
  [types.SET_SCOREINFOS](state, scoreInfos) {
    state.scoreInfos = scoreInfos
  },
  [types.CLEAR_SCOREINFOS](state) {
    state.scoreInfos = []
  },
  [types.SET_CURRENTWEEKOFSEMESTER](state, currentWeekOfSemester) {
    state.currentWeekOfSemester = currentWeekOfSemester
  }
}

export default mutations
