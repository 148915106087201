<template>
  <div class="more-operations" ref="more-operations">
    <transition-group
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
      @after-leave="afterLeave"
      :css="false"
      class="more-wrapper"
      tag="ul"
      name="more"
    >
      <li ref="listItems" v-show="isShowMore" class="more-btn" v-for="item in btnArrs" :key="item.id" @click="btnHandle(item.id)">
        <div class="more-left">{{ item.title }}</div>
        <div class="more-right">
          <svg class="icon" aria-hidden="true">
            <use v-bind:xlink:href="item.icon" />
          </svg>
        </div>
      </li>
    </transition-group>
    <div class="main-btn" @click="moreHandle" ref="mainBtn">
      <svg class="icon" aria-hidden="true">
        <use v-bind:xlink:href="mainIcon" />
      </svg>
    </div>
  </div>
</template>

<script>
import animations from 'create-keyframe-animation'
import { prefixStyle } from 'common/js/dom'

const transform = prefixStyle('transform')

export default {
  props: {
    btnArrs: {
      type: Array,
      default: () => [],
    },
    mainIcon: {
      type: String,
      default: '#icon-jiahao',
    },
    isShowMore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {
    btnHandle(id) {
      this.moreHandle()
      this.$emit('btnHandle', id)
    },
    enter(el, done) {
      let animation = {
        0: {
          opacity: 0,
          transform: 'scale(0)',
          'transform-origin': 'calc(100% - 17px) 100%',
        },
        100: {
          opacity: 1,
          transform: 'scale(1)',
          'transform-origin': 'calc(100% - 17px) 100%',
        },
      }

      animations.registerAnimation({
        name: 'open',
        animation,
        presets: {
          duration: 400,
          easing: 'linear',
        },
      })

      animations.runAnimation(el, 'open', done)
    },
    afterEnter(el) {
      animations.unregisterAnimation(el, 'open')
      el.style.animation = ''
    },
    leave(el, done) {
      let animation = {
        0: {
          opacity: 1,
          transform: 'scale(1)',
          'transform-origin': 'calc(100% - 17px) 100%',
        },
        100: {
          opacity: 0,
          transform: 'scale(0)',
          'transform-origin': 'calc(100% - 17px) 100%',
        },
      }

      animations.registerAnimation({
        name: 'close',
        animation,
        presets: {
          duration: 400,
          easing: 'linear',
        },
      })

      animations.runAnimation(el, 'close', done)
    },
    afterLeave(el) {
      animations.unregisterAnimation(el, 'close')
      el.style.animation = ''
    },
    moreHandle() {
      if (this.isShowMore) {
        this.$refs['more-operations'].style.opacity = 0.3
        this.$refs.mainBtn.style[transform] = 'rotate(0)'
      } else {
        this.$refs['more-operations'].style.opacity = 1
        this.$refs.mainBtn.style[transform] = 'rotate(90deg)'
      }
      this.$refs['more-operations'].style.transition = 'all 0.4s'
      this.$refs.mainBtn.style.transition = 'all 0.4s'
      this.$emit('moreHandle')
    },
  },
}
</script>

<style lang="scss" scoped>
.more-operations {
  z-index: 2;
  color: #fff;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  opacity: 0.3;
  .more-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 5px 15px 0;
    .more-left {
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      line-height: 25px;
      height: 25px;
      border-radius: 3px;
      padding: 0 7px;
      margin-right: 15px;
    }
    .more-right {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      background-color: #e30b5e;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .main-btn {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: #e30b5e;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @keyframes open {
    from {
      opacity: 0;
      transform: scale(0);
      transform-origin: calc(100% - 17px) 100%;
    }
    to {
      opacity: 1;
      transform: scale(1);
      transform-origin: calc(100% - 17px) 100%;
    }
  }
  @keyframes fade {
    from {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
</style>
