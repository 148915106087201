import axiosInstance from 'common/js/axios-service'

export async function getNetLoginParams() {
  return axiosInstance.request({
    url: 'getnetloginparams'
  })
}

export function networkSignIn(cookie, userInfo) {
  return axiosInstance.request({
    url: 'networksignin',
    method: 'post',
    data: {
      cookie,
      userInfo
    }
  })
}

export function getNetWorkInfo(cookie) {
  return axiosInstance.request({
    url: 'getnetworkinfo',
    params: {
      cookie
    }
  })
}

export function kickUser(uuid, cookie) {
  return axiosInstance.request({
    url: 'kickuser',
    params: {
      uuid,
      cookie
    }
  })
}
