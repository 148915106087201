<template>
  <div class="volunteer">
    <div class="logo">
      <img src="../../common/img/Youthassociation.jpg" alt />
    </div>
    <div class="title">时长查询</div>
    <div class="user">
      <div class="user-title">我的志愿服务记录</div>
      <div class="user-content">
        <div>姓名：{{ studentInfo.studentName }}</div>
        <div>学院：{{ studentInfo.faculty }}</div>
        <div>班级：{{ studentInfo.class }}</div>
        <div>学号：{{ studentInfo.studentID }}</div>
        <div>总时长：{{ totalDuration }}</div>
      </div>
    </div>
    <ul class="exam-info" v-if="volunteerInfo">
      <li class="exam-item" v-for="(item, index) in volunteerInfo" :key="index">
        <div>周数：{{ item.numOfWeeks }}</div>
        <div>服务类别：{{ item.serviceKind }}</div>
        <div>服务名称：{{ item.serviceName }}</div>
        <div>单项时长：{{ item.singleDuration }}</div>
      </li>
    </ul>
    <MoreOperations class="more-btn" @btnHandle="moreBtnHandle" @moreHandle="moreHandle" :isShowMore="isShowMore" :btnArrs="moreBtnArrs" />
    <BaseToast :options="toastOptions" />
    <BaseDialog :options="dialogOptions" />
  </div>
</template>
<script>
import { getVolunteerInfo } from 'api/course'
import BaseToast from 'base/base-toast/base-toast'
import BaseDialog from 'base/base-dialog/base-dialog'
import { mapGetters, mapMutations } from 'vuex'
import { redirectUrl } from 'common/js/config'
import MoreOperations from 'components/more-operations/more-operations'
export default {
  components: {
    BaseToast,
    BaseDialog,
    MoreOperations,
  },
  data() {
    return {
      moreBtnArrs: [{ title: '返回首页', icon: '#icon-shouye', id: 0 }],
      isShowMore: false,
      toastOptions: {
        mask: false,
        toastType: 'loading',
        toastContent: '请稍后',
        time: 1000,
        isShowToast: false,
      },
      dialogOptions: {
        mask: true,
        dialogContent: '',
        cancelText: '取消',
        confirmText: '确定',
        isShowDialog: false,
      },
    }
  },
  computed: {
    ...mapGetters(['volunteerInfo', 'studentInfo']),
    totalDuration() {
      let totalDuration = 0
      this.volunteerInfo.forEach((element) => {
        totalDuration += element.singleDuration
      })
      return totalDuration
    },
  },
  created() {
    this._getVolunteerInfo()
  },
  methods: {
    ...mapMutations({
      setVolunteerInfo: 'SET_VOLUNTEER_INFO',
      setStudentInfo: 'SET_STUDENTINFO',
    }),
    moreHandle() {
      this.isShowMore = !this.isShowMore
    },
    moreBtnHandle(id) {
      switch (id) {
        case 0:
          this.$router.push('index')
          break
      }
    },
    _getVolunteerInfo() {
      this.toastOptions = {
        isShowToast: true,
        time: 0,
        toastContent: '请稍后',
      }
      getVolunteerInfo().then((res) => {
        this.toastOptions.isShowToast = false
        if (res.data.error === 1) {
          if (res.data.errmsg === 'noSession') {
            window.location.href = redirectUrl('login?nextUrl=volunteer')
          } else if (res.data.errmsg === 'noStudentID') {
            this.$router.push('login?nextUrl=volunteer')
          } else {
            this.setStudentInfo(res.data.studentInfo)
            this.dialogOptions = {
              dialogContent: '当前学期暂无志愿信息，如仍然有问题请联系客服人员',
              cancelText: '取消',
              confirmText: '确定',
              isShowDialog: true,
              primaryHandle() {
                this.isShowDialog = false
              },
            }
          }
          return
        }
        // 把考场内容存储起来
        this.setVolunteerInfo(res.data.volunteerInfo)
        this.setStudentInfo(res.data.studentInfo)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'common/scss/variable';
@import 'common/scss/project-name';

.volunteer {
  box-sizing: border-box;
  padding: 20px;
  height: 100vh;
  background-color: $color-background;
  overflow: auto;
  .logo {
    text-align: center;
    img {
      border-radius: 50%;
      height: 100px;
    }
  }
  .title {
    line-height: 50px;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
  }
  .user {
    background-color: $color-bgc-white;
    border-radius: 10px;
    margin-bottom: 20px;
    .user-title {
      color: black;
      padding: 0 10px;
      font-weight: 600;
      font-size: 13px;
      line-height: 40px;
      border-bottom: 1px solid $color-border-light;
      .icon-wangluo {
        font-size: 20px;
      }
    }
    .user-content {
      padding: 10px 20px;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .exam-info {
    margin-top: 20px;
    .exam-item {
      background-color: $color-bgc-white;
      border-radius: 10px;
      margin-bottom: 20px;
      padding: 20px;
      font-size: 14px;
      line-height: 20px;
      box-shadow: 0px 8px 15px rgba(55, 78, 193, 0.15);
    }
  }
  .more-btn {
    position: fixed;
    bottom: 30px;
    right: 20px;
  }
}
</style>