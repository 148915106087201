import axiosInstance from 'common/js/axios-service'

export async function createCourse(data) {
  return axiosInstance.request({
    url: '/edu/create_course.json',
    method: 'post',
    data
  })
}

export async function updateCourse(data) {
  return axiosInstance.request({
    url: '/edu/update_course.json',
    method: 'post',
    data
  })
}

export async function getCustomCourse(params) {
  return axiosInstance.request({
    url: '/edu/get_self_course.json',
    params
  })
}
