import axiosInstance from 'common/js/axios-service'

export const getRankStudent = params => {
  return axiosInstance.request({
    url: 'edu/getrankstudent',
    params
  })
}

export const getRankSemester = params => {
  return axiosInstance.request({
    url: 'edu/getranksemester',
    params
  })
}
