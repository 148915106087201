<template>
  <div class="remind_score">
    <!-- <section>
      欢迎您使用成绩更新自动提醒功能<strong class="strong_content"
        >(tips: 以下几段为提醒功能介绍以及注意事项，如果您不关心这些，可以直接拉到底部开通功能)</strong
      >
    </section>
    <section>
      同学您好，我是助手运营团队的负责人，上学期由于我们学校的教务使用了新系统，助手提供的功能停止了一个期末，本学期初才重新开始提供服务，目前还有一些功能无法兼容新的教务系统，团队成员还在努力兼容，请大家稍安勿躁。
    </section>
    <section>
      本文主要还是希望能为新同学介绍一下成绩更新自动提醒功能的相关原理，毕竟知道原理才能使用的得心应手。成绩更新自动提醒：顾名思义可以在成绩更新的时候通过“东农助手”公众号给您发送提醒消息。
    </section>
    <section>
      <strong class="strong_content">
        由于我们学校的成绩都会公布在教务官网，公布新成绩之后并不能实时的通知到我们，而是需要我们每隔一段时间去检查是否出现了新成绩，实际查询过程也比较繁琐，对于关心成绩的同学难免会花费一些时间。
      </strong>
    </section>
    <section>
      成绩更新自动提醒的功能原理是什么呢？其实是机器帮助我们完成了从教务系统获取成绩并校验是否有新成绩更新的操作，根据校验结果给您各位推送消息。在这个过程中为了不给教务系统的服务器产生多余的压力，我们只是根据每个班级单个同学的成绩是否更新来通知当前班级所有的同学。
    </section>
    <section>
      那么机器是如何获取我们的成绩的呢？事实上和我们手动获取并无区别，机器获取成绩的过程中需要模拟登录教务并访问成绩页面，该操作与我们在助手或者其他三方微信教务获取成绩的过程别无二至，这也就是我们常说的“爬虫技术”。
    </section>
    <section>
      所以说成绩更新自动提醒功能是需要使用大家的账号及密码去获取成绩信息的，如果您继续使用该功能您需要注意并知晓的是成绩更新自动提醒功能可能会使用您的账号信息查询成绩并通过微信公众号"东农助手"给您推送成绩更新的相关提醒
    </section>
    <section>
      成绩更新提醒功能将于2022年6月12日早七点正式开始运行，<strong class="strong_content"
        >由于教务官网更换了新的教务系统，
        之前已经设置接收成绩更新提醒的同学需要在下方表单处更新密码；新用户需要按照提示完成授权便可以开通该功能。</strong
      >上线初期难免会有体验不好、程序bug等问题，还请大家多担待，大家有任何问题可以随时
      <router-link to="/contact">联系客服</router-link> ，工作人员看到以后会立即回复。
    </section> -->
    <div style="height: 20vh"></div>
    <div class="title">成绩自动提醒授权</div>
    <div style="height: 20px"></div>
    <div class="signin">
      <div class="form-group">
        <input
          class="form-input"
          type="text"
          :maxlength="9"
          v-model="userInfo.studentID"
          id="input-name"
          :disabled="isBindIng"
          placeholder="账号"
        />
      </div>
      <div class="form-group">
        <input class="form-input" v-model="userInfo.passWord" type="password" id="input-password" placeholder="密码" />
      </div>
      <div class="form-group char-code-input">
        <input class="form-input" :maxlength="4" v-model="userInfo.charCode" type="text" placeholder="验证码" />
        <img :src="charCodeUrl" alt="这里是验证码" />
      </div>
    </div>
    <div class="actions" v-if="scoreUpdate">
      <button style="width: 100%" class="btn" @click="submit">更新密码</button>
      <button style="width: 100%" class="btn btn-error" @click="closeRemind">关闭通知</button>
    </div>
    <div class="actions" v-else>
      <button style="width: 100%" class="btn btn-primary" @click="submit">授权登录</button>
      <button style="width: 100%" class="btn" @click="$router.push('index')">返回首页</button>
    </div>
    <div style="font-size: 12px; line-height: 20px; margin-top: 20px">
      温馨提示：<br />1. 该密码与教务密码一致，教务密码默认为身份证后六位<br />2. 关于该功能的任何问题，请随时<router-link to="/contact"
        >联系客服</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { checkBindIng, getLoginParams, changeScoreUpdate } from 'api/course'
import { redirectUrl } from 'common/js/config'
import { createRemindScore } from 'api/edu/remind_score'
import { getStudentInfo } from 'api/edu/student'

export default {
  data() {
    return {
      charCodeUrl: '',
      scoreUpdate: false,
      isFocus: false,
    }
  },
  computed: {
    ...mapGetters(['studentInfo', 'cookie']),
    isBindIng() {
      return Object.keys(this.studentInfo).includes('studentID')
    },
    userInfo() {
      return {
        studentID: Object.keys(this.studentInfo).includes('studentID') ? this.studentInfo.studentID : '',
        passWord: '',
        charCode: '',
      }
    },
  },
  created() {
    this.featureError()
    // this._getLoginParams()
    // this._checkBindIng()
  },
  methods: {
    ...mapMutations({
      setStudentInfo: 'SET_STUDENTINFO',
    }),
    featureError() {
      this.$Dialog({
        options: {
          dialogTitle: '抱歉，功能维护',
          dialogContent: '由于教务更换了登录方式，成绩更新自动提醒功能需要重构，预计2023-2024-2学期可正常使用',
          cancelText: '取消',
          confirmText: '返回首页',
          isShowDialog: true,
        },
      }).then(() => {
        this.isShowDialog = false
        this.$router.replace('index')
      })
    },
    _getStudentInfo() {
      getStudentInfo()
        .then((res) => {
          this.setStudentInfo(res.data)
        })
        .catch((err) => {
          if (err.response.status === 401) {
            window.location.href = redirectUrl(`login?nextUrl=${this.$route.query.nextUrl}`)
          }
        })
    },
    closeRemind() {
      this.$Dialog({
        options: {
          isShowDialogCancel: true,
          dialogTitle: '关闭提醒',
          dialogContent: '关闭成绩更新自动提醒功能将不会再收到消息提醒，是否继续？',
          cancelText: '取消',
          confirmText: '继续',
        },
      }).then(() => {
        this._changeScoreUpdate()
      })
    },
    _changeScoreUpdate() {
      changeScoreUpdate(false).then((res) => {
        this.scoreUpdate = false
        if (res.data.error === 1) {
          this.$Toast({
            options: {
              mask: false,
              toastType: 'warn',
              toastContent: '设置失败，请稍后再试',
              time: 1000,
            },
          })
          return
        }
        this.$Dialog({
          options: {
            dialogTitle: '关闭成功',
            dialogContent: '很抱歉，无法让您满意！您不会再接到有关成绩更新的通知！是否立即返回首页',
            confirmText: '确定',
          },
        }).then(() => {
          this.$router.replace('index')
        })
        const studentInfo = Object.assign({}, this.studentInfo)
        studentInfo.scoreUpdate = this.scoreUpdate
        studentInfo.passWord = ''
        this.setStudentInfo(studentInfo)
      })
    },
    _checkBindIng() {
      if (this.isBindIng) {
        this.scoreUpdate = this.studentInfo.scoreUpdate
        return
      }
      checkBindIng().then((res) => {
        if (res.data.error === 1) {
          if (res.data.errmsg === 'noSession') {
            window.location.href = redirectUrl()
            return
          } else {
            this.$router.push('/login?nextUrl=remind-score')
            return
          }
        }
        this.setStudentInfo(res.data.studentInfo)
        this.scoreUpdate = this.studentInfo.scoreUpdate
      })
    },
    async submit() {
      if (!this.userInfo.studentID || !this.userInfo.passWord || !this.userInfo.charCode) {
        this.$Toast({
          options: {
            toastType: 'warn',
            toastContent: '请输入完整内容',
            time: 1500,
          },
        })
        return
      }
      const closeToastAction = this.$Toast({
        options: {
          toastContent: '请稍后',
          time: 0,
        },
      })
      createRemindScore(this.cookie, this.userInfo)
        .then(() => {
          closeToastAction()
          this._getStudentInfo()
          this.successHandle()
        })
        .catch((error) => {
          closeToastAction()
          this._getLoginParams()
          this.userInfo.charCode = ''
          this.$Toast({
            options: {
              toastContent: error.response.data.errmsg,
              toastType: 'warn',
              time: 1000,
            },
          })
        })
    },
    successHandle() {
      this.$Dialog({
        options: {
          dialogTitle: '授权成功',
          dialogContent: '您将接收到成绩更新的通知！是否立即返回首页',
          confirmText: '确定',
        },
      }).then(() => {
        this.$router.replace('index')
      })
    },
    async _getLoginParams() {
      const ret = await getLoginParams()
      if (ret.data.error === 1) {
        this.$Dialog({
          option: {
            dialogContent: '网络错误，请稍后重试或者联系客服！',
            cancelText: '取消',
            confirmText: '确定',
          },
        }).then(() => {
          this.$router.push('index')
        })
      }
      this.userInfo.loginToken = ret.data.loginToken
      this.charCodeUrl = 'data:image/jpeg;base64,' + ret.data.charCode
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'common/scss/variable';
@import 'common/scss/project-name';

.remind_score {
  padding: 10px;
  line-height: 25px;
  font-size: 14px;
  letter-spacing: 2px;
  .title {
    text-align: center;
    font-size: 25px;
    font-weight: 900;
    margin-bottom: 30px;
  }
  section {
    margin-top: 10px;
  }
  .cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-radius: 15px;
    background-color: $color-bgc-white;
    margin-bottom: 10px;
    .form-switch {
      padding-right: 0;
    }
  }
  .signin {
    width: 100%;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 25px;
      font-weight: 900;
      margin-bottom: 30px;
    }
    .input {
      margin: 10px 0;
    }
    .char-code-input {
      display: flex;
      align-items: center;
      height: 40px;
      .char-code {
        flex: 1;
      }
      img {
        height: 100%;
        margin-left: 10px;
      }
    }
  }
  .actions {
    margin-top: 10px;
    display: flex;
  }
}

.form-input {
  box-sizing: border-box;
  height: 2.5rem;
}
</style>
