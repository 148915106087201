<template>
  <div class="char-code-receive">
    <div v-show="!isLoading">
      <!-- <div v-if="isSuccess">领取成功</div>
      <div v-else>领取失败！您已经领取过相应福利啦</div> -->
    </div>
    <div v-show="isLoading">loading</div>
  </div>
</template>

<script>
import * as autoCharCodeActivityApi from 'api/activity/auto_char_code'
export default {
  name: 'charCodeReceive',
  data() {
    return {
      isSuccess: false,
      isLoading: false,
    }
  },
  mounted() {
    this.receiveCharCodeTrialCount()
  },
  methods: {
    async receiveCharCodeTrialCount() {
      this.isLoading = true
      let dialogContent = '是否立即前往验证码自动识别页面查看领取结果？',
        redirectUrl = 'auto-charcode',
        dialogTitle = '领取成功',
        isShowDialogCancel = false
      try {
        const { activityName, remark } = this.$route.query
        const res = await autoCharCodeActivityApi.receiveAutoCharCodeTrialCount({ activityName, remark })
        this.isSuccess = res.data.isSuccess
        if (!res.data.isSuccess) {
          throw new Error('领取失败！')
        }
      } catch (error) {
        const { response } = error
        if (response.status === 403 || response.status === 401) return
        if (error.message === '领取失败！') {
          dialogContent = '您已经领取过相应福利啦，即将返回首页！'
        } else {
          dialogContent = '即将返回首页！'
        }
        dialogTitle = '领取失败'
        redirectUrl = 'index'
        isShowDialogCancel = true
      }
      this.$Dialog({
        options: {
          dialogTitle,
          dialogContent,
          cancelText: '联系客服',
          confirmText: '确定',
          isShowDialogCancel,
        },
      })
        .then(() => {
          this.$router.replace(redirectUrl)
        })
        .catch(() => {
          this.$router.replace('contact')
        })
      this.isLoading = false
    },
  },
}
</script>

<style>
</style>