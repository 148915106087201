import dict from 'api/core/dict'
import { removeCookie, removeCourse, removeExam, removeScore, saveCookie, saveCourses, saveExam, saveScore } from 'common/js/cache'
import dayjs from 'dayjs'
import { parsedCourse, parsedCourseForAllWeek, parsedCourseForCurrentWeek } from 'pages/syllabus/parsedCourse'
import * as types from './mutation-types'

const _setCourseCount = parsedCourses => {
  let courseCount = 0
  parsedCourses.forEach(elem => {
    elem.items.forEach(e => {
      if (e.isCurrentWeek) {
        courseCount += 1
      }
    })
  })
  return courseCount
}

export async function loadWeekOfSemester({ commit }) {
  const currentDate = dayjs()
  const dateOfSetWeek = (await dict.get({ mark: 'dateOfSetWeek' })).data.result[0].dict_items[0].title
  const weekOfSemester = 1
  const dayOfStartDayOfSemester = dayjs(dateOfSetWeek).day() === 0 ? 6 : dayjs(dateOfSetWeek).day() - 1

  const diffDay = currentDate.diff(dayjs(dateOfSetWeek), 'day') + dayOfStartDayOfSemester
  const currentWeekOfSemester = Math.floor(diffDay / 7) + weekOfSemester
  commit(types.SET_CURRENTWEEKOFSEMESTER, currentWeekOfSemester <= 0 ? 1 : currentWeekOfSemester)
}

export const saveExamAndSetExam = ({ commit }, examArr) => {
  saveExam(examArr)
  commit(types.SET_EXAMARR, examArr)
}

// 修改当前周之后改变智能周
export const changeParsedCourses = function({ commit, state }) {
  const parsedCourses = parsedCourse(state.courses, state.currentWeekOfSemester)
  commit(types.SET_PARSED_COURSES, parsedCourses)
  const courseCount = _setCourseCount(parsedCourses)
  commit(types.SET_COURSE_COUNT, courseCount)
}

export const switchCoursesOfAllWeeks = function({ commit }, courses) {
  const parsedCourses = parsedCourseForAllWeek(courses)
  commit(types.SET_PARSED_COURSES, parsedCourses)
}

export const switchCoursesOfCurrentWeeks = function({ commit, state }, courses) {
  const parsedCourses = parsedCourseForCurrentWeek(courses, state.currentWeekOfSemester)
  commit(types.SET_PARSED_COURSES, parsedCourses)
}

export function saveCourseAndSetStorage({ commit }, courses) {
  saveCourses(courses)
  commit(types.SET_COURSES, courses)
}

export function saveCookieAndSetCookie({ commit }, cookie) {
  saveCookie(cookie)
  commit(types.SET_COOKIE, cookie)
}

export const exitAndClearExamAndCourse = ({ commit }, studentInfo) => {
  commit(types.CLEAR_COURSE)
  commit(types.CLEAR_EXAMARR)
  commit(types.CLEAR_COOKIE)
  commit(types.SET_COURSE_COUNT, 0)
  commit(types.SET_STUDENTINFO, studentInfo)
  commit(types.CLEAR_SCOREINFOS)
  commit(types.CLEAR_ALL_PARSED_COURSE)
  removeCourse()
  removeExam()
  removeCookie()
  removeScore()
}

export const setScoreInfosAndSaveScoreInfos = ({ commit }, scoreInfos) => {
  commit(types.SET_SCOREINFOS, scoreInfos)
  saveScore(scoreInfos)
}
