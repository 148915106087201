<template>
  <ul class="course_modal_line">
    <li>{{ source.order }}</li>
    <li>{{ source.studentName }}</li>
    <li>{{ source.Gpa }}</li>
  </ul>
</template>

<script>
export default {
  name: 'item',
  props: {
    index: {
      type: Number,
    },
    source: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'common/scss/variable';
.course_modal_line {
  padding: 0 5px;
  border-bottom: 1px solid $color-border-light;
  line-height: 30px;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  justify-items: center;
  column-gap: 5px;
}
</style>