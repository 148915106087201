<template>
  <div class="rank">
    <div class="form-group">
      <select class="form-select" @change="changeSemester" v-model="semester">
        <option v-for="semesterInfo in semesterInfoList" :key="semesterInfo._id">
          {{ semesterInfo.semester }}
        </option>
      </select>
    </div>
    <Tabs :tabList="tabList" @change="changeTab" />
    <template>
      <div class="card">
        <ul class="modal_line">
          <li>排名</li>
          <li>姓名</li>
          <li>绩点</li>
        </ul>
        <ul class="modal_line self_info">
          <li>{{ curUserRankInfo.order }}</li>
          <li>{{ curUserRankInfo.studentName }}</li>
          <li>{{ curUserRankInfo.Gpa }}</li>
        </ul>
        <VirtualList
          ref="virtualRef"
          style="height: 100%; overflow: auto"
          :data-key="'_id'"
          :data-sources="studentList"
          :data-component="itemComponent"
        ></VirtualList>
      </div>
    </template>
  </div>
</template>

<script>
import Tabs from './tab.vue'
import { getRankStudent, getRankSemester } from 'api/edu/rank'
import { newDebounce } from 'common/js/utils'
import VirtualList from 'vue-virtual-scroll-list'
import Item from './item.vue'
import { redirectUrl } from 'common/js/config'
import { mapGetters, mapMutations } from 'vuex'
import { getStudentInfo } from 'api/edu/student'

export default {
  name: 'rank',
  components: {
    Tabs,
    VirtualList,
  },
  data() {
    return {
      tabList: [
        { value: '班级排名', key: 'CLASS' },
        { value: '专业排名', key: 'MAJOR' },
      ],
      rankType: 'CLASS',
      semester: '',
      studentList: [],
      semesterInfoList: [],
      itemComponent: Item,
      curUserRankInfo: {},
    }
  },
  computed: {
    ...mapGetters(['studentInfo']),
  },
  created() {
    this.studentListStore = new Map()
    this.curUserRankInfoStore = new Map()
  },
  mounted() {
    this.getStudentID()
  },
  methods: {
    ...mapMutations({
      setStudentInfo: 'SET_STUDENTINFO',
    }),
    changeSemester() {
      this.getRankStudentList()
    },
    async _getRankSemester() {
      this.semesterInfoList = (await getRankSemester({ studentID: this.studentInfo.studentID })).data.reverse()
      for (let semesterInfo of this.semesterInfoList) {
        this.studentListStore.set(semesterInfo.semester, new Map())
        this.curUserRankInfoStore.set(semesterInfo.semester, new Map())
      }
      this.semester = this.semesterInfoList[0].semester
      this.getRankStudentList()
    },
    _getStudentInfo() {
      getStudentInfo()
        .then((res) => {
          this.setStudentInfo(res.data)
          this._getRankSemester()
        })
        .catch((err) => {
          if (err.response.status === 401) {
            window.location.href = redirectUrl(`login?nextUrl=${this.$route.query.nextUrl}`)
          } else if (err.response.status === 403) {
            this.$router.push({ path: 'login', query: { nextUrl: 'rank' } })
          }
        })
    },
    getStudentID() {
      if (this.studentInfo && this.studentInfo.studentID) {
        this._getRankSemester()
      } else {
        this._getStudentInfo()
      }
    },
    resetVirtualList() {
      this.$refs.virtualRef.reset()
    },
    async getRankStudentList() {
      if (this.studentListStore.get(this.semester).get(this.rankType)) {
        this.studentList = this.studentListStore.get(this.semester).get(this.rankType)
        this.curUserRankInfo = this.curUserRankInfoStore.get(this.semester).get(this.rankType)
        return
      }
      const closeToastAction = this.$Toast({
        options: {
          toastContent: '请稍后',
          time: 0,
        },
      })
      const classmates = (await getRankStudent({ semester: this.semester, rankType: this.rankType, studentID: this.studentInfo.studentID }))
        .data
      classmates.sort((prev, cur) => {
        return cur.Gpa - prev.Gpa
      })
      let prev = 0
      for (let i = 0; i < classmates.length; i++) {
        if (classmates[i].Gpa === prev) {
          classmates[i].order = classmates[i - 1].order
        } else {
          classmates[i].order = i + 1
          prev = classmates[i].Gpa
        }
        if (classmates[i].studentID === this.studentInfo.studentID) {
          this.curUserRankInfo = classmates[i]
          this.curUserRankInfoStore.get(this.semester).set(this.rankType, classmates[i])
        }
      }
      closeToastAction()
      this.studentList = Object.freeze(classmates)
      this.studentListStore.get(this.semester).set(this.rankType, Object.freeze(classmates))
    },
    changeTab(index) {
      this.rankType = this.tabList[index].key
      const getRankStudent = newDebounce(this.getRankStudentList, 200)
      getRankStudent()
      this.resetVirtualList()
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'common/scss/variable';
.rank {
  box-sizing: border-box;
  padding: 10px 20px;
  height: 100vh;
  overflow: auto;
  background-color: $color-background;
  display: flex;
  flex-direction: column;
  .card {
    background-color: $color-bgc-white;
    border-radius: 10px;
    margin-bottom: 20px;
    height: 100%;
    .modal_line {
      padding: 0 5px;
      border-bottom: 1px solid $color-border-light;
      line-height: 30px;
      display: grid;
      grid-template-columns: 1fr 2fr 2fr;
      justify-items: center;
      column-gap: 5px;
    }
    .self_info {
      color: $color-spectre-main;
    }
  }
}
</style>