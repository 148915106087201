import axiosInstance from 'common/js/axios-service'

export async function updateNewsRemind(data) {
  return axiosInstance.request({
    url: '/edu/update_news_remind.json',
    method: 'post',
    data
  })
}

export async function getNewsRemind(params) {
  return axiosInstance.request({
    url: '/edu/get_news_remind.json',
    params
  })
}
