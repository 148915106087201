import axiosInstance from 'common/js/axios-service'

export const getComprehensive = cookie => {
  return axiosInstance.request({
    url: 'edu/getcoursemodal',
    params: { cookie }
  })
}

export const searchCourseModal = courseName => {
  return axiosInstance.request({
    url: 'edu/comprehensive.json?pageSize=99',
    params: { courseName }
  })
}
