<template>
  <div class="search_course_modal">
    <div style="height: 5vh"></div>
    <div class="title">通识选修模块查询</div>
    <div class="input-group">
      <input type="text" class="form-input" v-model="courseName" placeholder="课程名" />
      <button class="btn btn-primary input-group-btn" @click="searchModal">查询</button>
    </div>
    <div style="height: 20px"></div>
    <div v-if="courseModalList.length">
      <table class="table">
        <thead>
          <tr>
            <th>课程名称</th>
            <th>开设学期</th>
            <th>课程模块</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(courseModal, index) in courseModalList" :key="index">
            <td>{{ courseModal.courseName }}</td>
            <td>{{ courseModal.semester }}</td>
            <td>{{ courseModal.courseType }}</td>
          </tr>
        </tbody>
      </table>
      <div class="end_container">到底啦~~</div>
    </div>
    <div v-else class="no_result">暂无结果～</div>
  </div>
</template>

<script>
import { searchCourseModal } from 'api/edu/comprehensive'
export default {
  data() {
    return {
      courseModalList: [],
      courseName: '',
    }
  },
  mounted() {
    this.courseName = this.$route.query.courseName
    this.searchModal()
  },
  methods: {
    searchModal() {
      searchCourseModal(this.courseName)
        .then((result) => {
          this.courseModalList = result.data.result
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'common/scss/variable';
@import 'common/scss/project-name';

.form-input {
  box-sizing: border-box;
  height: 2.5rem;
}

.btn {
  height: 2.5rem;
}

.search_course_modal {
  height: 100vh;
  padding: 0 15px;
  overflow: auto;
  background-color: $color-background;
  box-sizing: border-box;
  .title {
    text-align: center;
    font-size: 25px;
    font-weight: 900;
    margin-bottom: 30px;
  }
  .no_result {
    line-height: 20vh;
    text-align: center;
    font-size: 14px;
    color: #ccc;
  }
  .end_container {
    text-align: center;
    font-size: 14px;
    color: #ccc;
    line-height: 50px;
  }
}
</style>