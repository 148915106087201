<template>
  <div class="score">
    <div style="line-height: 50px; font-size: 30px; font-weight: bold; text-align: center">成绩查询</div>
    <div style="font-size: 13px; line-height: 20px; margin-bottom: 20px; text-align: center">
      <span style="color: #5755d9"> 有问题可以随时到主页添加客服微信咨询！ </span>
    </div>
    <CardInfo :cardInfoArr="scoreInfos">
      <template v-slot:title="scoreInfo">{{ scoreInfo.item.title }}</template>
      <template v-slot:content="scoreInfo">
        <div class="score-line">
          <div class="score-name">课程名</div>
          <div class="line-right">
            <div class="score-grade">成绩</div>
            <div class="score-credit">学分</div>
          </div>
        </div>
        <div v-for="(item, index) in scoreInfo.item.info" :key="index">
          <div class="score-line">
            <div class="score-name">{{ item['课程名'] }}</div>
            <div class="line-right">
              <div class="score-grade">{{ item['成绩'] }}</div>
              <div class="score-credit">{{ item['学分'] }}</div>
            </div>
          </div>
        </div>
        <div class="score-line gpa" v-if="scoreInfo.item.Gpa">
          <div class="score-name">智育绩点</div>
          <div>{{ scoreInfo.item.Gpa }}</div>
        </div>
      </template>
    </CardInfo>
    <MoreOperations class="more-btn" @btnHandle="moreBtnHandle" @moreHandle="moreHandle" :isShowMore="isShowMore" :btnArrs="moreBtnArrs" />
  </div>
</template>
<script>
import { getScore } from 'api/course'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import CardInfo from 'components/card-info/card-info'
import MoreOperations from 'components/more-operations/more-operations'
import { loadRemindScoreInviteCount, saveRemindScoreInviteCount } from 'common/js/cache'
import { getStudentInfo } from 'api/edu/student'
import { redirectUrl } from 'common/js/config'

export default {
  components: {
    CardInfo,
    MoreOperations,
  },
  data() {
    return {
      moreBtnArrs: [
        { title: '返回首页', icon: '#icon-shouye', id: 0 },
        { title: '更新成绩', icon: '#icon-shuaxin', id: 1 },
        { title: '排名估算', icon: '#icon-paiming', id: 2 },
        { title: '智育排名', icon: '#icon-paiming_rank', id: 3 },
      ],
      isShowMore: false,
      errorTime: 0,
    }
  },
  computed: {
    ...mapGetters(['cookie', 'scoreInfos', 'studentInfo']),
  },
  async created() {
    this.checkSource()
  },
  methods: {
    ...mapActions(['setScoreInfosAndSaveScoreInfos']),
    ...mapMutations({
      clearScoreInfos: 'CLEAR_SCOREINFOS',
      setStudentInfo: 'SET_STUDENTINFO',
    }),
    _getStudentInfo() {
      getStudentInfo()
        .then((res) => {
          this.setStudentInfo(res.data)
        })
        .catch((err) => {
          if (err.response.status === 401) {
            window.location.href = redirectUrl(`login?nextUrl=${this.$route.query.nextUrl}`)
          }
        })
    },
    moreHandle() {
      this.isShowMore = !this.isShowMore
    },
    moreBtnHandle(id) {
      switch (id) {
        case 0:
          this.$router.push('index')
          break
        case 1:
          this.updateScoreInfos()
          break
        case 2:
          this.$router.push('checkgpa')
          break
        case 3:
          this.$router.push('rank')
          break
      }
    },
    checkSource() {
      if (this.scoreInfos.length == 0) {
        this._getScore()
      } else {
        this._getStudentInfo()
      }
    },
    updateScoreInfos() {
      this._getScore()
    },
    async _getScore() {
      const closeToastAction = this.$Toast({
        options: {
          time: 0,
          toastContent: '请稍后',
          mask: true,
        },
      })
      getScore(this.cookie)
        .then((res) => {
          if (res.data.scoreInfos.length === 0) {
            this.$Dialog({
              options: {
                dialogContent: '暂无成绩信息！',
                cancelText: '取消',
                confirmText: '确定',
              },
            }).then(() => {
              this.$router.replace('index')
            })
            return
          }
          this.setScoreInfosAndSaveScoreInfos(res.data.scoreInfos)
        })
        .catch((error) => {
          const { response } = error
          if (response.status !== 403 && response.status !== 401) {
            this.errHandle()
          }
        })
        .finally(() => {
          closeToastAction()
        })
    },
    errHandle() {
      const isErrorLimit = this.errorTime >= 2
      this.$Dialog({
        options: {
          dialogTitle: '错误提醒',
          dialogContent: isErrorLimit ? '网络连接失败，多次连接失败请添加客服微信！' : '成绩信息更新失败，是否立即重新获取',
          cancelText: '取消',
          confirmText: isErrorLimit ? '联系客服' : '确定',
          isShowDialog: true,
          isShowDialogCancel: isErrorLimit,
        },
      })
        .then(() => {
          isErrorLimit ? this.$router.push('contact') : this.updateScoreInfos()
        })
        .catch(() => {})
      this.errorTime++
    },
    remindScoreInvite() {
      if ((this.studentInfo.passWord && this.studentInfo.passWord.length > 20) || loadRemindScoreInviteCount() > 15) return
      this.$Dialog({
        options: {
          isShowDialogCancel: true,
          dialogTitle: '功能邀请',
          dialogContent:
            '本学期的成绩更新自动提醒功能已经可以使用，由于更换了新的教务系统，之前已经开通该功能的老用户需要更新密码才能继续使用哦~~',
          cancelText: '取消',
          confirmText: '去看看',
        },
      })
        .then(() => {
          this.$router.push('/remind-score')
        })
        .finally(() => {
          saveRemindScoreInviteCount(loadRemindScoreInviteCount() + 1)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'common/scss/variable';
.score {
  box-sizing: border-box;
  padding: 20px;
  height: 100vh;
  overflow: auto;
  background-color: $color-background;
  .score-line {
    border-bottom: 1px solid $color-border-light;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    .score-name {
      width: 50%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .line-right {
      width: 30%;
      display: flex;
      justify-content: space-between;
    }
  }
  .gpa {
    color: $color-spectre-main;
  }
  .more-btn {
    position: fixed;
    bottom: 30px;
    right: 20px;
  }
}
</style>
