<template>
  <div class="index">
    <div ref="syllabusLists">
      <div v-for="(item, index) in 25" :key="index">
        <div
          class="syllabus-item"
          :class="{ current: currentIndex === index, active: nextIndex === index }"
          @touchstart="pageTouchStart"
          @touchmove="pageTouchMove"
          @touchend="pageTouchEnd"
        >
          <WeekBar :currentDayOfWeek="currentDayOfWeek" />
          <div class="container">
            <TimeSlotBar />
            <CourseTable :parsedCourses="parsedCourses" style="width: 100%" />
          </div>
        </div>
      </div>
    </div>
    <MoreOperations :isShowMore="isShowMore" @btnHandle="moreBtnHandle" @moreHandle="moreHandle" :btnArrs="moreBtnArrs" class="more-btn" />
    <SetWeeksModal v-if="isShowWeeks" @itemHandle="_setCurrentWeekOfSemester" @hideWeekHandle="hideWeekHandle" />
    <CourseMissingDataDialog
      @onCloseDialogHandle="onCloseDialogHandle"
      :missingInfoCourseList="missingInfoCourseList"
      v-if="isShowCourseMissingDataDialog"
    ></CourseMissingDataDialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import WeekBar from './week-bar'
import TimeSlotBar from './time-slot-bar'
import CourseTable from './course-table'
import MoreOperations from 'components/more-operations/more-operations'
import SetWeeksModal from './set-weeks-modal'
import { getCourse } from 'api/course'
import { setWebTitle } from 'common/js/utils'
import { saveWeekOfSemester } from 'common/js/cache'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import CourseMissingDataDialog from './course-missing-data-dialog.vue'
import { isMissingCourseInfo } from './utils'

export default {
  name: 'index',
  components: {
    WeekBar,
    TimeSlotBar,
    CourseTable,
    MoreOperations,
    SetWeeksModal,
    CourseMissingDataDialog,
  },
  beforeRouteEnter(to, from, next) {
    /**
     * 从登录页面进入到该页面，需要刷新课表
     */
    if (from.path.includes('login')) {
      next({ query: { type: 'refresh' } })
    } else {
      next()
    }
  },
  data() {
    return {
      currentDayOfWeek: dayjs().day(), // 今天是该周第几天
      isShowWeeks: false,
      isShowMore: false,
      isAllWeeks: false,
      currentIndex: 0,
      nextIndex: null,
      syllabusLists: [],
      // 是否显示数据缺失的弹窗
      isShowCourseMissingDataDialog: false,
      // 缺失信息的课程列表
      missingInfoCourseList: [],
    }
  },
  computed: {
    ...mapGetters(['currentWeekOfSemester', 'prevParsedCourses', 'parsedCourses', 'nextParsedCourses', 'courses', 'cookie']),
    moreBtnArrs() {
      return [
        { title: '返回首页', icon: '#icon-shouye', id: 0 },
        { title: this.isAllWeeks ? '本周课表' : '全周课表', icon: '#icon-kebiaoxinxi', id: 1 },
        { title: '修改当前周', icon: '#icon-setting-fill', id: 2 },
        { title: '新建课程', icon: '#icon-setting-fill', id: 3 },
        { title: '更新课表', icon: '#icon-shuaxin', id: 4 },
      ]
    },
    isNeedRefresh() {
      return this.$route.query?.type === 'refresh'
    },
  },
  async created() {
    await this.loadWeekOfSemester()
    setWebTitle(`第${this.currentWeekOfSemester}周课表`)
    this.currentIndex = this.currentWeekOfSemester - 1
    this.checkCourses()
    this.touch = {}
  },
  mounted() {
    this.initRender()
  },
  methods: {
    ...mapMutations({
      setCurrentWeekOfSemester: 'SET_CURRENT_WEEK_OF_SEMESTER',
      setCourses: 'SET_COURSES',
      clearCourses: 'CLEAR_COURSE',
    }),
    ...mapActions([
      'loadWeekOfSemester',
      'changeParsedCourses',
      'saveCourseAndSetStorage',
      'switchCoursesOfAllWeeks',
      'switchCoursesOfCurrentWeeks',
    ]),
    initRender() {
      this.syllabusLists = this.$refs.syllabusLists.children
    },
    pageTouchStart(e) {
      this.touch.initiated = true
      const touch = e.touches[0]
      this.touch.move = false
      this.touch.startX = touch.pageX
      this.touch.startY = touch.pageY
    },
    pageTouchMove(e) {
      if (!this.touch.initiated) {
        return
      }
      const touch = e.touches[0]
      const deltaX = touch.pageX - this.touch.startX
      const deltaY = touch.pageY - this.touch.startY

      if (Math.abs(deltaY) > Math.abs(deltaX)) {
        return
      }

      // 保证offsetWidth在屏幕范围内
      const offsetWidth = Math.min(window.innerWidth, Math.max(-window.innerWidth, deltaX))
      this.touch.direction = offsetWidth > 0 ? -1 : 1
      this.nextIndex = this.currentIndex + this.touch.direction
      if (this.nextIndex < 0 || this.nextIndex >= this.syllabusLists.length) {
        return
      }

      this.touch.percent = Math.abs(offsetWidth) / window.innerWidth
      this.touch.move = true
      this.syllabusLists[this.currentIndex].style['transform'] = `translate3d(${offsetWidth}px, 0, 0)`
      this.syllabusLists[this.nextIndex].style['transform'] = `translate3d(${
        offsetWidth + this.touch.direction * window.innerWidth
      }px, 0, 0)`
    },
    pageTouchEnd() {
      const deply = 300
      if (!this.touch.move) {
        return
      }

      if (this.touch.percent < 0.25) {
        this.syllabusLists[this.currentIndex].style.cssText = `transform: translate3d(${0}px, 0, 0); transition-duration: ${deply}ms;`
        this.syllabusLists[this.nextIndex].style.cssText = `transform: translate3d(${
          this.touch.direction * window.innerWidth
        }px, 0, 0); transition-duration: ${deply}ms;`
        return
      }

      this.syllabusLists[this.currentIndex].style.cssText = `transform: translate3d(${
        -this.touch.direction * window.innerWidth
      }px, 0, 0); transition-duration: ${deply}ms; transition-timing-function: ease-in-out;`
      this.syllabusLists[
        this.nextIndex
      ].style.cssText = `transform: translate3d(${0}px, 0, 0); transition-duration: ${deply}ms; transition-timing-function: ease-in-out;`

      setTimeout(() => {
        if (this.currentWeekOfSemester + this.touch.direction > 0 && this.currentWeekOfSemester + this.touch.direction < 26) {
          this._setCurrentWeekOfSemester(this.currentWeekOfSemester + this.touch.direction)
        }
        this.syllabusLists[this.currentIndex].style.cssText = ''
        this.syllabusLists[this.nextIndex].style.cssText = ''
        this.nextIndex = null
      }, deply)
    },
    switchCoursesForWeek() {
      if (this.isAllWeeks) {
        this.switchCoursesOfCurrentWeeks(this.courses)
        setWebTitle(`第${this.currentWeekOfSemester}周课表`)
      } else {
        this.switchCoursesOfAllWeeks(this.courses)
        this.checkCoursesInfoComplete(this.courses)
        setWebTitle('全周课表')
      }
      this.isAllWeeks = !this.isAllWeeks
    },
    _setCurrentWeekOfSemester(index) {
      this.setCurrentWeekOfSemester(index)
      this.currentIndex = index - 1
      this.changeParsedCourses()
      this.isShowWeeks = false
      saveWeekOfSemester(index, dayjs())
      setWebTitle(`第${this.currentWeekOfSemester}周课表`)
      this.isAllWeeks = false
    },
    checkCourses() {
      if (this.courses.length && !this.isNeedRefresh) {
        this.changeParsedCourses()
        return
      }
      this.handleImportCourses()
    },
    onCloseDialogHandle() {
      this.isShowCourseMissingDataDialog = false
    },
    checkCoursesInfoComplete(courseList = []) {
      this.missingInfoCourseList = courseList.filter((course) => isMissingCourseInfo(course))
      if (this.missingInfoCourseList.length) {
        this.isShowCourseMissingDataDialog = true
      }
    },
    handleImportCourses() {
      const closeToastAction = this.$Toast({
        options: {
          time: 0,
          toastContent: '请稍后',
        },
      })
      getCourse(this.cookie)
        .then((res) => {
          this.checkCoursesInfoComplete(res.data?.message || [])
          this.saveCourseAndSetStorage(res.data.message)
          this.changeParsedCourses()
        })
        .finally(() => {
          closeToastAction()
        })
    },
    moreHandle() {
      this.isShowMore = !this.isShowMore
    },
    hideWeekHandle() {
      this.isShowWeeks = false
    },
    moreBtnHandle(id) {
      switch (id) {
        case 0:
          this.$router.push('index')
          break
        case 1:
          this.switchCoursesForWeek()
          break
        case 2:
          this.isShowWeeks = true
          break
        case 3:
          this.$router.push('create-course')
          break
        case 4:
          this.updateCourse()
          break
      }
    },
    updateCourse() {
      this.$Dialog({
        options: {
          dialogContent: '是否立即更新课表？',
          cancelText: '取消',
          confirmText: '确定',
          isShowDialog: true,
          isShowDialogCancel: true,
        },
      })
        .then(() => {
          this.handleImportCourses()
        })
        .catch(() => {
          this.isShowDialog = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.index {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  .syllabus-item {
    position: absolute;
    height: 100%;
    width: 100%;
    display: none;
  }
  .current {
    display: block;
    will-change: transform;
  }
  .active {
    display: block;
    will-change: transform;
  }
  .container {
    display: flex;
  }
  .more-btn {
    position: fixed;
    bottom: 30px;
    right: 20px;
  }
}
</style>
