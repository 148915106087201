export const SET_CURRENT_WEEK_OF_SEMESTER = 'SET_CURRENT_WEEK_OF_SEMESTER'

export const SET_COOKIE = 'SET_COOKIE'

export const CLEAR_COOKIE = 'CLEAR_COOKIE'

export const SET_COURSE_COUNT = 'SET_COURSE_COUNT'

export const SET_COURSES = 'SET_COURSES'

export const CLEAR_COURSE = 'CLEAR_COURSE'

export const SET_PREV_PARSED_COURSES = 'SET_PREV_PARSED_COURSES'

export const SET_PARSED_COURSES = 'SET_PARSED_COURSES'

export const SET_NEXT_PARSED_COURSES = 'SET_NEXT_PARSED_COURSES'

export const CLEAR_ALL_PARSED_COURSE = 'CLEAR_ALL_PARSED_COURSE'

export const CHANGE_ISSHOW_UPDATE_LOG = 'CHANGE_ISSHOW_UPDATE_LOG'

export const SET_STUDENTINFO = 'SET_STUDENTINFO'

export const SET_EXAMARR = 'SET_EXAMARR'

export const SET_VOLUNTEER_INFO = 'SET_VOLUNTEER_INFO'

export const CLEAR_EXAMARR = 'CLEAR_EXAMARR'

export const SET_SCOREINFOS = 'SET_SCOREINFOS'

export const CLEAR_SCOREINFOS = 'CLEAR_SCOREINFOS'

export const SET_CURRENTWEEKOFSEMESTER = 'SET_CURRENTWEEKOFSEMESTER'
