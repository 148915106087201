import axiosInstance from 'common/js/axios-service'

export function authorize(code) {
  return axiosInstance.request({
    url: 'authorize',
    params: {
      code: code
    }
  })
}

export function loginOut() {
  return axiosInstance.request({
    url: 'loginout'
  })
}
