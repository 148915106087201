<template>
  <div class="tabs">
    <div>
      <ul class="tab tab-block">
        <li
          :class="'tab-item ' + (index == active ? 'active' : '')"
          v-for="(tab, index) in tabList"
          :key="tab.key"
          @click="bindTitle(index)"
        >
          <a>{{ tab.value }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
      default: () => [],
    },
    contentArr: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      active: 0,
    }
  },
  methods: {
    bindTitle(index) {
      this.active = index
      this.$emit('change', index)
    },
  },
}
</script>

<style lang='scss' scoped>
a {
  box-shadow: none;
}
</style>