<template>
  <div class="exam-room">
    <div style="line-height: 50px; font-size: 30px; font-weight: bold; text-align: center">考场查询</div>
    <CardInfo :cardInfoArr="examArr">
      <template v-slot:title="cardInfo">{{ cardInfo.item.examName }}</template>
      <template v-slot:content="cardInfo">
        <div>学号: {{ cardInfo.item.studentID }}</div>
        <div>姓名: {{ cardInfo.item.studentName }}</div>
        <div>考试时间: {{ cardInfo.item.examTime }}</div>
        <div v-show="cardInfo.item.examRoom">考试地点: {{ cardInfo.item.examRoom }}</div>
      </template>
    </CardInfo>
    <MoreOperations class="more-btn" @btnHandle="moreBtnHandle" @moreHandle="moreHandle" :isShowMore="isShowMore" :btnArrs="moreBtnArrs" />
  </div>
</template>
<script>
import { getExamInfo } from 'api/course'
import CardInfo from 'components/card-info/card-info'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { redirectUrl } from 'common/js/config'
import MoreOperations from 'components/more-operations/more-operations'
export default {
  components: {
    MoreOperations,
    CardInfo,
  },
  data() {
    return {
      moreBtnArrs: [
        { title: '返回首页', icon: '#icon-shouye', id: 0 },
        { title: '更新考场', icon: '#icon-shuaxin', id: 1 },
      ],
      isShowMore: false,
    }
  },
  computed: {
    ...mapGetters(['examArr', 'studentInfo']),
  },
  created() {
    this.checkSource()
  },
  methods: {
    ...mapMutations({
      setEaxmArr: 'SET_EXAMARR',
    }),
    ...mapActions(['saveExamAndSetExam']),
    moreHandle() {
      this.isShowMore = !this.isShowMore
    },
    moreBtnHandle(id) {
      switch (id) {
        case 0:
          this.$router.push('index')
          break
        case 1:
          this._getExamInfo()
          break
      }
    },
    checkSource() {
      if (this.examArr.length == 0) {
        this._getExamInfo()
      }
    },
    _getExamInfo() {
      const closeToastAction = this.$Toast({
        time: 0,
        toastContent: '请稍后',
      })
      getExamInfo()
        .then((res) => {
          const that = this
          if (res.data.error === 1) {
            if (res.data.errmsg === 'noSession') {
              window.location.href = redirectUrl('login?nextUrl=examroom')
              return
            } else if (res.data.errmsg === 'noStudentID') {
              this.$router.push('login?nextUrl=examroom')
            }
            return
          }
          if (res.data.examInfo.length === 0 && res.data.getExamFromNeauInfo.length === 0) {
            this.$Dialog({
              options: {
                dialogContent: '暂无考试信息！',
                confirmText: '确定',
              },
            }).then(() => {
              that.$router.push('index')
            })
          }
          const examArr = res.data.examInfo.concat(res.data.getExamFromNeauInfo)
          // 把考场内容存储起来
          this.saveExamAndSetExam(examArr)
        })
        .finally(() => {
          closeToastAction()
        })
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'common/scss/variable';
@import 'common/scss/project-name';

.form-input {
  box-sizing: border-box;
  height: 2.5rem;
}

.exam-room {
  box-sizing: border-box;
  padding: 20px;
  height: 100vh;
  background-color: $color-background;
  overflow: auto;
  .exam-info {
    padding: 20px;
    font-size: 14px;
    line-height: 20px;
    box-shadow: 0px 8px 15px rgba(55, 78, 193, 0.15);
  }
  .more-btn {
    position: fixed;
    bottom: 30px;
    right: 20px;
  }
}
</style>