<template>
  <div class="network-login">
    <div style="height: 20vh;"></div>
    <div class="title">校园网登录系统</div>
    <div class="form-group">
      <input class="form-input" type="text" :maxlength="9" v-model="userInfo.userName" id="input-name" placeholder="学号" />
    </div>
    <div class="form-group">
      <input class="form-input" v-model="userInfo.passWord" type="password" id="input-password" placeholder="默认密码身份证号后八位" />
    </div>
    <div class="form-group char-code-input">
      <input class="form-input" :maxlength="4" v-model="userInfo.charCode" type="text" id="input-charcode" placeholder="验证码" />
      <img :src="charCodeUrl" alt="这里是验证码" />
    </div>
    <div style="text-align: center;">
      <button style="width:100%" class="btn" @click="submit">登录</button>
    </div>
    <div class="form-group remember-me">
      <label class="form-checkbox">
        <input type="checkbox" v-model="rememberMe" />
        <i class="form-icon"></i>记住我
      </label>
    </div>
    <div style="font-size: 14px;line-height: 20px;">
      功能介绍：选中“记住我”之后下次登录便不需要再输入账号密码
    </div>
    <BaseToast :options="toastOptions" />
    <!-- <router-view></router-view> -->
  </div>
</template>

<script>
import { getNetLoginParams, networkSignIn } from 'api/neau-network'
import { saveNetWork, removeNetWork, loadNetWork } from 'common/js/cache'
import BaseToast from 'base/base-toast/base-toast'

export default {
  components: {
    BaseToast
  },
  data() {
    return {
      cookie: '',
      charCodeUrl: '',
      rememberMe: false,
      userInfo: {
        userName: '',
        passWord: '',
        charCode: ''
      },
      toastOptions: {
        mask: false,
        toastType: 'loading',
        toastContent: '请稍后',
        time: 1000,
        isShowToast: false
      },
      dialogOptions: {
        mask: true,
        dialogContent: '',
        cancelText: '取消',
        confirmText: '确定',
        isShowDialog: false
      }
    }
  },
  created() {
    this._getNetworkLoginParams()
    this._loadNetWork()
  },
  methods: {
    _loadNetWork() {
      const netWorkUserInfo = loadNetWork()
      if (!netWorkUserInfo.rememberMe) return
      this.rememberMe = true
      this.userInfo.userName = netWorkUserInfo.userName
      this.userInfo.passWord = netWorkUserInfo.passWord
    },
    _getNetworkLoginParams() {
      getNetLoginParams().then(res => {
        this.cookie = res.data.cookie
        this.charCodeUrl = 'data:image/jpeg;base64,' + res.data.charCode
      })
    },
    async submit() {
      if (!this.userInfo.userName || !this.userInfo.passWord || !this.userInfo.charCode) {
        this.toastOptions = {
          isShowToast: true,
          toastType: 'warn',
          toastContent: '请输入完整内容',
          time: 1500
        }
        return
      }
      this.toastOptions = {
        isShowToast: true,
        time: 0,
        toastContent: '请稍后'
      }
      networkSignIn(this.cookie, this.userInfo).then(result => {
        this.toastOptions.isShowToast = false
        if (result.data.resultCode === 200) {
          if (this.rememberMe) {
            const netWorkUserInfo = {
              rememberMe: this.rememberMe,
              userName: this.userInfo.userName,
              passWord: this.userInfo.passWord
            }
            saveNetWork(netWorkUserInfo)
          } else {
            removeNetWork()
          }
          this.$router.replace({
            path: '/broken',
            query: { cookie: this.cookie }
          })
          return
        }
        this._getNetworkLoginParams()
        this.userInfo.charCode = ''
        this.toastOptions = {
          toastContent: result.data.resultMsg,
          toastType: 'warn',
          isShowToast: true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'common/scss/variable';
@import 'common/scss/project-name';

.form-input {
  box-sizing: border-box;
  height: 2.5rem;
}
.btn {
  height: 2.5rem;
}
.network-login {
  height: 100vh;
  padding: 0 15px;
  background-color: $color-background;
  width: 100%;
  box-sizing: border-box;
  .title {
    text-align: center;
    font-size: 25px;
    font-weight: 900;
    margin-bottom: 30px;
  }
  .input {
    margin: 10px 0;
  }
  .char-code-input {
    display: flex;
    align-items: center;
    .char-code {
      flex: 1;
    }
    img {
      margin-left: 10px;
    }
  }
  .remember-me {
    font-size: 14px;
    .form-checkbox {
      display: flex;
      align-items: center;
    }
  }
}
</style>
