<template>
  <div class="signin">
    <div style="height: 20vh"></div>
    <div class="title">内网登录</div>
    <div class="form-group">
      <input class="form-input" type="text" :maxlength="9" v-model="userInfo.studentID" id="input-name" placeholder="学号" />
    </div>
    <div class="form-group char-code-input">
      <input class="form-input" v-model="userInfo.passWord" type="text" :placeholder="psdPlaceholder" />
      <button class="btn" @click="_getOtp" :disabled="sendPsdBtnDisabled">{{ sendPsdBtnText }}</button>
    </div>
    <div style="text-align: center">
      <button style="width: 100%" class="btn" @click="submit">登录</button>
    </div>
    <div style="font-size: 14px; line-height: 20px; margin-top: 20px">
      温馨提示：<br />1. 该登录方式通过校园VPN方式登录<br />2.
      校园VPN有两种登录方式，第一种初始账号是学号，初始密码是校园网密码(没有校园网的同学可以试试身份证后8位)；第二种需要绑定VPN公众号，初始账号依然是学号，可点击获取密码并到VPN公众号内查看登录密码；
    </div>
  </div>
</template>

<script>
import { getStudentInfo } from 'api/edu/student'
import { mapMutations, mapGetters } from 'vuex'
import { getOtp, getCookie, signIn } from 'api/vpn/auth'

export default {
  name: 'signin',
  data() {
    return {
      userInfo: {
        studentID: '',
        passWord: '',
      },
      sendPsdBtnText: '获取密码',
      sendPsdIsNotAccept: false,
    }
  },
  computed: {
    ...mapGetters(['cookie', 'studentInfo']),
    isBindIng() {
      return Object.keys(this.studentInfo).includes('studentID')
    },
    sendPsdBtnDisabled() {
      return !this.userInfo.studentID || this.sendPsdIsNotAccept
    },
    psdPlaceholder() {
      return this.sendPsdIsNotAccept ? '密码已发送到手机' : '密码'
    },
  },
  async created() {
    await this._getStudentInfo()
    this._getCookie()
  },
  methods: {
    ...mapMutations({
      setCookie: 'SET_COOKIE',
      setStudentInfo: 'SET_STUDENTINFO',
    }),
    _getOtp() {
      const data = {
        csrfToken: this.csrfToken,
        login: this.userInfo.studentID,
      }
      getOtp(data)
        .then(() => {
          this.sendPsdIsNotAccept = true
          this.sendPsdAcceptStatusInterval()
        })
        .catch((error) => {
          this.$Toast({
            options: {
              toastType: 'warn',
              toastContent: error.response.data.errmsg,
            },
          })
        })
    },
    sendPsdAcceptStatusInterval() {
      let time = 60
      const timer = setInterval(() => {
        if (time > 0) {
          time--
          this.sendPsdBtnText = `${time}秒`
        } else {
          this.sendPsdBtnText = '获取密码'
          clearInterval(timer)
          this.sendPsdIsNotAccept = false
        }
      }, 1000)
    },
    async _getCookie() {
      const res = await getCookie()
      this.csrfToken = res.data.csrfToken
    },
    _getStudentInfo() {
      getStudentInfo()
        .then((res) => {
          this.setStudentInfo(res.data)
          res.data.hasOwnProperty('studentID') && (this.userInfo.studentID = res.data.studentID)
        })
        .catch(() => {})
    },
    async submit() {
      if (!this.userInfo.studentID || !this.userInfo.passWord) {
        this.$Toast({
          options: {
            toastType: 'warn',
            toastContent: '请输入完整内容',
          },
        })
        return
      }
      const closeToastAction = this.$Toast({
        options: {
          time: 0,
          toastContent: '请稍后',
        },
      })
      signIn(Object.assign({}, this.userInfo, { csrfToken: this.csrfToken }))
        .then(() => {
          closeToastAction()
          this.$router.replace({ path: 'login', query: this.$route.query })
        })
        .catch((error) => {
          this.$Toast({
            options: {
              time: 1000,
              toastType: 'warn',
              toastContent: error.response.data.errmsg,
            },
          })
        })
        .finally(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'common/scss/variable';
@import 'common/scss/project-name';

.form-input {
  box-sizing: border-box;
  height: 2.5rem;
}
.btn {
  height: 2.5rem;
}
.signin {
  height: 100vh;
  padding: 0 15px;
  background-color: $color-background;
  width: 100%;
  box-sizing: border-box;
  .title {
    text-align: center;
    font-size: 25px;
    font-weight: 900;
    margin-bottom: 30px;
  }
  .input {
    margin: 10px 0;
  }
  .char-code-input {
    display: flex;
    align-items: center;
    .char-code {
      flex: 1;
    }
    button {
      width: 70px;
    }
  }
  .remember-me {
    font-size: 14px;
    .form-checkbox {
      display: flex;
      align-items: center;
    }
  }
}
</style>
