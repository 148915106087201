<template>
  <div class="ad-container">
    <a :href="adUrl">
      <img :src="imgUrl" alt />
    </a>
  </div>
</template>
<script>
export default {
  name: 'ad',
  props: {
    adUrl: String,
    imgUrl: String
  }
}
</script>
<style lang="scss" scoped>
.ad-container {
  padding: 10px 0;
  img {
    width: 100%;
    border-radius: 5px;
  }
}
</style>