import axiosInstance from 'common/js/axios-service'

export function checkBindIng() {
  return axiosInstance.request({
    url: 'checkbinding'
  })
}

export async function getLoginParams() {
  return axiosInstance.request({
    url: 'getloginparmas'
  })
}

export async function getCode(charCode) {
  return axiosInstance.request({
    url: 'getcode',
    method: 'post',
    data: { charCode }
  })
}

export function signIn(userInfo) {
  return axiosInstance.request({
    url: 'signin',
    method: 'post',
    data: { userInfo }
  })
}

export const modifyPsw = (newPwd, oldPwd) => {
  return axiosInstance.request({
    url: 'modifypsw',
    method: 'post',
    data: { newPwd, oldPwd }
  })
}

export function getCourse(cookie) {
  return axiosInstance.request({
    url: 'getcourse',
    params: { cookie },
    timeout: 10000
  })
}

export function getSelfStudyRoom(selfStudyInfo) {
  return axiosInstance.request({
    url: 'getselfstudy',
    method: 'post',
    data: {
      selfStudyInfo
    }
  })
}

export function getNefuCourse(cookie) {
  return axiosInstance.request({
    url: 'getnefucourse',
    params: {
      cookie: cookie
    }
  })
}

export const checkCookieConnect = function() {
  return axiosInstance.request({
    url: 'checkcookieconnect'
  })
}

export const getExamInfo = function() {
  return axiosInstance.request({
    url: 'getexaminfo'
  })
}

export const getVolunteerInfo = () => {
  return axiosInstance.request({
    url: 'getvolunteerinfo'
  })
}

export const clearUserInfo = () => {
  return axiosInstance.request({
    url: 'clearuserinfo'
  })
}

export const changeExamUpdate = examUpdate => {
  return axiosInstance.request({
    url: 'changeexamupdate',
    params: { examUpdate }
  })
}

export const changeScoreUpdate = scoreUpdate => {
  return axiosInstance.request({
    url: 'changescoreupdate',
    params: { scoreUpdate }
  })
}

export const getScore = cookie => {
  return axiosInstance.request({
    url: 'getscore',
    params: { cookie }
  })
}

export const getResetSubInfo = () => {
  return axiosInstance.request({
    url: 'getresetsub'
  })
}

export const getExamFromNeau = () => {
  return axiosInstance.request({
    url: 'getexamfromneau'
  })
}

export const getGpaRank = cookie => {
  return axiosInstance.request({
    url: 'getgparank',
    params: { cookie }
  })
}
