import axios from 'axios'

/**
 *
 * @returns
 * notes: 由于获取学生信息的方法本身就在登录页面调用，如果使用axiosInstance，
 * 当接口返回403的时候，还是会重定向到登录页面，方式不够优雅！
 * 2021-09-06 by @hanhuipeng
 */
export const getStudentInfo = () => {
  return axios.request({
    url: process.env.VUE_APP_BASE_URL + 'getstudentinfo',
    withCredentials: true
  })
}
