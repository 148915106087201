<template>
  <div class="broken-network">
    <div class="wifi-img">
      <img src="../../common/img/wifi.png" alt />
    </div>
    <div class="user">
      <div class="user-title">
        <svg class="icon icon-wangluo" aria-hidden="true">
          <use xlink:href="#icon-wangluo" />
        </svg>
        我的校园网
      </div>
      <div class="user-content">
        <div>用户：{{ packageInfo.studentID }}</div>
        <div class="package">套餐：{{ packageInfo['packageName'] }}</div>
        <div class="remain-time">剩余时间：{{ packageInfo.netRemainTime }}</div>
      </div>
    </div>
    <div class="equs" v-if="netWorkInfo.length">
      <div class="equs-item" v-for="(item, index) in netWorkInfo" :key="index">
        <div class="equs-type">在线设备：{{ item['在线设备类型'] }}</div>
        <div class="equs-start-time">上线时间：{{ item['上线时间'] }}</div>
        <div class="equs-time">在线时长：{{ item['在线时长'] }}</div>
        <button class="btn" @click="_kickUser(item)">下线</button>
      </div>
    </div>
    <div v-else class="equs-item" style="text-align: center;font-weight: 600;">暂无设备在线</div>
    <div class="tips">
      温馨提示：如果进行下线操作以后页面依然显示刚刚下线的设备，并且上线时间是刚刚才开始，很可能是连接校园网的设备使用的是student_auto功能，可能需要先手动下线student_auto,然后再次点击下线功能！
    </div>
    <BaseDialog :options="dialogOptions" />
    <BaseToast :options="toastOptions" />
  </div>
</template>

<script>
import { getNetWorkInfo, kickUser } from 'api/neau-network'
import BaseToast from 'base/base-toast/base-toast'
import BaseDialog from 'base/base-dialog/base-dialog'

export default {
  components: { BaseDialog, BaseToast },
  data() {
    return {
      netWorkInfo: [],
      packageInfo: {},
      cookie: '',
      dialogOptions: {
        mask: true,
        dialogContent: '',
        cancelText: '取消',
        confirmText: '确定',
        isShowDialog: false
      },
      toastOptions: {
        mask: false,
        toastType: 'loading',
        toastContent: '请稍后',
        time: 1000,
        isShowToast: false
      }
    }
  },
  created() {
    this.checkCookie()
  },
  methods: {
    checkCookie() {
      if (!this.$route.query.cookie) {
        this.$router.push('/networklogin')
        return
      }
      getNetWorkInfo(this.$route.query.cookie).then(res => {
        this.packageInfo = res.data[0]
        this.netWorkInfo = res.data.slice(1)
      })
    },
    _kickUser(item) {
      const that = this
      this.dialogOptions = {
        dialogContent: '确定下线当前的设备吗？',
        isShowDialogCancel: true,
        cancelText: '取消',
        confirmText: '确定',
        isShowDialog: true,
        cancelHandle() {
          this.isShowDialog = false
        },
        primaryHandle() {
          this.isShowDialog = false
          that.toastOptions = {
            isShowToast: true,
            time: 0,
            toastContent: '请稍后'
          }
          const uuidReg = /;.*?&/
          const uuid = item['操作'].match(uuidReg)[0].slice(1, -1)
          kickUser(uuid, that.$route.query.cookie).then(res => {
            that.netWorkInfo = res.data.slice(1)
            that.toastOptions.isShowToast = false
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'common/scss/variable';
@import 'common/scss/project-name';

.broken-network {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  overflow: auto;
  background-color: $color-background;
  padding: 0 15px;
  .wifi-img {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border-radius: 50%;
    margin: 20px auto;
    background-color: $color-bgc-white;
    img {
      height: 60px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .user {
    background-color: $color-bgc-white;
    border-radius: 10px;
    margin-bottom: 20px;
    .user-title {
      color: black;
      padding: 0 10px;
      font-weight: 600;
      font-size: 13px;
      line-height: 40px;
      border-bottom: 1px solid $color-border-light;
      .icon-wangluo {
        font-size: 20px;
      }
    }
    .user-content {
      padding: 10px 20px;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .equs-item {
    background-color: $color-bgc-white;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    .btn {
      position: absolute;
      right: 20px;
      bottom: 10px;
      // display: block;
      // margin-left: auto;
    }
  }
  .tips {
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
