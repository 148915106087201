<template>
  <div v-if="options.isShowDialog" class="dialog">
    <div v-if="mask" class="mask animate-fade-in"></div>
    <div class="model animate-fade-in">
      <div class="dialog-model">
        <div class="dialog-title">{{options.dialogTitle}}</div>
        <div class="dialog-content" v-html="options.dialogContent"></div>
        <div class="dialog-handle">
          <div class="btn" @click="cancelBtnHandle" v-if="isShowDialogCancel">{{options.cancelText}}</div>
          <div class="btn btn-primary" @click="primaryBtnHandle">{{options.confirmText}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          mask: true,
          dialogTitle: '提示',
          dialogContent: '有问题了，请稍后重试！',
          cancelText: '取消',
          confirmText: '确定',
          isShowDialog: false,
          cancelHandle() {
            this.isShowDialog = false
          },
          primaryHandle() {
            this.isShowDialog = false
          }
        }
      }
    }
  },
  computed: {
    mask() {
      return this.options.mask || true
    },
    isShowDialogCancel() {
      return this.options.isShowDialogCancel || false
    }
  },
  methods: {
    cancelBtnHandle() {
      // this.$emit("dialogCancelHandle");
      this.options.cancelHandle()
    },
    primaryBtnHandle() {
      // this.$emit("dialogPrimaryHandle");
      this.options.primaryHandle()
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'common/scss/mixin.scss';

.animate-fade-in {
  @include fade-modal;
}

.mask {
  @include mask;
}

.model {
  @include model;
}

.dialog-model {
  width: 275px;
  background: #fff;
  border-radius: 6px;
  padding: 12px;
  .dialog-title {
    text-align: center;
    color: #5755d9;
  }
  .dialog-content {
    text-align: center;
    font-size: 14px;
    padding: 20px 0;
    line-height: 20px;
  }
  .dialog-handle {
    display: flex;
    .btn {
      flex: 1;
      text-align: center;
      font-size: 14px;
    }
  }
}
</style>