<template>
  <div class="course_missing_data">
    <div class="mask animate-fade-in"></div>
    <div class="model animate-fade-in">
      <!-- 本体 -->
      <div class="course-items-wrap">
        <div class="title">您有{{ missingInfoCourseList.length }}节课未安排上课时间/地点</div>
        <!-- 循环课程 -->
        <ol class="course-model-content">
          <li v-for="(item, index) in missingInfoCourseList" :key="index" class="course-model-item">{{ `${index + 1}. ${item.name}` }}</li>
        </ol>
        <div class="tips">您可点击下方编辑按钮去编辑该类课程</div>
        <div class="course-opts">
          <!-- 点击进入只包含缺失信息课程下拉框的编辑页 -->
          <div class="course-edit-btn" @click="onGoEditHandle">编辑</div>
          <div class="course-cancel-btn" @click="onCloseDialogHandle">关闭</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { isMissingCourseInfo } from './utils'
export default {
  name: 'CourseMissingDataDialog',
  data() {
    return {
      // 缺失信息的课程列表
      missingInfoCourseList: [],
    }
  },
  computed: {
    ...mapGetters(['courses']),
  },
  mounted() {
    this.checkCoursesInfoComplete(this.courses)
  },
  methods: {
    onGoEditHandle() {
      this.$router.push('/edit-course')
    },
    onCloseDialogHandle() {
      this.$emit('onCloseDialogHandle')
    },
    checkCoursesInfoComplete(courseList = []) {
      this.missingInfoCourseList = courseList.filter((course) => isMissingCourseInfo(course))
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'common/scss/mixin.scss';

.animate-fade-in {
  @include fade-modal;
}

.mask {
  @include mask;
}
.model {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 5000;
  left: 0;
  right: 0;
  top: 20px;
  bottom: 100px;
}
.course-items-wrap {
  overflow: auto;
  max-height: 100%;
  white-space: normal;
  width: 275px;
  background: #fff;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 18px;
  .title {
    text-align: center;
    color: #5755d9;
  }
  .course-model-content {
    margin-top: 10px;
    line-height: 20px;
  }
  .tips {
    font-size: 12px;
    line-height: 20px;
    margin-top: 8px;
  }
  .course-opts {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    border-top: 1px solid #eee;
    padding-top: 8px;

    .course-edit-btn {
      color: #5755d9;
    }
    .course-cancel-btn {
      color: #333;
    }
  }
}
</style>
