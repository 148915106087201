import axiosInstance from 'common/js/axios-service'

function getOsPayParams(params) {
  return axiosInstance.request({
    url: 'wx/get_os_pay_params',
    params
  })
}

export default { getOsPayParams }
