<template>
  <div class="index">
    <header>
      <div class="date">{{ year }}年{{ month }}月{{ date }}日</div>
      <div class="current-week">本学期第{{ currentWeekOfSemester }}周</div>
      <div class="current-week" v-if="courseCount">本周共有{{ courseCount }}节课</div>
      <router-link tag="div" to="/singleinfo" class="single-info">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-setting-fill" />
        </svg>
      </router-link>
    </header>
    <main>
      <div class="library" @click="goLibrary">
        <div class="library-left">
          <div v-show="totalSeatsOfLibrary">当前图书馆共有{{ totalSeatsOfLibrary }}个空闲座位</div>
          <div v-show="!totalSeatsOfLibrary">15分钟以后有{{ totalSeatsOfLibraryThen }}个空闲座位</div>
        </div>
        <div class="library-right">
          详情
          <span class="iconfont icon-iconfonticonfonti2copycopy"></span>
        </div>
      </div>
      <div class="feature" v-for="(parentMenu, index) in getParentMenuLists" :key="index">
        <div class="feature-title">{{ parentMenu.title }}</div>
        <div class="feature-wrapper">
          <div class="feature-item" v-for="(item, index) in getChildMenuLists(parentMenu._id)" :key="index" @click="goFeaturesItem(item)">
            <span class="iconfont" :class="item.icon"></span>
            <div class="item-title">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { getSeatInfo } from 'api/library'
import { authorize } from 'api/authorize'
import { getMenuLists } from 'api/core/menu'

export default {
  data() {
    return {
      seatInfos: [],
      menuLists: [],
    }
  },
  computed: {
    ...mapGetters(['currentWeekOfSemester', 'courseCount', 'courses', 'cookie', 'isShowUpdateLog', 'studentInfo']),
    totalSeatsOfLibrary() {
      let num = 0
      this.seatInfos.forEach((element) => {
        num += parseInt(element.syzw)
      })
      return num
    },
    totalSeatsOfLibraryThen() {
      let num = 0
      this.seatInfos.forEach((element) => {
        num += parseInt(element.syzw15)
      })
      return num
    },
    year() {
      return dayjs().year()
    },
    month() {
      return dayjs().month() + 1
    },
    date() {
      return dayjs().date()
    },
    isBindIng() {
      return Object.keys(this.studentInfo).includes('studentID')
    },
    getParentMenuLists() {
      return this.menuLists.filter((menu) => menu.depth === 0).sort(this.menuSort)
    },
  },
  async created() {
    await this.loadWeekOfSemester()
    this._getSeatInfo()
    await this.getCode()
    this.checkCourses()
  },
  mounted() {
    this.getMenuLists()
  },
  methods: {
    ...mapMutations({
      setCourses: 'SET_COURSES',
      changeIsShowUpdateLog: 'CHANGE_ISSHOW_UPDATE_LOG',
      setStudentInfo: 'SET_STUDENTINFO',
    }),
    ...mapActions(['changeParsedCourses', 'saveCourseAndSetStorage', 'loadWeekOfSemester']),
    getChildMenuLists(id) {
      return this.menuLists.filter((menu) => menu.parentId === id).sort(this.menuSort)
    },
    menuSort(pre, cur) {
      return pre.seq - cur.seq
    },
    getMenuLists() {
      /**
       * 未作授权校验
       * 2022-02-26 by@hanhuipeng
       */
      getMenuLists().then((res) => {
        this.menuLists = res.data.result
      })
    },
    checkCourses() {
      if (this.courses.length) {
        this.changeParsedCourses()
        return
      }
    },
    getCode() {
      const urlReg = /code=.*&/
      if (!urlReg.test(document.URL)) {
        return
      }
      const query = document.URL.match(urlReg)[0]
      const code = query.slice(5, -1)
      return authorize(code).then((res) => {
        if (res.data.error === 0) {
          if (this.$route.query.state !== '0') {
            this.$router.push(this.$route.query.state)
          }
        }
      })
    },
    goFeaturesItem(item) {
      if (item.out) {
        window.open(item.url)
      } else {
        if (item.url === 'selfstudy') {
          this.$router.push({ path: 'login', query: { nextUrl: 'selfstudy' } })
          return
        }
        this.$router.push(item.url)
      }
    },
    goLibrary() {
      this.$router.push('library')
    },
    _getSeatInfo() {
      getSeatInfo()
        .then((res) => {
          this.seatInfos = res.data
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'common/scss/variable.scss';
span {
  font-size: 30px;
  background-image: linear-gradient(27deg, #fb2c61, #fac362);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.index {
  overflow: auto;
  height: 100vh;
  background-color: $color-background;
  header {
    box-sizing: border-box;
    width: 100%;
    height: 150px;
    background-image: linear-gradient(27deg, #fc4561, #fcbb5e);
    font-size: 18px;
    color: #fff;
    overflow: hidden;
    padding: 0 15px;
    position: relative;
    .date {
      margin-top: 50px;
    }
    .current-week {
      margin-top: 10px;
      font-size: 13px;
    }
    .single-info {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 20px;
      padding: 10px;
    }
  }
  main {
    padding: 0 15px;
    .library {
      background-color: $color-bgc-white;
      line-height: 50px;
      border-radius: 15px;
      padding: 0 15px;
      position: relative;
      top: -15px;
      display: flex;
      justify-content: space-between;
      .library-right {
        font-size: 13px;
        background-image: -webkit-linear-gradient(left bottom, #fb2c61, #fac362);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        span {
          font-size: 13px;
        }
      }
    }
    .feature {
      background-color: $color-bgc-white;
      border-radius: 10px;
      margin-bottom: 20px;
      .feature-title {
        padding: 0 10px;
        font-weight: 600;
        font-size: 13px;
        line-height: 40px;
        border-bottom: 1px solid $color-border-light;
      }
      .feature-wrapper {
        color: #666666;
        padding: 5px 20px 0;
        display: flex;
        flex-wrap: wrap;
        .feature-item {
          height: 70px;
          font-size: 30px;
          flex: 0 0 25%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .item-title {
            margin-top: 10px;
            font-size: 13px;
            position: relative;
            i {
              position: absolute;
              top: 50%;
              margin-top: -2px;
              margin-left: -6px;
              height: 4px;
              width: 4px;
              border-radius: 50%;
              background-color: #fb2c61;
            }
          }
        }
      }
    }
  }
}
</style>
